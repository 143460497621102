import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "pr-input-group",
  template: `
    <form
      #prInputGroupForm="ngForm"
      class="flex space-x-4"
      (ngSubmit)="submitted.emit(prInputGroupForm.value)"
    >
      <div class="flex flex-1 flex-col">
        <input
          [type]="inputType"
          [name]="inputName"
          class="p-2 border border-gray-400 rounded-md text-sm leading-5 font-normal text-gray-500"
          [placeholder]="inputPlaceholder"
          ngModel
        />
      </div>
      <button
        type="submit"
        class="px-6 py-2 bg-gray-600 rounded text-sm font-normal leading-5 text-white"
      >
        {{ buttonText }}
      </button>
    </form>
  `,
  styles: [],
})
export class PrInputGroupComponent {
  @Input() inputType = "text";
  @Input() inputName: string;
  @Input() inputPlaceholder: string;
  @Input() buttonText: string;

  @Output() inputChange: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
