// TODO Move into @types

export enum AuthStatus {
  login = "Login in progress",
  loginSuccess = "Login completed",
  loginError = "Login Error",
  //
  loadUserProfileRequested = "Load User Profile Requested",
  loadUserProfileSuccess = "Load User Profile Success",
  loadUserProfileError = "Load User Profile Error",
}

export enum ProductsComparatorStatus {
  //
  LoadProductComparatorRequest = "Load Product Comparator Requested",
  LoadProductComparatorSuccess = "Load Product Comparator Success",
  LoadProductComparatorError = "Load Product Comparator Error",
  //
  UpdateCompetitorProduct = "Update Competitor Product Requested",
  UpdateCompetitorProductSuccess = "Update Competitor Product Success",
  UpdateCompetitorProductError = "Update Competitor Product Error",
  WrongProductIntoComparisonRequested = "Wrong Product Into Comparison Requested",
  WrongProductIntoComparisonSuccess = "Wrong Product Into Comparison Success",
  WrongProductIntoComparisonError = "Wrong Product Into Comparison Error",
  /**
   * Export status
   */
  ExportComparisonTOCSVRequested = "Export Comparison TO CSV Requested",
  ExportComparisonTOCSVSuccess = "Export Comparison TO CSV Success",
  ExportComparisonTOCSVError = "Export Comparison TO CSV Error",
  /**
   * Filter status
   */
  filterCompetitorsProductsOfComparisonRequested = "Filter Competitors Products Of Comparison Requested",
  filterCompetitorsProductsOfComparisonSuccess = "Filter Competitors Products Of Comparison Success",
  filterCompetitorsProductsOfComparisonError = "Filter Competitors Products Of Comparison Error",
  /**
   * Sort Comparison
   */
  SortComparisonRequested = "Sort Comparison Requested",
  SortComparisonSuccess = "Sort Comparison Success",
  SortComparisonError = "Sort Comparison Error",
  /**
   * Ge Missing Ecomemrces Status
   */
  GetMissingEcommercesFromComparison = "GetMissingEcommercesFromComparison Requested",
  GetMissingEcommercesFromComparisonSuccess = "GetMissingEcommercesFromComparison Success",
  GetMissingEcommercesFromComparisonError = "GetMissingEcommercesFromComparison Error",
  /**
   * Ge Ecommerces that are violating policies
   */
  GetViolatingEcommerces = "GetViolatingEcommerces Requested",
  GetViolatingEcommercesSuccess = "GetViolatingEcommerces Success",
  GetViolatingEcommercesError = "GetViolatingEcommerces Error",
}

/**
 * Potrebbe non essere utile separare gli status per Tracked ed Untracked.
 * Magari sarebbe meglio rappresentare il tutto con un unico status: Tracked success or error e capire
 * tramite l'action di cosa si tratta, se tracked o untracked
 */
export enum EcommerceProductsStatus {
  LoadingEcommerceProducts = "Loading Ecommerce Products",
  LoadEcommerceProductsSuccess = "Load Ecommerce Products Success",
  LoadEcommerceProductsError = "Load Ecommerce Products Error",
  //
  EcommerceProductsSuccess = "Ecommerce Products Success",
  EcommerceProductsError = "Ecommerce Products Error",
  //
  UntrackedProductsLoading = "Untracked Products Loading",
  UntrackedProductsSuccess = "Untracked Products Success",
  UntrackedProductsError = "Untracked Products Success",
  //
  CreateProductRequested = "Create Product Requested",
  CreateProductSuccess = "Create Product Success",
  CreateProductError = "Create Product Error",
  //
  UpdateProductRequested = "Update Product Requested",
  UpdateProductSuccess = "Update Product Success",
  UpdateProductError = "Update Product Error",
  //
  SaveMAPandMSRPRequested = "Save MAP and MSRP Requested",
  SaveMAPandMSRPSuccess = "Save MAP and MSRP Success",
  SaveMAPandMSRPError = "Save MAP and MSRP Error",
  //
  LoadAlertByProductIdRequest = "LoadAlert By ProductId Request",
  LoadAlertByProductIdSuccess = "LoadAlert By ProductId Success",
  LoadAlertByProductIdError = "LoadAlert By ProductId Error",
  //
  UpdateAlertByProductIdRequested = "Update AlertBy Product Id Requested",
  UpdateAlertByProductIdSuccess = "Update AlertBy Product Id Success",
  UpdateAlertByProductIdError = "Update AlertBy Product Id Error",
}

export enum CompetitorsStatus {
  LoadingCompetitors = "LoadingCompetitors",
  LoadedCompetitorsSuccess = "LoadedCompetitorsSuccess",
  LoadedCompetitorsError = "LoadedCompetitorsError",
  SortByFieldSuccess = "SortByFieldSuccess",
  SortByFieldError = "SortByFieldError",
}

export enum ProductsMatchingStatus {
  LoadingProductsMatching = "LoadingProductsMatching",
  LoadedProductsMatchingSuccess = "LoadedProductsMatchingSuccess",
  LoadedProductsMatchingError = "LoadedProductsMatchingError",
  //
  MatchRequested = "Match Requested",
  MatchSuccess = "Match Success",
  MatchError = "Match Error",
  //
  NotMatchRequested = "Not Match Requested",
  NotMatchSuccess = "Not Match Success",
  NotMatchError = "Not Match Error",
  //
  LoadSimilarProductsRequested = "Load Similar Products Requested",
  LoadSimilarProductsSuccess = "Load Similar Products Success",
  LoadSimilarProductsError = "Load Similar Products Error",
}

export enum ReportsStatus {
  //
  LoadComparisonCompetitorsProductGroupedByCountryRequested = "Load Comparison Competitors Product Grouped By Country Requested",
  LoadComparisonCompetitorsProductGroupedByCountrySucess = "Load Comparison Competitors Product Grouped By Country Success",
  LoadComparisonCompetitorsProductGroupedByCountryError = "Load Comparison Competitors Product Grouped By Country Error",
  //
  LoadComparisonCompetitorsProductGroupedByChannelsRequested = "Load Comparison Competitors Product Grouped By Channels Requested",
  LoadComparisonCompetitorsProductGroupedByChannelsSucess = "Load Comparison Competitors Product Grouped By Channels Success",
  LoadComparisonCompetitorsProductGroupedByChannelsError = "Load Comparison Competitors Product Grouped By Channels Error",
  //
  LoadAllProductsSplittedByCountryRequested = "Load All Products Splitted By Country Requested",
  LoadAllProductsSplittedByCountrySuccess = "Load All Products Splitted By Country Success",
  LoadAllProductsSplittedByCountryError = "Load All Products Splitted By Country Error",
  //
  LoadFraudolentCompetitorsByMAPRequested = "Load Fraudolent Competitors By MAP Requested",
  LoadFraudolentCompetitorsByMAPSuccess = "Load Fraudolent Competitors By MAP Success",
  LoadFraudolentCompetitorsByMAPError = "Load Fraudolent Competitors By MAP Error",
  //
  LoadFraudolentCompetitorsByMAPGroupedByCountryRequested = "Load Fraudolent Competitors By MAP Grouped By Country Requested",
  LoadFraudolentCompetitorsByMAPGroupedByCountrySuccess = "Load Fraudolent Competitors By MAP Grouped By Country Success",
  LoadFraudolentCompetitorsByMAPGroupedByCountryError = "Load Fraudolent Competitors By MAP Grouped By Country Error",
  //
  LoadFraudolentCompetitorsByMSRPGroupedByCountryRequested = "Load Fraudolent Competitors By MSRP Grouped By Country Requested",
  LoadFraudolentCompetitorsByMSRPGroupedByCountrySuccess = "Load Fraudolent Competitors By MSRP Grouped By Country Success",
  LoadFraudolentCompetitorsByMSRPGroupedByCountryError = "Load Fraudolent Competitors By MSRP Grouped By Country Error",
  //
  LoadProductInsightsRequested = "Load Product Insights Requested",
  LoadProductInsightsSuccess = "Load Product Insights Success",
  LoadProductInsightsError = "Load Product Insights Error",
}

export enum DistributionStatus {
  LoadDistributionOverviewByChannelsRequested = "Load Distribution Overview By Channels Requested",
  LoadDistributionOverviewByChannelsSuccess = "Load Distribution Overview By Channels Success",
  LoadDistributionOverviewByChannelsError = "Load Distribution Overview By Channels Error",
  //
  ExportProductsDistributionRequested = "Export Products Distribution Requested",
  ExportProductsDistributionSuccess = "Export Products Distribution Success",
  ExportProductsDistributionError = "Export Products Distribution Error",
}

export enum CommercialAssistantStatus {
  LoadAllAutomationsRequested = "Load All Automations Requested",
  LoadAllAutomationsSuccess = "Load All Automations Success",
  LoadAllAutomationsError = "Load All Automations Error",
  //
  AddReferenceForEcommerce = "Add Reference For Ecommerce",
  //
  SaveDraftedAutomationRequested = "Save Drafted Automation Requested",
  SaveDraftedAutomationSuccess = "Save Drafted Automation Success",
  SaveDraftedAutomationError = "Save Drafted Automation Error",
  //
  DeleteAutomationsRequested = "Delete Automations Requested",
  DeleteAutomationsSuccess = "Delete Automations Success",
  DeleteAutomationsError = "Delete Automations Error",
  //
  ChangeEmailSubjectIntoDraft = "Change Email Subject Into Draft",
  ChangeEmailTemplateIntoDraft = "Change Email Template Into Draft",
  //
  EditAutomation = "Edit Automation Requested",
  EditAutomationSuccess = "Edit Automation Success",
  EditAutomationError = "Edit Automation Error",
  ExportProductsDistributionRequested = "Export Products Distribution Requested",
  ExportProductsDistributionSuccess = "Export Products Distribution Success",
  ExportProductsDistributionError = "Export Products Distribution Error",
  //
  ActivateAutomationsRequested = "Activate Automations Requested",
  ActivateAutomationsSuccess = "Activate Automations Success",
  ActivateAutomationsError = "Activate Automations Error",
  //
  DeactivateAutomationsRequested = "Deactivate Automations Requested",
  DeactivateAutomationsSuccess = "Deactivate Automations Success",
  DeactivateAutomationsError = "Deactivate Automations Error",
}

export default interface Status {
  EcommerceProducts: EcommerceProductsStatus;
  CompetitorsStatus: CompetitorsStatus;
  ProductsMatchingStatus: ProductsMatchingStatus;
  ReportsStatus: ReportsStatus;
  DistributionStatus: DistributionStatus;
  CommercialAssistantStatus: CommercialAssistantStatus;
}
