import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "page-wrapper",
  template: `
    <div class="flex flex-row bg-gray-100 space-x-4">
      <sidebar></sidebar>
      <div
        class="h-screen w-full py-4 pr-4 flex flex-col flex-grow space-y-3 overflow-y-scroll"
      >
        <div class="flex flex-col md:flex-row justify-between">
          <h4 *ngIf="title" class="mr-10 text-indigo-900 font-semibold">{{ title }}</h4>
          <ng-content select="page-header"></ng-content>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: []
})
export class PageWrapperComponent {
  @Input() title: string;
  constructor() { }
}
