import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges
} from "@angular/core";
import { BasicTableComponent } from "../basic-table/basic-table.component";

@Component({
  selector: "table-chart",
  template: `
    <basic-table [columnLabels]="columnLabels" [items]="data">
      <ng-template row-tmp let-item="item" let-index="index">
        <tr
          class="w-full border-b hover:bg-gray-200 cursor-pointer"
          [ngClass]="{ 'bg-gray-200': selectedRow === index }"
          (click)="click({ index: index, item: item })"
        >
          <td
            *ngFor="let row of item | keyvalue: returnZero"
            class="px-6 py-3 text-sm text-gray-800 font-medium space-x-2"
          >
            {{ row.value }}
          </td>
        </tr>
      </ng-template>
    </basic-table>
  `,
  styles: []
})
export class TableChartComponent extends BasicTableComponent
  implements OnChanges {
  @Input() columnLabels;
  @Input() data: any[];
  @Output() rowClick: EventEmitter<any> = new EventEmitter();

  private selectedRow: number;

  constructor() {
    super();
  }

  ngOnChanges() {
    if (this.data && !this.columnLabels) {
      this.columnLabels = Object.keys(this.data[0]).map((column, index) => ({
        id: index.toString(),
        name: column
      }));
    }
  }

  returnZero() {
    return 0;
  }

  click({ index, item }): void {
    this.selectedRow = index;
    this.rowClick.emit(index);
  }
}
