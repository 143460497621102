import { Component, OnInit } from "@angular/core";
import BasicChartComponent from "../../models/basic-chart-component";

@Component({
  selector: "number-cards-chart",
  template: `
    <ngx-charts-number-card
      [view]="view"
      [scheme]="colorScheme"
      [results]="[data[0]]"
      [cardColor]="cardColor"
      (select)="onSelect($event)"
    >
    </ngx-charts-number-card>
  `,
  styles: []
})
export class NumberCardsChartComponent extends BasicChartComponent
  implements OnInit {
  private cardColor: string = "#FFFFFF";

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
