import { ThrowStmt } from "@angular/compiler";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Series } from "@cubejs-client/core";
import { select, Store } from "@ngrx/store";
import { EcommerceType, ProductType } from "@sprintstudioco/ts-types";
import * as moment from "moment";
import { Observable, pipe } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { BasicTableColumn } from "src/app/shared/models/basic-tabel";
import { FilterProp } from "src/app/shared/models/filter";
import PrTabNavigation from "src/app/shared/models/pr-tab-navigation";
import { CompetitorProductEmbedded } from "src/app/shared/models/product-comparator";
import { ProductComparatorActions } from "src/app/store/actions";
import { ReportsAction } from "src/app/store/actions/reports.action";
import { ApplicationState } from "src/app/store/reducers";
import {
  AuthSelectors,
  ProductsComparatorSelectors,
  ReportsSelectors,
} from "src/app/store/selectors";

@Component({
  selector: "product-prices",
  template: `
    <div class="">
      <product-price-stats
        [product]="product"
        [isAnAdminUser]="isAnAdminUser$ | async"
        (onPricesAnomaliesClick)="onPricesAnomaliesClick($event)"
      ></product-price-stats>

      <div class="mt-8 flex flex-col space-y-10">
        <pr-tab-group (labelClicked)="labelClicked($event)">
          <pr-tab-panel
            id="tab-country-distribution"
            label="Tabella distribuzione per country"
            show="true"
          >
            <table-chart
              *ngIf="!showCountryChart"
              class="h-full w-full"
              [columnLabels]="[
                { id: 'country', name: 'Nazione' },
                { id: 'resellers', name: '# Rivenditori' },
                { id: 'avg-price', name: 'Prezzo Medio' },
                { id: 'max-price', name: 'Prezzo Minimo' },
                { id: 'min-price', name: 'Prezzo Massimo' }
              ]"
              [data]="comparisonCompetitorsProductGroupedByCountry"
            ></table-chart>
          </pr-tab-panel>
          <pr-tab-panel
            *ngIf="false"
            id="tab-channel-distribution"
            label="Tabella distribuzione per channel"
          >
            <table-chart
              class="h-full w-full"
              [columnLabels]="[
                { id: 'country', name: 'Channel' },
                { id: 'resellers', name: '# Rivenditori' },
                { id: 'avg-price', name: 'Prezzo Medio' },
                { id: 'max-price', name: 'Prezzo Minimo' },
                { id: 'min-price', name: 'Prezzo Massimo' }
              ]"
              [data]="comparisonCompetitorsGroupedByChannels"
            ></table-chart>
          </pr-tab-panel>
          <pr-tab-panel id="tab-map" label="Violazioni MAP">
            <card-header-footer>
              <div class="flex items-center justify-center" body>
                <alert
                  *ngIf="fraudolentCompetitorsByMAP?.length === 0"
                  type="success"
                  [title]="'Ottimo, non ci sono rivenditori che violano il MAP'"
                ></alert>
                <vertical-bar-chart
                  *ngIf="fraudolentCompetitorsByMAP?.length > 0"
                  [view]="[300, 200]"
                  [showGridLines]="true"
                  [showXAxisLabel]="false"
                  [showYAxisLabel]="false"
                  [showLegend]="false"
                  [data]="fraudolentCompetitorsByMAP[0].series"
                ></vertical-bar-chart>
              </div>
            </card-header-footer>
          </pr-tab-panel>
          <pr-tab-panel
            *ngIf="comparison?.msrp"
            id="tab-msrp"
            label="Violazioni MSRP"
          >
            <card-header-footer>
              <div class="flex items-center justify-center" body>
                <alert
                  *ngIf="fraudolentCompetitorsByMSRP?.length === 0"
                  type="success"
                  [title]="'Ottimo, Non ci sono rivenditori che violano l MSRP'"
                ></alert>
                <vertical-bar-chart
                  *ngIf="fraudolentCompetitorsByMSRP?.length > 0"
                  [view]="[300, 200]"
                  [showGridLines]="true"
                  [showXAxisLabel]="false"
                  [showYAxisLabel]="false"
                  [showLegend]="false"
                  [data]="fraudolentCompetitorsByMSRP[0].series"
                ></vertical-bar-chart>
              </div> </card-header-footer
          ></pr-tab-panel>
          <pr-tab-panel
            id="tab-empty-resellers"
            [label]="'Rivenditori scoperti'"
          >
            <card-header-footer>
              <div class="w-full flex items-center justify-center" body>
                <alert
                  *ngIf="fraudolentCompetitorsByMSRP?.length === 0"
                  type="success"
                  [title]="'Ottimo, Non ci sono rivenditori che violano l MSRP'"
                ></alert>
                <basic-table
                  class="w-full max-h-64 overflow-y-auto"
                  [columnLabels]="[
                    { id: 'ecommerceName', name: 'Ecommerce' },
                    { id: 'action', name: 'Actions' }
                  ]"
                  [items]="missingEcommercesIntoComparison$ | async"
                >
                  <ng-template row-tmp let-item="item" let-index="index">
                    <tr
                      class="py-2 hover:bg-gray-300 cursor-pointer border-b"
                      gtmPush
                      [gtmEventReport]="''"
                      [gtmEventName]="item.name"
                    >
                      <td class="px-4 py-2 text-sm">
                        {{ item.name }}
                      </td>
                      <td class="p-3 text-center border-l">
                        <a
                          class="w-full h-full text-xs text-gray-500 inline-flex items-center space-x-2"
                          [href]="item.url"
                          target="_blank"
                        >
                          <span>Vedi catalogo</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-3 w-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  </ng-template>
                </basic-table>
              </div> </card-header-footer
          ></pr-tab-panel>
        </pr-tab-group>
      </div>

      <div class="mt-8">
        <price-history
          [heading]="'Storico prezzi Rivenditori'"
          [placeholder]="'Seleziona uno o più rivenditori'"
          [products]="competitorsProduct"
          [comparison]="comparison"
          (onChangeProductsUrl)="onChangeProductsHistory($event)"
        ></price-history>
      </div>

      <div class="mb-2 mt-16 flex items-center space-x-5">
        <ng-select
          *ngIf="false"
          class="p-2 w-16"
          class="text-sm rounded"
          [items]="['Google Shopping', 'eBay', 'Trovaprezzi']"
          [placeholder]="placeholder"
          [searchable]="true"
          (change)="selectSpecificChannel($event)"
        ></ng-select>

        <div class="flex flex-1 justify-between">
          <div class="flex items-center space-x-5">
            <label class="font-medium text-gray-800 text-sm"
              >Filtra risultati</label
            >
            <div class="relative z-0 inline-flex rounded-md">
              <label
                class="-ml-px relative inline-flex items-center space-x-2 px-3 py-1 border border-gray-300 rounded-l-md bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <input type="radio" name="filtering" (click)="filter('all')" />
                <span>Tutti</span>
              </label>
              <label
                class="relative inline-flex items-center space-x-2 px-3 py-1 border-t border-b border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <input
                  type="radio"
                  name="filtering"
                  class="form-radio"
                  (click)="filter('violatingMAP')"
                />
                <span>Violazioni MAP</span>
              </label>
              <label
                class="relative inline-flex items-center space-x-2 px-3 py-1 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <input
                  type="radio"
                  name="filtering"
                  (click)="filter('violatingMSRP')"
                />
                <span>Violazioni MSRP</span>
              </label>
            </div>
            <div class="flex items-center">
              <label class="mr-3 font-medium text-gray-800 text-sm"
                >Tipologia</label
              >
              <label
                class="relative inline-flex items-center space-x-2 px-3 py-1 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <input
                  type="radio"
                  name="channel"
                  #isMarketplace
                  (click)="filter('all')"
                />
                <span>Tutti</span>
              </label>
              <label
                class="relative inline-flex items-center space-x-2 px-3 py-1 border-t border-b border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <input
                  type="radio"
                  name="channel"
                  #isMarketplace
                  (click)="filter('isMarketplace', true)"
                />
                <span>Marketplace</span>
              </label>
              <label
                class="relative inline-flex items-center space-x-2 px-3 py-1 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <input
                  type="radio"
                  name="channel"
                  #isMarketplace
                  (click)="filter('isMarketplace', false)"
                />
                <span>Rivenditori</span>
              </label>
            </div>
          </div>

          <div>
            <button
              class=" px-3 py-1 rounded-md bg-gray-800 text-gray-200 rounder shadow -ml-px relative inline-flex items-center space-x-1 text-xs font-medium  hover:bg-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              (click)="exportData()"
            >
              <span> Esporta questi dati in Excel </span>
              <svg
                class="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- TODO Ottimizzare la comparsa di questo componente. Magari creare una route -->
      <div class="flex flex-col space-y-2">
        <pricing-comparator-full
          [productsComparison]="comparison"
          (filterProductsByCountry)="filterCompetitorsProductsByCountry($event)"
          (onRowClick)="onRowClick($event)"
          (onSort)="onSortComparison($event)"
        ></pricing-comparator-full>
      </div>

      <router-outlet></router-outlet>
    </div>
  `,
})
export class ProductPricesComponent implements OnInit {
  private isAnAdminUser$: Observable<boolean>;
  private selectedComparison: string;
  private comparison: any;
  private product: ProductType;
  private competitors: CompetitorProductEmbedded[];
  private lowestPrice: number;
  private highestPrice: number;
  private recommendedPrice: number;
  private map: number;
  private competitorsProductUrl: string[];
  private competitorsProduct: ProductType[];

  private columnLabels: BasicTableColumn[];
  private comparisonCompetitorsProductGroupedByCountry: any[];
  private fraudolentCompetitorsByMAP: any[];
  private fraudolentCompetitorsByMSRP: any[];
  private comparisonCompetitorsGroupedByChannels: any[];
  private productInsights: any;
  private missingEcommercesIntoComparison$: Observable<EcommerceType[]>;

  private filterProp: FilterProp;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<ApplicationState>
  ) {}

  ngOnInit(): void {
    const comparisonId$ = this.route.params.pipe(
      map((params) => {
        this.selectedComparison = params["comparisonId"];
        return this.selectedComparison;
      })
    );

    this.isAnAdminUser$ = this.store.pipe(select(AuthSelectors.selectIfUserIsAdmin));

    // TODO La situazione qui sotto si potrebbe unire ma non mi viene la soluzione, il forkJoin sembra non emettere il risultato
    comparisonId$
      .pipe(
        switchMap((comparisonId) =>
          this.store.pipe(
            select(
              ProductsComparatorSelectors.selectProductComparisonById(
                comparisonId
              )
            ),
            filter((data) => !!data)
          )
        )
      )
      .subscribe((comparison) => {
        this.comparison = comparison;
        this.product = comparison.product;
        this.competitors = comparison.competitors;
        this.lowestPrice = comparison.lowest_price;
        this.highestPrice = comparison.highest_price;
        this.recommendedPrice = comparison.recommended_price;
        this.map = comparison["map"];
      });

    comparisonId$
      .pipe(
        switchMap((comparisonId) =>
          this.store.pipe(
            select(
              ProductsComparatorSelectors.selectAllCompetitorsProductUrlFromSelectedComparison(
                comparisonId
              )
            )
          )
        )
      )
      .subscribe((competitorsProductUrl) => {
        this.competitorsProductUrl = competitorsProductUrl;
      });

    comparisonId$
      .pipe(
        switchMap((comparisonId) =>
          this.store.pipe(
            select(
              ProductsComparatorSelectors.selectAllCompetitorsProductFromSelectedComparison(
                comparisonId
              )
            )
          )
        )
      )
      .subscribe((competitorsProduct) => {
        this.competitorsProduct = competitorsProduct;
      });

    this.store
      .pipe(
        select(ReportsSelectors.selectChartForProductComparison),
        filter((data) => !!data)
      )
      .subscribe(
        ({
          comparisonCompetitorsGroupedByCountry,
          comparisonCompetitorsGroupedByChannels,
          fraudolentCompetitorsByMAP,
          fraudolentCompetitorsByMSRP,
          productInsights,
        }) => {
          if (fraudolentCompetitorsByMAP) {
            this.fraudolentCompetitorsByMAP = fraudolentCompetitorsByMAP;
          }

          if (fraudolentCompetitorsByMSRP) {
            this.fraudolentCompetitorsByMSRP = fraudolentCompetitorsByMSRP;
          }

          if (productInsights) {
            this.productInsights = productInsights;
          }

          this.comparisonCompetitorsGroupedByChannels =
            comparisonCompetitorsGroupedByChannels;
          this.comparisonCompetitorsProductGroupedByCountry =
            comparisonCompetitorsGroupedByCountry;
        }
      );

    this.store
      .pipe(
        select(ProductsComparatorSelectors.selectFilterProp),
        filter((data) => !!data)
      )
      .subscribe((filterProp) => {
        this.filterProp = filterProp;
      });

    this.missingEcommercesIntoComparison$ = this.store.pipe(
      select(ProductsComparatorSelectors.selectMissingEcommercesIntoComparison),
      filter((data) => !!data)
    );
  }

  onPricesAnomaliesClick() {
    this.router.navigate([
      "/product/anomalies",
      this.selectedComparison,
      "prices-anomalies",
    ]);
  }

  onChangeProductsHistory(productsUrl: string[]) {
    this.competitorsProductUrl = productsUrl;
  }

  filterCompetitorsProductsByCountry(filter: FilterProp) {
    /**
     * TODO
     * Richiamare l'azione che filtra i competitors in base al filtraggio specificato
     * L'azione deve mappare i competitors tenedo conto di questo filtraggio
     */
    if (filter.country) {
      this.store.dispatch(
        ProductComparatorActions.filterCompetitorsProductsOfComparison({
          comparisonId: this.comparison["_id"],
          filter: { country: filter.country },
        })
      );
    } else {
      this.store.dispatch(
        ProductComparatorActions.filterCompetitorsProductsOfComparison({
          comparisonId: this.comparison["_id"],
        })
      );
    }
  }

  onRowClick(competitor: CompetitorProductEmbedded) {
    this.router.navigate(["product-profile", competitor.product["_id"]], {
      relativeTo: this.route,
    });
  }

  exportData() {
    this.store.dispatch(
      ProductComparatorActions.exportComparisonTOCSV({
        competitors: this.comparison.competitors,
        fileName: `Export-Comparison-${
          this.product.name
        }-${moment().toString()}`,
      })
    );
  }

  rowCountryTableIsClicked($event) {
    this.store.dispatch(
      ProductComparatorActions.filterCompetitorsProductsOfComparison({
        comparisonId: this.comparison["_id"],
        filter: { country: $event.name },
      })
    );
  }

  filter(filter: any, value?: any) {
    switch (filter) {
      case "violatingMAP":
        this.store.dispatch(
          ProductComparatorActions.filterCompetitorsProductsOfComparison({
            comparisonId: this.comparison["_id"],
            filter: {
              ...this.filterProp,
              onlyViolatingMAP: true,
            },
          })
        );

        break;
      case "violatingMSRP":
        this.store.dispatch(
          ProductComparatorActions.filterCompetitorsProductsOfComparison({
            comparisonId: this.comparison["_id"],
            filter: {
              ...this.filterProp,
              onlyViolatingMSRP: true,
            },
          })
        );

        break;
      case "isMarketplace":
        this.store.dispatch(
          ProductComparatorActions.filterCompetitorsProductsOfComparison({
            comparisonId: this.comparison["_id"],
            filter: {
              ...this.filterProp,
              isMarketplace: value,
            },
          })
        );

        break;
      case "all":
        this.store.dispatch(
          ProductComparatorActions.filterCompetitorsProductsOfComparison({
            comparisonId: this.comparison["_id"],
            filter: {},
          })
        );
        break;
      default:
        break;
    }
  }

  labelClicked(tab: PrTabNavigation) {
    switch (tab.id) {
      case "tab-map":
        this.store.dispatch(
          ReportsAction.loadFraudolentCompetitorsByMAPGroupedByCountry({
            comparisonId: this.comparison._id,
            map: this.comparison.map,
          })
        );
        break;
      case "tab-msrp":
        this.store.dispatch(
          ReportsAction.loadFraudolentCompetitorsByMSRPGroupedByCountry({
            comparisonId: this.comparison._id,
            msrp: this.comparison.msrp,
          })
        );
        break;
      case "tab-insight":
        this.store.dispatch(
          ReportsAction.loadProductInsights({
            comparisonId: this.comparison._id,
          })
        );
        break;
      case "tab-empty-resellers":
        // this.store.dispatch(
        //   ProductComparatorActions.getMissingEcommercesFromComparison({ comparisonId: this.comparison._id })
        // )
        break;
      default:
        break;
    }
  }

  private sort = -1;
  onSortComparison($event: any) {
    if ($event.sortByRegularPrice) {
      this.sort *= -1;
      this.store.dispatch(
        ProductComparatorActions.sortComparison({
          comparisonId: this.comparison["_id"],
          comparison: this.comparison,
          filter: { sortByRegularPrice: this.sort === 1 ? -1 : 1 },
        })
      );
    }

    if ($event.sortByDiscountedPrice) {
      this.sort *= -1;
      this.store.dispatch(
        ProductComparatorActions.sortComparison({
          comparisonId: this.comparison["_id"],
          comparison: this.comparison,
          filter: { sortByDiscountedPrice: this.sort === 1 ? -1 : 1 },
        })
      );
    }
  }

  selectSpecificChannel(channel) {
    this.comparison.competitors = [
      ...this.comparison.competitors.filter(
        (competitor) => competitor.ecommerce.name === channel
      ),
    ];
  }
}
