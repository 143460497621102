import { schema } from "normalizr";
import { EcommerceSchema } from './ecommerce.schema';
import { ProductSchema } from './product.schema';

export default class ProductsComparatorSchema extends schema.Entity {
    constructor() {
    const ecommerce: schema.Entity = new EcommerceSchema();
    const product: schema.Entity = new ProductSchema(ecommerce);

    super(
      "comparison",
      {
        product,
        competitors: [{ product, ecommerce }],
      },
      {
        idAttribute: "_id",
      }
    );
  }
}
