import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from "@angular/router";
import { Store } from "@ngrx/store";
import { ReportsAction } from "src/app/store/actions/reports.action";
import { ApplicationState } from "src/app/store/reducers";

@Injectable({
  providedIn: "root"
})
export default class ReportsResolver implements Resolve<void> {
  constructor(private store: Store<ApplicationState>) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    return this.store.dispatch(
      ReportsAction.loadAllProductsSplittedByCountry()
    );
  }
}
