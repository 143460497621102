import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { Store } from "@ngrx/store";
import { ApplicationState } from "src/app/store/reducers";
import { ProductComparatorActions } from "src/app/store/actions";

@Injectable({
  providedIn: "root",
})
export class ProductComparatorResolver implements Resolve<void> {
  constructor(private store: Store<ApplicationState>) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    return this.store.dispatch(
      ProductComparatorActions.loadProductComparator()
    );
  }
}
