import { createSelector } from "@ngrx/store";

import * as fromDistribution from "../reducers/distribution.reducer";
import * as fromFeature from "../reducers";
import { DistributionStatus } from "src/app/shared/models/status";

export const selectDistributionEntities = createSelector(
  fromFeature.getDistributionState,
  fromDistribution.getDistributionEntities
);

export const selectDistributionStatus = createSelector(
  fromFeature.getDistributionState,
  (state: fromDistribution.DistributionState) => state.status
);

export const selectDistributionByChannels = createSelector(
  selectDistributionEntities,
  (entities) => {
    if (!entities) return;
    return entities.distributionOverviewByChannels;
  }
);
