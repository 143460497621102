import { ErrorHandler, Provider } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonModule } from "@angular/common";
// Import Bugsnag and the Angular integration
import Bugsnag from "@bugsnag/js";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";
import { JWTInterceptor } from "./helpers/jwt-interceptor";
import { HttpErrorInterceptor } from "./helpers/http-error-interceptor";
import PricyoErrorHandler from "./helpers/errors-handler";

Bugsnag.start({ apiKey: "43a250e00a589447729cb0bacffd4b37" });

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

export const IMPORTS = [CommonModule];

export const PROVIDERS: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JWTInterceptor,
    multi: true
  },
  {
    provide: ErrorHandler,
    useClass: PricyoErrorHandler
  }
];

export const DECLARATIONS = [];

export const EXPORTS = [];
