import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { EcommerceType, ProductType } from "@sprintstudioco/ts-types";
import { Observable, pipe, Subscription } from "rxjs";
import { delay, filter, map, retryWhen, skip, skipUntil, switchMap, tap } from "rxjs/operators";
import { Comparison } from "src/app/shared/models/product-comparator";
import {
  CompetitorProduct4MatchingEmbedded,
  SimilarProduct,
} from "src/app/shared/models/products-matching";
import { ProductsMatchingActions } from "src/app/store/actions";
import { ApplicationState } from "src/app/store/reducers";
import { ProductsMatchingSelectors } from "src/app/store/selectors";

@Component({
  selector: "products-matching",
  template: `
    <page-wrapper [title]="'Products Matching'">
      <div class="w-3/4 flex flex-col bg-white rounded shadow">
        <div class="p-3 flex flex-col space-y-1 border-b">
          <h2 class="text-sm font-normal text-gray-700">
            Abbiamo trovato {{ productsToMatch?.length }} prodotti dai tuoi
            competitors che potrebbero essere simili al tuo prodotto
          </h2>
          <alert
            *ngIf="isLastMatchSuccess$ | async"
            class="flex-1"
            [type]="'success'"
            [text]="'Matching effettuato'"
          ></alert>
          <alert
            *ngIf="isLastMatchError$ | async"
            class="flex-1"
            [type]="'danger'"
            [text]="'Si è verificato un errore durante il Matching'"
          ></alert>
        </div>
        <div
          class="flex flex-col flex-1 md:flex-row md:justify-center md:items-center"
        >
          <div class="flex-1">
            <my-product [product]="product"></my-product>
          </div>
          <div class="border-l flex flex-1 justify-between">
            <button
              class="px-3 py-1 rounded text-gray-700 text-sm font-medium focus:outline-none"
              [disabled]="currentCompetitorProduct === 0"
              (click)="currentCompetitorProduct = currentCompetitorProduct - 1"
            >
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <my-product
              [product]="productsToMatch[currentCompetitorProduct]?.product"
            ></my-product>
            <button
              class="px-3 py-1 rounded text-gray-700 text-sm font-medium focus:outline-none"
              [disabled]="
                currentCompetitorProduct === productsToMatch?.length - 1
              "
              (click)="currentCompetitorProduct = currentCompetitorProduct + 1"
            >
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="p-3 flex flex-col space-y-2 border-t">
          <products-matching-footer
            class="flex-1"
            [ngClass]="{
              'opacity-25 pointer-events-none':
                (isLastMatchRequested$ | async) || (isLastMatchSuccess$ | async)
            }"
            (match)="match($event)"
          ></products-matching-footer>
        </div>
      </div>
    </page-wrapper>
  `,
  styles: [],
})
export class ProductsMatchingComponent implements OnInit, OnDestroy {
  private product: ProductType;
  private currenctCompetitor: EcommerceType;
  private comparisonToMatch: any;
  private currentCompetitorProduct: number;

  private isLastMatchRequested$: Observable<boolean>;
  private isLastMatchSuccess$: Observable<boolean>;
  private isLastMatchError$: Observable<boolean>;

  private productsToMatch: SimilarProduct[];
  private nextCompetitorProductsSub: Subscription;
  private similarProductSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private store: Store<ApplicationState>
  ) {}

  ngOnInit() {
    this.currentCompetitorProduct = 0;

    this.isLastMatchRequested$ = this.store.pipe(
      select(ProductsMatchingSelectors.selectIsLastMatchRequested)
    );

    this.isLastMatchSuccess$ = this.store.pipe(
      select(ProductsMatchingSelectors.selectIsLastMatchSuccess)
    );

    this.isLastMatchError$ = this.store.pipe(
      select(ProductsMatchingSelectors.selectIsLastMatchError)
    );

    /**
     * Seleziono il prossimo competitor da dover usare per effettuare il matching
     * Sparo successivamente l'azione per caricare i prodotti simili a partire
     * dall'ecommerce competitor appena arrivato
     */
    this.similarProductSub = this.route.paramMap
      .pipe(
        map((paramsMap) => paramsMap.get("productId")),
        switchMap((productId) =>
          this.store.pipe(
            select(
              ProductsMatchingSelectors.selectNextCompetitorAndMyProductToMatch(
                productId
              )
            ),
            filter((data) => !!data),
            delay(2000)
          )
        )
      )
      .subscribe(({ product, nextCompetitor }) => {
        this.product = product;
        this.currenctCompetitor = nextCompetitor;

        if (product && nextCompetitor) {
          this.store.dispatch(
            ProductsMatchingActions.loadSimilarProducts({
              productId: product["_id"],
              competitorId: nextCompetitor["_id"],
            })
          );
        }
      });

    /**
     * Seleziona i prossimi 20 prodotto da matchare all'interno dello stato dell'applicazione
     */
    this.nextCompetitorProductsSub = this.store
      .pipe(
        select(ProductsMatchingSelectors.selectNextSimilarProductsToMatch),
        filter((data) => !!data),
      )
      .subscribe((products) => {
        this.productsToMatch = products;
      });

    /**
     * Seleziono dallo stato la comparazione da dover matchare
     * e la uso nel componente
     */
  }

  ngOnDestroy(): void {
    this.similarProductSub.unsubscribe();
    this.nextCompetitorProductsSub.unsubscribe();
  }

  match($event) {
    this.store.dispatch(
      ProductsMatchingActions.matchProducts({
        productId: this.product["_id"],
        similarProductID: this.productsToMatch[this.currentCompetitorProduct]
          .product["_id"],
      })
    );
  }
}
