import { Injectable } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Credential } from "src/app/shared/models/credential";
import { Observable, from, of, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { User } from "src/app/shared/models/user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  signup(credentials: Credential): Observable<any> {
    return this.http.post(environment.API.signup, credentials);
  }

  login(credentials: Credential): Observable<any> {
    // TODO Creare un effects che intercetta il login e salva nello storage il token
    return this.http.post(environment.API.login, credentials).pipe(
      map((response: { token: string }) => {
        window.localStorage.setItem(environment.token_name, response.token);
        return response;
      }),
      catchError(({ error }) => throwError(error))
    );
  }

  me(): Observable<User> {
    return this.http.get<User>(`${environment.API.me}`);
  }

  getToken(): Observable<string> {
    return of(window.localStorage.getItem(environment.token_name));
  }

  currentUser(): User {
    return {
      token: window.localStorage.getItem(environment.token_name),
    };
  }

  isAuthenticated(): boolean | string {
    return window.localStorage.getItem(environment.token_name);
  }

  logout() {
    window.localStorage.removeItem(environment.token_name);
    return of({});
  }
}
