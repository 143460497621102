import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "card-header-footer",
  template: `
    <div
      class="bg-white overflow-show shadow rounded-lg divide-y divide-gray-200"
    >
      <div
        class="px-4 py-5 sm:px-6 flex flex-col items-start justify-between space-y-2"
      >
        <h4 class="text-xs font-semibold text-gray-500 uppercase">
          {{ heading }}
        </h4>
        <ng-content select="[header]"></ng-content>
      </div>
      <div class="px-4 py-5 sm:p-6">
        <ng-content select="[body]"></ng-content>
      </div>
      <div class="px-4 py-4 sm:px-6">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  `,
  styles: []
})
export class CardHeaderFooterComponent implements OnInit {
  @Input() heading: string;

  constructor() {}

  ngOnInit(): void {}
}
