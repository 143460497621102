import { Component, OnInit } from "@angular/core";
import {
  BasicTableOptions,
  BasicTableColumn
} from "src/app/shared/models/basic-tabel";
import { Store, select } from "@ngrx/store";
import { ApplicationState } from "src/app/store/reducers";
import { AuthSelectors, CompetitorsSelectors } from "src/app/store/selectors";
import { filter } from "rxjs/operators";
import { EcommerceType } from "@sprintstudioco/ts-types";
import { CompetitorsActions } from "src/app/store/actions";
import { Observable } from "rxjs";
import { User } from "src/app/shared/models/user";

@Component({
  selector: "competitor-dashboard",
  template: `
    <div class=" flex flex-row bg-gray-100 space-x-4">
      <sidebar></sidebar>
      <div
        class="h-screen w-full flex flex-col pt-4 space-y-3 overflow-y-scroll"
      >
        <h4 class="text-indigo-900 font-semibold">Riepilogo</h4>
        <basic-table
          [options]="options"
          [columnLabels]="labels"
          [items]="competitors"
          [currentPage]="page"
          [totalPage]="5"
          (sort)="sortColumn($event)"
          (previousPage)="previousPage($event)"
          (nextPage)="nextPage($event)"
        >
          <ng-template row-tmp let-item="item">
            <tr class="w-full border-b">
              <td
                class="px-6 py-3 flex items-center text-sm text-gray-800 font-medium space-x-2"
              >
                <div class="flex items-center space-x-1">
                  <span>{{ item.name }}</span>
                  <a
                    class="text-gray-600 font-normal"
                    href="{{ item.url }}"
                    target="_blank"
                  >
                    <svg
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="external-link w-3 h-3"
                    >
                      <path
                        d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                      ></path>
                      <path
                        d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                      ></path></svg
                  ></a>
                </div>
              </td>
              <td class="px-6 py-3 text-sm text-gray-600 font-medium">
                {{ item.url }}
              </td>
              <td class="px-6 py-3">
                <label
                  class="px-2 inline-flex rounded-full bg-gray-100 text-gray-800 font-semibold text-xs leading-5"
                  >{{ (user$ | async).lastDataUpdate | date }}</label
                >
              </td>
            </tr>
          </ng-template>
        </basic-table>
      </div>
    </div>
  `
})
export class CompetitorDashboardComponent implements OnInit {
  private options: BasicTableOptions = {
    itemsIsSelectable: false,
    itemsHasActions: false,
    hasPagination: false
  };
  private labels: BasicTableColumn[];
  private competitors: EcommerceType[];
  private page: number;
  private sort: { sortBy?: string; order?: string } = {};
  private user$: Observable<User>;

  constructor(private store: Store<ApplicationState>) {}

  ngOnInit() {
    this.labels = [
      {
        id: "name",
        name: "Nome",
        canSort: true
      },
      {
        id: "site",
        name: "Sito Web",
        canSort: true
      },
      {
        id: "last-tracked",
        name: "Ultimo aggiornamento",
        canSort: true
      }
    ];

    this.sort = {};
    this.store
      .pipe(
        select(CompetitorsSelectors.selectCompetitorsEntitiesValue),
        filter(data => data !== null)
      )
      .subscribe(competitors => (this.competitors = competitors));

    this.store
      .pipe(select(CompetitorsSelectors.selectCurrentPage))
      .subscribe(page => (this.page = page));

    this.store
      .pipe(
        select(CompetitorsSelectors.selectSort),
        filter(data => data !== null)
      )
      .subscribe(sort => (this.sort = sort));

    this.user$ = this.store.pipe(
      select(AuthSelectors.selectUser),
      filter(data => !!data)
    );
  }

  sortColumn(field: string) {
    this.store.dispatch(
      CompetitorsActions.sortByField({ field, order: this.sort.order })
    );
  }

  previousPage() {
    this.store.dispatch(
      CompetitorsActions.previousCompetitorsPage({
        page: this.page,
        sort: this.sort.sortBy,
        order: this.sort.order
      })
    );
  }

  nextPage() {
    const { sortBy, order } = this.sort;
    this.store.dispatch(
      CompetitorsActions.nextCompetitorsPage({
        page: this.page,
        sort: this.sort.sortBy,
        order: this.sort.order
      })
    );
  }
}
