import { schema } from "normalizr";
import { EcommerceSchema, ProductSchema } from '.';

export default class ProductsMatchingSchema extends schema.Entity {
  constructor() {
    const ecommerce: schema.Entity = new EcommerceSchema();
    const product: schema.Entity = new ProductSchema(ecommerce);

    super(
      "matching",
      [{
        product,
        // competitors: [{ product, ecommerce }],
      }],
      {
        idAttribute: "_id",
      }
    );
  }
}
