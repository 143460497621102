// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const baseURLProd = "https://sprintstudio-scraper-api.herokuapp.com";
const baseURLStag = "https://sprintstudio-scraper-api-stg.herokuapp.com";
const baseURL = baseURLProd;

const cubeJsProd =
  "https://sprintstudio-cube-js-mongo.herokuapp.com/cubejs-api/v1";
const cubeJsStag =
  "https://pricyo-cube-js-mongo-stg.herokuapp.com/cubejs-api/v1";
const cubeJs = cubeJsProd;

// host: "http://localhost:4000/cubejs-api/v1",
// host: "https://sprintstudio-cube-js-mongo-stg.herokuapp.com/cubejs-api/v1",
// host: "http://localhost:4000/cubejs-api/v1/cubejs-api/v1",

export const environment = {
  production: true,
  token_name: "ecommerce_scraper_access_token",
  LOGZIO_TOKEN: "VyoayxDDIWdiQkMurwZGcKBaHvtjJGQB",
  Realm: {
    appId: "ecommerce-scraper-admin-heyqs",
  },
  CubeJs: {
    host: cubeJs,
    token: window.localStorage.getItem("ecommerce_scraper_access_token"),
  },
  API: {
    host: baseURL,
    signup: `${baseURL}/api/signup`,
    login: `${baseURL}/api/login`,
    me: `${baseURL}/api/me`,
    getEcommerceProducts: `${baseURL}/api/products`,
    getCompetitors: `${baseURL}/api/ecommerces/competitors`,
    trackProducts: `${baseURL}/api/products/track`,
    untrackProducts: `${baseURL}/api/products/track`,
    createProduct: `${baseURL}/api/products`,
    updateProduct: (productId) => `${baseURL}/api/products/${productId}`,
    saveMAPandMSRP: `${baseURL}/api/alerts`,
    getAlertByProductId: (productId: string) =>
      `${baseURL}/api/alerts/${productId}`,
    updateAlertByProductId: (productId: string) =>
      `${baseURL}/api/alerts/${productId}`,
    getProductsComparison: `${baseURL}/api/products/comparison`,
    getProductComparisonById: (comparisonId: string) =>
      `${baseURL}/api/products/comparison/${comparisonId}`,
    getProductsToMatch: (productId) =>
      `/api/products/${productId}/competitor_products_to_match`,
    matchProducts: (productId) =>
      `/api/products/${productId}/competitor_products_to_match`,
    getSimilarProducts: (productId, ecommerceId) =>
      `/api/products/${productId}/similar/${ecommerceId}`,
    updatedCompetitorProduct: (productId) => `/api/products/${productId}`,
    wrongProductIntoComparison: (comparisonId, productId) =>
      `/api/products/comparison/${comparisonId}/competitors/${productId}`,
    getMissingEcommercesIntoComparison: (comparisonId) =>
      `/api/products/comparison/${comparisonId}/missingEcommerces`,
    getEcommercesViolatingPolicies: `/api/ecommerces/violations`,
    // Commercial Assistant
    getAllAutomations: `/api/automations`,
    createAutomation: `/api/automations`,
    deleteAutomations: `/api/automations`,
    editAutomation: (automationId) => `/api/automations/${automationId}`,
    activeAutomations: `/api/automations/activate`,
    deactivateAutomations: `/api/automations/deactivate`,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
