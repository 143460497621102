import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PROVIDERS, DECLARATIONS, IMPORTS } from '.';

@NgModule({
  providers: [PROVIDERS],
  declarations: [DECLARATIONS],
  imports: [
    IMPORTS
  ]
})
export class CoreModule { }
