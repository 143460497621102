import { Component, OnInit } from "@angular/core";

@Component({
  selector: "auth",
  template: `
    <!--<router-outlet name="login-form"></router-outlet>
    <router-outlet name="register-form"></router-outlet>-->
    <router-outlet></router-outlet>
  `,
})
export class AuthComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
