import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Route, RouterModule } from "@angular/router";
import { CompetitorDashboardComponent } from "./containers/competitor-dashboard/competitor-dashboard.component";
import { CompetitorsResolver } from "../core/resolvers/competitors/competitors.resolver";

const routes: Route[] = [
  {
    path: "competitor",
    component: CompetitorDashboardComponent,
    resolve: {
      action: CompetitorsResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompetitorRoutingModule {}
