import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "pr-button-toggle",
  template: `
    <button
      [id]="id"
      type="button"
      [name]="name"
      [value]="value"
      class="inline-flex items-center px-1 py-1 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      [ngClass]="{ 'bg-indigo-700 text-indigo-100': checked }"
      (click)="click.emit({ id: id, value: value })"
    >
      <ng-content></ng-content>
    </button>
  `,
  styles: []
})
export class PrButtonToggleComponent implements OnInit {
  @Input() id: string;
  @Input() name: string;
  @Input() value: string;
  @Input() checked: boolean;
  @Output() click: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
