import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import WizardPanelTab from "../../models/wizard-panel-tab";

@Component({
  selector: "step-panels-description",
  template: `
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="lg:border-t lg:border-b lg:border-gray-200 bg-white">
      <nav class="mx-auto max-w-7xl" aria-label="Progress">
        <ol
          role="list"
          class="rounded-md overflow-hidden lg:flex lg:border-gray-200 lg:rounded-none"
        >
          <li
            *ngFor="let tab of tabs; let i = index"
            class="relative overflow-hidden lg:flex-1"
            (click)="changeTab(i)"
          >
            <div
              class="border border-gray-200 overflow-hidden border-t-0 rounded-b-md lg:border-0"
            >
              <!-- Upcoming Step -->
              <a class="group" (click)="changeTab(i)">
                <span
                  class="absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                  aria-hidden="true"
                  [ngClass]="{ 'bg-gray-600 text-white': current === i }"
                ></span>
                <span
                  class="px-6 py-5 flex items-start text-sm font-medium lg:pl-9"
                >
                  <span class="flex-shrink-0">
                    <span
                      class="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full"
                      [ngClass]="{
                        'border-gray-600 text-gray-600': current === i
                      }"
                    >
                      <span class="text-gray-500">0{{ i }}</span>
                    </span>
                  </span>
                  <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
                    <span
                      class="text-xs font-semibold text-gray-500 tracking-wide uppercase"
                      [ngClass]="{ 'text-gray-600': current === i }"
                      >{{ tab.title }}</span
                    >
                    <span class="text-sm font-medium text-gray-500">{{
                      tab.description
                    }}</span>
                  </span>
                </span>
              </a>

              <!-- Separator -->
              <div
                class="hidden absolute top-0 left-0 w-3 inset-0 lg:block"
                aria-hidden="true"
              >
                <svg
                  class="h-full w-full text-gray-300"
                  viewBox="0 0 12 82"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0.5 0V31L10.5 41L0.5 51V82"
                    stroke="currentcolor"
                    vector-effect="non-scaling-stroke"
                  />
                </svg>
              </div>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  `,
  styles: [],
})
export class StepPanelsDescriptionComponent {
  @Input() tabs: WizardPanelTab[];
  @Input() current: number;
  @Input() complete: number;
  @Input() tabCanBeClicked: boolean = false;
  @Output() clickOnTab: EventEmitter<number> = new EventEmitter();

  constructor() {}

  changeTab(index: number) {
    if (!this.tabCanBeClicked) return;

    this.current = index;
  }
}
