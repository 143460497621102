import {
  AfterContentInit,
  Component,
  ContentChild,
  OnInit
} from "@angular/core";

@Component({
  selector: "loader-placeholder",
  template: `
    <div class="opacity-30">
      <ng-content></ng-content>
    </div>
  `,
  styles: []
})
export class LoaderPlaceholderComponent implements AfterContentInit {
  constructor() {}

  ngAfterContentInit(): void {}
}
