import { Action, createReducer, on } from "@ngrx/store";
import {
  AutomationReferrerType,
  AutomationType,
} from "@sprintstudioco/ts-types";
import { CommercialAssistantStatus } from "src/app/shared/models/status";
import { BaseState } from ".";
import { CommercialAssistantActions } from "../actions";

export interface CommercialAssistantEntities {
  automation?: Record<string, AutomationType<string>>;
  referrer?: Record<string, AutomationReferrerType>;
}

export interface CommercialAssistantState extends BaseState {
  entities: CommercialAssistantEntities;
  draft?: Partial<AutomationType | any>;
}

export const initialState: CommercialAssistantState = {
  entities: {},
  selectedItems: [],
};

const commercialAssistantReducers = createReducer(
  initialState,
  on(CommercialAssistantActions.loadAllAutomations, (state) => ({
    ...state,
    status: CommercialAssistantStatus.LoadAllAutomationsRequested,
  })),
  on(
    CommercialAssistantActions.loadAllAutomationsSuccess,
    (state, { entities }) => ({
      ...state,
      entities,
      status: CommercialAssistantStatus.LoadAllAutomationsSuccess,
    })
  ),
  on(
    CommercialAssistantActions.loadAllAutomationsError,
    (state, { error }) => ({
      ...state,
      error,
      status: CommercialAssistantStatus.LoadAllAutomationsError,
    })
  ),
  on(
    CommercialAssistantActions.selectAutomation,
    (state, { automationId }) => ({
      ...state,
      selectedItems: [...state.selectedItems, automationId],
    })
  ),
  on(
    CommercialAssistantActions.removeSelectedAutomation,
    (state, { automationId }) => ({
      ...state,
      selectedItems: state.selectedItems.filter((id) => id !== automationId),
    })
  ),
  on(CommercialAssistantActions.editAutomation, (state, { automationId }) => ({
    ...state,
    draft: {
      ...state.entities.automation[automationId],
      referrers: state.entities.automation[automationId].referrers.map(
        (referrer) => state.entities.referrer[referrer]
      ),
    },
    status: CommercialAssistantStatus.EditAutomation,
  })),
  on(
    CommercialAssistantActions.editAutomationSuccess,
    (state, { automationId, data }) => ({
      ...state,
      entities: {
        ...state.entities,
        automation: {
          ...state.entities.automation,
        },
      },
      status: CommercialAssistantStatus.EditAutomationSuccess,
    })
  ),
  on(CommercialAssistantActions.editAutomationError, (state, { error }) => ({
    ...state,
    error,
    status: CommercialAssistantStatus.EditAutomationError,
  })),
  // Automation Creator Actions
  on(CommercialAssistantActions.initAutomationDraft, (state) => ({
    ...state,
    draft: {
      referrers: [],
    },
  })),
  on(
    CommercialAssistantActions.setEcommerceToNotify,
    (state, { ecommerceId }) => ({
      ...state,
      draft: {
        ...state.draft,
        ecommerceId,
      },
    })
  ),
  on(
    CommercialAssistantActions.setAutomationType,
    (state, { notificationType }) => ({
      ...state,
      draft: {
        ...state.draft,
        notificationType,
      },
    })
  ),
  on(
    CommercialAssistantActions.setAutomationFrequency,
    (state, { frequencyInDays }) => ({
      ...state,
      draft: {
        ...state.draft,
        frequencyInDays,
      },
    })
  ),
  on(
    CommercialAssistantActions.addReferrerForEcommerce,
    (state, { referent }) => ({
      ...state,
      draft: {
        ...state.draft,
        referrers: [...state.draft.referrers, referent],
      },
      status: CommercialAssistantStatus.AddReferenceForEcommerce,
    })
  ),
  on(
    CommercialAssistantActions.removeReferenceForEcommerce,
    (state, { referentEmail }) => ({
      ...state,
      draft: {
        ...state.draft,
        referrers: state.draft.referrers.filter(
          ({ email }) => email !== referentEmail
        ),
      },
    })
  ),
  on(
    CommercialAssistantActions.changeEmailSubjectIntoDraft,
    (state, { emailSubject }) => ({
      ...state,
      draft: {
        ...state.draft,
        emailSubject,
      },
      state: CommercialAssistantStatus.ChangeEmailSubjectIntoDraft,
    })
  ),
  on(
    CommercialAssistantActions.changeEmailTemplateIntoDraft,
    (state, { emailTemplate }) => ({
      ...state,
      draft: {
        ...state.draft,
        emailTemplate,
      },
      status: CommercialAssistantStatus.ChangeEmailTemplateIntoDraft,
    })
  ),
  on(CommercialAssistantActions.setAutomationStatus, (state, { isActive }) => ({
    ...state,
    draft: {
      ...state.draft,
      isActive,
    },
  })),
  on(
    CommercialAssistantActions.saveDraftedAutomation,
    (state, { automation }) => ({
      ...state,
      status: CommercialAssistantStatus.SaveDraftedAutomationRequested,
    })
  ),
  on(
    CommercialAssistantActions.saveDraftedAutomationSuccess,
    (state, { automation }) => ({
      ...state,
      status: CommercialAssistantStatus.SaveDraftedAutomationSuccess,
    })
  ),
  on(
    CommercialAssistantActions.saveDraftedAutomationError,
    (state, { error }) => ({
      ...state,
      error,
      status: CommercialAssistantStatus.SaveDraftedAutomationError,
    })
  ),
  on(CommercialAssistantActions.deleteAutomations, (state) => ({
    ...state,
    status: CommercialAssistantStatus.DeleteAutomationsRequested,
  })),
  on(
    CommercialAssistantActions.deleteAutomationsSuccess,
    (state, { automationsIds }) => {
      let _automations = { ...state.entities.automation };
      let _referrers = { ...state.entities.referrer };

      automationsIds.forEach((id) => {
        state.entities.automation[id].referrers.forEach(
          (referrerId) => delete _referrers[referrerId]
        );

        delete _automations[id];
      });

      return {
        ...state,
        selectedItems: [],
        entities: {
          referrer: {
            ..._referrers,
          },
          automation: {
            ..._automations,
          },
        },
        status: CommercialAssistantStatus.DeleteAutomationsSuccess,
      };
    }
  ),
  on(CommercialAssistantActions.deleteAutomationsError, (state, { error }) => ({
    ...state,
    error,
    status: CommercialAssistantStatus.DeleteAutomationsError,
  })),
  on(CommercialAssistantActions.activeAutomations, (state) => ({
    ...state,
    status: CommercialAssistantStatus.ActivateAutomationsRequested,
  })),
  on(
    CommercialAssistantActions.activeAutomationsSuccess,
    (state, { automationsIds }) => {
      let automations = state.entities.automation;
      automationsIds.forEach((_id) => {
        automations[_id] = {
          ...automations[_id],
          isActive: true,
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          automation: {
            ...automations,
          },
        },
        selectedItems: [],
        status: CommercialAssistantStatus.ActivateAutomationsSuccess,
      };
    }
  ),
  on(CommercialAssistantActions.activeAutomationsError, (state, { error }) => ({
    ...state,
    error,
    status: CommercialAssistantStatus.ActivateAutomationsError,
  })),
  on(CommercialAssistantActions.deactiveAutomations, (state) => ({
    ...state,
    status: CommercialAssistantStatus.DeactivateAutomationsRequested,
  })),
  on(
    CommercialAssistantActions.deactiveAutomationsSuccess,
    (state, { automationsIds }) => {
      let _automations = state.entities.automation;
      automationsIds.forEach((_id) => {
        _automations[_id] = {
          ..._automations[_id],
          isActive: false,
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          automation: {
            ..._automations,
          },
        },
        selectedItems: [],
        status: CommercialAssistantStatus.DeactivateAutomationsSuccess,
      };
    }
  ),
  on(
    CommercialAssistantActions.deactiveAutomationsError,
    (state, { error }) => ({
      ...state,
      error,
      status: CommercialAssistantStatus.DeactivateAutomationsError,
    })
  )
);

export function reducer(state: CommercialAssistantState, action: Action) {
  return commercialAssistantReducers(state, action);
}

export const getCommercialAssistantEntities = (
  state: CommercialAssistantState
) => state.entities;
