import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
  CompetitorsActions,
  EcommerceProductsActions,
  ProductsMatchingActions,
} from "src/app/store/actions";
import { ApplicationState } from "src/app/store/reducers";

@Injectable({
  providedIn: "root",
})
export class ProductsMatchingResolver implements Resolve<void> {
  constructor(private store: Store<ApplicationState>) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    /**
     * Il Frontend per la comparisione selezionata, richiama un'action per prelevare i matching da fare per quella
     * particolare comparison
     */
    // return this.store.dispatch(
    //   ProductsMatchingActions.loadProductsForMatching({ productId: route.paramMap.get("productId") })
    // );

    this.store.dispatch(EcommerceProductsActions.loadEcommerceProducts());
    return this.store.dispatch(CompetitorsActions.loadCompetitors());
  }
}
