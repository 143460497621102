import { AfterContentInit, Component, Input, OnInit } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { Observable, Subject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import * as moment from "moment";
import { ProductsComparatorSelectors } from "src/app/store/selectors";
import { Store } from "@ngrx/store";
import { ApplicationState } from "src/app/store/reducers";

@Component({
  selector: "chart",
  template: `
    <div class="h-full p-5 mt-5">
      <!--<ngx-charts-line-chart
        [curve]="curve"
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="results"
      >
      </ngx-charts-line-chart>-->
      <ng-content></ng-content>
    </div>
  `,
  styles: []
})
export class ChartComponent implements AfterContentInit {
  @Input() curve: any;
  @Input() legend: boolean;
  @Input() showLabels: boolean;
  @Input() animations: boolean;
  @Input() xAxis: boolean;
  @Input() yAxis: boolean;
  @Input() showYAxisLabel: boolean;
  @Input() showXAxisLabel: boolean;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() timeline: boolean;

  @Input() view: any[];
  @Input() results: any[];
  @Input() query: any;
  @Input() querySubject$: Subject<any>;
  @Input() result$: Subject<any>;

  @Input() colorScheme;

  constructor(
    private store: Store<ApplicationState>,
    private cubejs: CubejsClient
  ) {}

  ngAfterContentInit(): void {
    this.legend = true;
    this.showLabels = true;
    this.animations = true;
    this.xAxis = true;
    this.yAxis = true;
    this.showYAxisLabel = true;
    this.showXAxisLabel = true;
    this.timeline = true;
    this.view = [1400, 400];
    this.colorScheme = {
      domain: ["#5AA454", "#E44D25", "#CFC0BB", "#7aa3e5", "#a8385d", "#aae3f5"]
    };

    this.cubejs
      .watch(this.querySubject$)
      .pipe(
        map(response => {
          const keys = response.series().map(({ key }) => key.split(",")[0]);
          return { keys, response };
        }),
        switchMap(({ keys, response }) =>
          this.store
            .select(
              ProductsComparatorSelectors.selectEcommerceCompetitorsById(keys)
            )
            .pipe(map(data => ({ ecommerces: data, response })))
        ),
        map(({ ecommerces, response }) => {
          return response.series().map(({ key, series, title }) => ({
            name: ecommerces[key.split(",")[0]].name,
            series: series.map(({ value, x }) => ({
              name: moment(x).format("DD-MM-YYYY"),
              value
            }))
          }));
        })
      )
      .subscribe(
        chartData => {
          this.results = [...chartData];
          this.result$.next(this.results);
        },
        err => console.error(err)
      );

    // this.querySubject$.next(this.query);
  }
}
