import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, Router, RouterModule } from '@angular/router';

import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { ProductComparatorResolver } from '../core/resolvers/product/product-comparator-resolver.service';
import { ProductPricesComponent } from './containers/product-prices/product-prices.component';
import { ProductsOverviewComponent } from './containers/products-overview/products-overview.component';
import { PricesAnomaliesComponent } from './containers/prices-anomalies/prices-anomalies.component';
import { ProductHistoryResolver } from '../core/resolvers/product-history-resolver/product-history-resolver';
import { ProductProfileComponent } from '../shared/containers/product-profile/product-profile.component';
import { ProductComparisonResolver } from '../core/resolvers/product/product-comparison-resolver.service';
import UserResolver from '../core/resolvers/user/user.resolver';

const routes: Route[] = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: 'products/overview',
        component: ProductsOverviewComponent,
        resolve: {
          products: ProductComparatorResolver,
          user: UserResolver
        }
      },
      {
        path: 'product/:comparisonId/:productName',
        component: ProductPricesComponent,
        resolve: {
          comparision: ProductComparisonResolver,
          products: ProductComparatorResolver,
          history: ProductHistoryResolver
        },
        children: [
          {
            path: 'product-profile/:productId',
            component: ProductProfileComponent
          }
        ]
      },
      {
        path: 'product/anomalies/:comparisonId/prices-anomalies',
        component: PricesAnomaliesComponent,
        resolve: {
          comparisions: ProductComparatorResolver,
          products: ProductComparatorResolver
        }
      },
      {
        path: '',
        redirectTo: '/products/overview',
        pathMatch: 'full'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
