import { createSelector } from "@ngrx/store";
import { retry } from "rxjs/operators";
import { ProductsMatching } from "src/app/shared/models/products-matching";
import { ProductsMatchingStatus } from "src/app/shared/models/status";
import { EcommerceProductsSelectors } from ".";
import * as fromFeature from "../reducers/index";
import * as fromProductsMatching from "../reducers/products-matching.reducer";

export const selectProductsMatchEntities = createSelector(
  fromFeature.getProductsMatchingState,
  fromProductsMatching.getProductsMatchingEntities
);

export const selectFirstMatching = createSelector(
  selectProductsMatchEntities,
  (entities: ProductsMatching) => {
    if (!entities) return;

    return entities.matching[Object.keys(entities.matching)[0]];
  }
);

export const selectComparisonByProductIdToMatch = (productId: string) =>
  createSelector(selectProductsMatchEntities, (entities: ProductsMatching) => {
    if (!entities) return;

    const selectedMatching =
      entities.matching[
        Object.keys(entities.matching).find(
          (matchingId) => entities.matching[matchingId].product === productId
        )
      ];

    return {
      ...selectedMatching,
      product: entities.product[selectedMatching.product],
      competitors: selectedMatching.competitors.map((competitor) => ({
        product: entities.product[competitor.product],
        ecommerce: entities.ecommerce[competitor.ecommerce],
      })),
    };
  });

export const selectNextCompetitorToMatch = createSelector(
  fromFeature.getProductsMatchingState,
  (state: fromProductsMatching.ProductsMatchingState) => {
    if (!state.nextCompetitorToMatch) return;
    return state.nextCompetitorToMatch;
  }
);

export const selectNextCompetitorAndMyProductToMatch = (productId: string) =>
  createSelector(
    EcommerceProductsSelectors.selectEcommerceProductById(productId),
    selectNextCompetitorToMatch,
    (product, nextCompetitor) => {
      if (!product || !nextCompetitor) return;

      return {
        product,
        nextCompetitor,
      };
    }
  );

export const selectNextSimilarProductsToMatch = createSelector(
  fromFeature.getProductsMatchingState,
  (state: fromProductsMatching.ProductsMatchingState) => {
    if (state.entities && state.entities.products) {
      return Object.values(state.entities.products);
    }
  }
);

export const selectNextProductToMatch = createSelector(
  selectProductsMatchEntities,
  (entities: ProductsMatching) => {
    if (!entities) return;

    const first = entities.matching[Object.keys(entities.matching)[0]];

    return {
      ...first,
      product: entities.product[first.product],
      competitors: first.competitors.map((competitor) => ({
        ...competitor,
        product: entities.product[competitor.product],
        ecommerce: entities.ecommerce[competitor.ecommerce],
      })),
    };
  }
);

/**
 * Status selectors for Matching
 */
export const selectIsLastMatchRequested = createSelector(
  fromFeature.getProductsMatchingState,
  (state: fromProductsMatching.ProductsMatchingState) =>
    state.status === ProductsMatchingStatus.MatchRequested
);

export const selectIsLastMatchSuccess = createSelector(
  fromFeature.getProductsMatchingState,
  (state: fromProductsMatching.ProductsMatchingState) =>
    state.status === ProductsMatchingStatus.MatchSuccess
);

export const selectIsLastMatchError = createSelector(
  fromFeature.getProductsMatchingState,
  (state: fromProductsMatching.ProductsMatchingState) =>
    state.status === ProductsMatchingStatus.MatchError
);
