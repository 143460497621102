import { Series } from "@cubejs-client/core";
import { createAction, props } from "@ngrx/store";

export const ReportsAction = {
  /**
   * Product Prices History
   */
  loadProductPriceHistory: createAction(
    "[ProductPrices Component] Load Product Prices History Requested",
    props<{ comparisonId: string }>()
  ),

  loadProductPriceHistorySuccess: createAction(
    "[ProductPrices Component] Load Product Prices History Success",
    props<{ comparisonId: string; history: any; status: any }>()
  ),

  loadProductPriceHistoryError: createAction(
    "[ProductPrices Component] Load Product Prices History Error",
    props<{ error: any }>()
  ),

  /**
   * Load All Products Stacked Country
   */
  loadAllProductsSplittedByCountry: createAction(
    "[Reports Component] Load All Products Splitted By Country Requested"
  ),
  loadAllProductsSplittedByCountrySuccess: createAction(
    "[Reports Effect] Load All Products Splitted By Country Success",
    props<{ chartData: any }>()
  ),
  loadAllProductsSplittedByCountryError: createAction(
    "[Reports Effect] Load All Products Splitted By Country Error",
    props<{ error: any }>()
  ),

  /**
   * ComparisonCompetitorsProductGroupedByCountry
   */
  loadComparisonCompetitorsProductGroupedByCountry: createAction(
    "[ProductComparison Resolver] Load Comparison Competitors Product Grouped By Country Requested",
    props<{ comparisonId: string }>()
  ),
  loadComparisonCompetitorsProductGroupedByCountrySuccess: createAction(
    "[ProductsComparator Effects] Load Comparison Competitors Product Grouped By Country Success",
    props<{ comparisonId: string; chartData: any }>()
  ),
  loadComparisonCompetitorsProductGroupedByCountryError: createAction(
    "[ProductsComparator Effects] Load Comparison Competitors Product Grouped By Country Error",
    props<{ error: any }>()
  ),

  /**
   * ComparisonCompetitorsProductGroupedByChannel
   */
  loadComparisonCompetitorsProductGroupedByChannels: createAction(
    "[ProductComparison Resolver] Load Comparison Competitors Product Grouped By Channels Requested",
    props<{ comparisonId: string; channels: string[] }>()
  ),
  loadComparisonCompetitorsProductGroupedByyChannelsSuccess: createAction(
    "[ProductsComparator Effects] Load Comparison Competitors Product Grouped By Channels Success",
    props<{ comparisonId?: string; channels?: string[]; chartData: any }>()
  ),
  loadComparisonCompetitorsProductGroupedByyChannelsError: createAction(
    "[ProductsComparator Effects] Load Comparison Competitors Product Grouped By Channels Error",
    props<{ error: any }>()
  ),

  /**
   * Competitor Fraudolent
   */
  loadFraudolentCompetitorsByMAP: createAction(
    "[ProductPrices Component] Load Fraudulent Competitor Requested",
    props<{ comparisonId: string; map: number }>()
  ),

  loadFraudolentCompetitorsByMAPSuccess: createAction(
    "[Reports Effect] Load Fraudulent Competitor Success",
    props<{ competitors: any; chartData?: any }>()
  ),

  loadFraudolentCompetitorsByMAPError: createAction(
    "[Reports Effect] Load Fraudulent Competitor Error",
    props<{ error: any }>()
  ),

  /**
   * Competitor Fraudolent by MAP grouped by country
   */
  loadFraudolentCompetitorsByMAPGroupedByCountry: createAction(
    "[ProductPrices Component] Load Fraudulent Competitors By MAP Grouped by Country Requested",
    props<{ comparisonId: string; map: number }>()
  ),
  loadFraudolentCompetitorsByMAPGroupedByCountrySuccess: createAction(
    "[Reports Effect] Load Fraudulent Competitors By MAP Grouped by Country Success",
    props<{ comparisonId: string; map: number; chartData?: any[] }>()
  ),
  loadFraudolentCompetitorsByMAPGroupedByCountryError: createAction(
    "[Reports Effect] Load Fraudulent Competitors By MAP Grouped by Country Error",
    props<{ error: any }>()
  ),

  /**
   * Competitor Fraudolent by MSRP grouped by country
   */
  loadFraudolentCompetitorsByMSRPGroupedByCountry: createAction(
    "[ProductPrices Component] Load Fraudulent Competitors By MSRP Grouped by Country Requested",
    props<{ comparisonId: string; msrp: number }>()
  ),
  loadFraudolentCompetitorsByMSRPGroupedByCountrySuccess: createAction(
    "[Reports Effect] Load Fraudulent Competitors By MSRP Grouped by Country Success",
    props<{ comparisonId: string; msrp: number; chartData?: any[] }>()
  ),
  loadFraudolentCompetitorsByMSRPGroupedByCountryError: createAction(
    "[Reports Effect] Load Fraudulent Competitors By MSRP Grouped by Country Error",
    props<{ error: any }>()
  ),
  /**
   * Load Product Insights
   */
  loadProductInsights: createAction(
    "[ProductPrices Component] Load Product Insights Requested",
    props<{ comparisonId: string }>()
  ),
  loadProductInsightsSuccess: createAction(
    "[ProductPrices Component] Load Product Insights Success",
    props<{ comparisonId?: string; chartData: any[] }>()
  ),
  loadProductInsightsError: createAction(
    "[ProductPrices Component] Load Product Insights Error",
    props<{ error: any }>()
  )
};
