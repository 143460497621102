import { schema } from "normalizr";
import { EcommerceSchema } from "./ecommerce.schema";
import { CategorySchema } from "./category.schema";
import { ProductSchema } from "./product.schema";

export class MyProductsSchema extends schema.Entity {
  constructor() {
    const ecommerce: schema.Entity = new EcommerceSchema();
    const category: schema.Entity = new CategorySchema();
    const products: schema.Entity = new ProductSchema(category, ecommerce);

    super(
      "product",
      {
        ecommerce,
        category,
      },
      { idAttribute: "_id" }
    );
  }
}
