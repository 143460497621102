import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ContentChild,
  OnInit
} from '@angular/core';
import { RowTmpDirective } from '../../directives/row-tmp/row-tmp.directive';
import { BasicTableOptions, BasicTableColumn } from '../../models/basic-tabel';

@Component({
  selector: 'basic-table',
  template: `
    <div class="bg-white rounded-md shadow overflow-auto">
      <table class="min-w-full table-auto">
        <thead>
          <tr class="">
            <th
              *ngIf="options?.itemsIsSelectable"
              class="px-4 py-3 sticky top-0 text-gray-500 text-xs text-left bg-white border-b font-medium uppercase"
              (click)="checkAllItems()"
            >
              <input type="checkbox" class="form-checkbox" />
            </th>
            <th
              class="p-4 sticky top-0 text-gray-500 text-xs leading-4 tracking-wider bg-gray-50 border-b font-medium uppercase"
              *ngFor="let label of columnLabels"
            >
              <div class="flex items-center space-x-1">
                <span>{{ label.name }}</span>
                <a *ngIf="label.canSort" (click)="sortColumn(label.id)">
                  <svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="selector w-4 h-4"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </th>
            <th
              *ngIf="options?.itemsHasActions"
              class="px-6 py-3 sticky top-0 text-gray-500 text-xs text-left bg-white border-b font-medium uppercase"
            ></th>
          </tr>
        </thead>
        <tbody class="w-full h-full overflow-y-auto">
          <ng-container
            *ngFor="let item; index as i; of: items"
            (click)="rowClicked.emit({ index: i })"
          >
            <ng-template
              [ngTemplateOutlet]="rowTemplate"
              [ngTemplateOutletContext]="{ index: i, item: item }"
            ></ng-template>
          </ng-container>
        </tbody>
        <tfoot *ngIf="hasFooter">
          <tr class="border-t">
            <th colspan="6" class="h-6 sticky bottom-0 bg-white">
              <div
                *ngIf="options?.hasPagination"
                class="flex justify-between border-t text-xs text-gray-800 font-medium"
              >
                <div class="h-12 pl-6 flex justify-start items-center ">
                  {{ currentPage }} di {{ totalPage }} pagine
                </div>
                <div class="h-12 pr-6 flex justify-end items-center">
                  <div class="flex">
                    <button
                      class="px-2 py-1 border border-r-0 rounded-tl rounded-bl"
                      (click)="prevPage()"
                    >
                      <svg
                        class=" w-3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M15 19l-7-7 7-7"></path>
                      </svg>
                    </button>
                    <button
                      class="px-2 border rounded-tr rounded-br"
                      (click)="nexPage()"
                    >
                      <svg
                        class="w-3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M9 5l7 7-7 7"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  `,
  styles: []
})
export class BasicTableComponent implements OnInit {
  // TODO Estrarre in un tipo così da poterlo utilizzare anche all'interno di altri componenti
  @Input() options: BasicTableOptions;
  @Input() columnLabels: BasicTableColumn[];
  @Input() items: any[];
  @Input() currentPage: number;
  @Input() totalPage: number;
  @Input() hasFooter: boolean;

  // @Output() select: EventEmitter<string> = new EventEmitter();
  @Output() selectAll: EventEmitter<void> = new EventEmitter();
  @Output() previousPage: EventEmitter<void> = new EventEmitter();
  @Output() nextPage: EventEmitter<void> = new EventEmitter();
  @Output() sort: EventEmitter<string> = new EventEmitter();
  @Output() rowClicked: EventEmitter<number> = new EventEmitter();

  @ContentChild(RowTmpDirective, { read: TemplateRef, static: true })
  rowTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void { }

  /*checkItem(id: string) {
    this.select.emit(id);
  }*/

  checkAllItems() {
    this.selectAll.emit();
  }

  prevPage() {
    this.previousPage.emit();
  }

  nexPage() {
    this.nextPage.emit();
  }

  sortColumn(labelId: string) {
    this.sort.emit(labelId);
  }
}
