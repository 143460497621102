import { Injectable } from "@angular/core";
import { PivotConfig } from "@cubejs-client/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, map as MapRxJs } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  private query: any;

  constructor(private cubejs: CubejsClient) {}

  private executeCubeJsQueryForTableChart(
    querySubject$,
    pivotConfig?: PivotConfig
  ) {
    return this.cubejs
      .watch(querySubject$)
      .pipe(MapRxJs((data) => data.tablePivot(pivotConfig)));
  }

  private executeCubeJsQuery(querySubject$, pivotConfig?: PivotConfig) {
    return this.cubejs.watch(querySubject$).pipe(
      MapRxJs((data) => data.series()),
      map((series) => {
        return series.map(({ key, title, series }) => ({
          name: key,
          title,
          series: series.map(({ value, x }) => ({
            name: x,
            value,
          })),
        }));
      })
    );
  }

  getComparisonCompetitorsProductGroupedByCountry(
    comparisonId: string,
    chartType?: string
  ) {
    const querySubject$ = new BehaviorSubject<any>({
      measures: [
        "ComparisonsCompetitors.count",
        "Products.visibleprice_avg",
        "Products.visibleprice_min",
        "Products.visibleprice_max",
      ],
      timeDimensions: [],
      order: {
        "ComparisonsCompetitors.count": "desc",
      },
      dimensions: ["Products.country"],
      filters: [
        {
          member: "Comparisons.id",
          operator: "equals",
          values: [comparisonId],
        },
      ],
    });

    return this.executeCubeJsQueryForTableChart(querySubject$);
  }

  getComparisonCompetitorsProductGroupedByChannel(
    comparisonId: string,
    channels: string[]
  ) {
    channels = channels || ["eBay", "Trovaprezzi", "Google Shopping"];

    const querySubject$ = new BehaviorSubject<any>({
      measures: [
        "ComparisonsCompetitors.count",
        "Products.visibleprice_avg",
        "Products.visibleprice_min",
        "Products.visibleprice_max",
      ],
      timeDimensions: [],
      order: {
        "ComparisonsCompetitors.count": "desc",
      },
      dimensions: ["ComparisonsCompetitors.competitorsEcommerceName"],
      filters: [
        {
          member: "Comparisons.id",
          operator: "equals",
          values: [comparisonId],
        },
        {
          member: "ComparisonsCompetitors.competitorsEcommerceName",
          operator: "equals",
          values: [...channels],
        },
      ],
    });

    return this.executeCubeJsQueryForTableChart(querySubject$);
  }

  getAllProductsSplittedByCountry() {
    const querySubject$ = new BehaviorSubject<any>({
      measures: ["Products.count"],
      timeDimensions: [
        {
          dimension: "Products.timestamp",
        },
      ],
      order: {
        "Products.count": "desc",
      },
      dimensions: ["Products.country", "Comparisons.productName"],
      filters: [
        {
          member: "Comparisons.productEcommerce",
          operator: "equals",
          values: [],
        },
      ],
    });

    return this.executeCubeJsQuery(querySubject$, {
      x: ["Comparisons.productName"],
      y: ["Products.country", "measures"],
    });
  }

  getFraudulentCompetitorsByMAPGroupedByCountry(
    comparisonId: string,
    map: number
  ) {
    const querySubject$ = new BehaviorSubject<any>({
      measures: ["ComparisonsCompetitors.count"],
      timeDimensions: [],
      order: {
        "ComparisonsCompetitors.count": "desc",
      },
      dimensions: ["Products.country"],
      filters: [
        {
          member: "ComparisonsCompetitors.competitorsComparisonId",
          operator: "equals",
          values: [comparisonId],
        },
        {
          member: "Products.visiblepriceDimension",
          operator: "lt",
          values: [map.toString()],
        },
      ],
    });

    return this.executeCubeJsQuery(querySubject$);
  }

  getFraudulentCompetitorsByMSRPGroupedByCountry(
    comparisonId: string,
    msrp: number
  ) {
    const querySubject$ = new BehaviorSubject<any>({
      measures: ["ComparisonsCompetitors.count"],
      timeDimensions: [],
      order: {
        "ComparisonsCompetitors.count": "desc",
      },
      dimensions: ["Products.country"],
      filters: [
        {
          member: "ComparisonsCompetitors.competitorsComparisonId",
          operator: "equals",
          values: [comparisonId],
        },
        {
          member: "Products.regularpriceDimension",
          operator: "gt",
          values: [msrp.toString()],
        },
      ],
    });
    return this.executeCubeJsQuery(querySubject$);
  }

  loadFraudulentCompetitorsByMAP(
    comparisonId: string,
    map: number
  ): Observable<any> {
    const querySubject$ = new BehaviorSubject<any>({
      filters: [
        {
          member: "Comparisons.id",
          operator: "equals",
          values: [comparisonId],
        },
        {
          member: "Products.visibleprice",
          operator: "lt",
          values: [map.toString()],
        },
      ],
      dimensions: [
        "ComparisonsCompetitors.competitorsProduct",
        "Comparisons.productEcommerce",
        "Products.name",
      ],
      timeDimensions: [
        {
          dimension: "Products.timestamp",
        },
      ],
      order: {
        "Products.visibleprice": "desc",
      },
      measures: ["Products.visibleprice"],
    });

    return this.executeCubeJsQuery(querySubject$);
  }

  loadFraudulentCompetitorsByMSRP(comparisonId: string, msrp: number) {
    const querySubject$ = new BehaviorSubject<any>({
      measures: ["Products.visibleprice"],
      timeDimensions: [
        {
          dimension: "Products.timestamp",
        },
      ],
      order: {
        "Products.count": "desc",
      },
      dimensions: [
        "ComparisonsCompetitors.competitorsProduct",
        "Products.ecommerce",
        "Products.name",
      ],
      filters: [
        {
          member: "Comparisons.id",
          operator: "equals",
          values: [comparisonId],
        },
        {
          member: "Products.visibleprice",
          operator: "lte",
          values: [msrp],
        },
      ],
    });

    return this.executeCubeJsQuery(querySubject$);
  }

  loadFraudulentCompetitorsByMSRPGroupedByCountry(
    comparisonId: string,
    msrp: number
  ) {
    const querySubject$ = new BehaviorSubject<any>({
      measures: ["Products.visibleprice"],
      timeDimensions: [
        {
          dimension: "Products.timestamp",
        },
      ],
      order: {
        "Products.count": "desc",
      },
      dimensions: [
        "ComparisonsCompetitors.competitorsProduct",
        "Products.ecommerce",
        "Products.name",
      ],
      filters: [
        {
          member: "Comparisons.id",
          operator: "equals",
          values: [comparisonId],
        },
        {
          member: "Products.visibleprice",
          operator: "lte",
          values: [msrp],
        },
      ],
    });

    return this.executeCubeJsQuery(querySubject$);
  }

  loadProductInsights(comparisonId: string) {
    const querySubject$ = new BehaviorSubject<any>({
      measures: ["ComparisonsCompetitors.count"],
      timeDimensions: [
        {
          dimension: "Products.timestamp",
        },
      ],
      order: {
        "Products.count": "desc",
      },
      dimensions: [
        "ComparisonsCompetitors.competitorsProduct",
        "Products.image",
      ],
      filters: [
        {
          member: "Comparisons.id",
          operator: "equals",
          values: [comparisonId],
        },
        {
          member: "Products.image",
          operator: "set",
        },
      ],
    });

    return this.executeCubeJsQuery(querySubject$);
  }

  loadDistributionOverviewByChannels(ecommerceId: string) {
    if (ecommerceId === null) return;

    const querySubject$ = new BehaviorSubject({
      measures: ["Products.count"],
      timeDimensions: [
        {
          dimension: "Products.timestamp",
        },
      ],
      order: {
        "Products.count": "desc",
      },
      filters: [
        {
          member: "Comparisons.productEcommerce",
          operator: "equals",
          values: [ecommerceId],
        },
      ],
      dimensions: ["ComparisonsCompetitors.competitorsEcommerceName"],
    });

    return this.executeCubeJsQueryForTableChart(querySubject$);
  }
}
