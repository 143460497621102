import { createReducer, on, Action } from "@ngrx/store";
import { ProductComparatorActions } from "../actions";
import { ProductComparator } from "src/app/shared/models/product-comparator";
import { ProductsComparatorStatus } from "src/app/shared/models/status";
import { FilterProp } from "src/app/shared/models/filter";
// TODO ProductComparator dovrà essere normalizzato e rappresentato così: { [key: string ]: ProductComparator } invece di []
export interface ProductsComparatorState {
  entities?: ProductComparator;
  filterProp?: FilterProp;
  status?: string;
  error?: string;
}

export const initialState: ProductsComparatorState = {};

export const productComparatorReducers = createReducer(
  initialState,
  on(ProductComparatorActions.loadProductComparator, state => ({
    ...state,
    status: ProductsComparatorStatus.LoadProductComparatorRequest
  })),
  on(
    ProductComparatorActions.loadProductComparatorSuccess,
    (state, { productsComparator }) => ({
      ...state,
      entities: productsComparator,
      status: "Loaded"
    })
  ),
  on(
    ProductComparatorActions.loadProductComparatorError,
    (state, { error }) => ({ ...state, error, status: "Error" })
  ),
  on(
    ProductComparatorActions.updateCompetitorProduct,
    (state, { productId, data }) => ({
      ...state,
      status: ProductsComparatorStatus.UpdateCompetitorProduct
    })
  ),
  on(
    ProductComparatorActions.updateCompetitorProductSuccess,
    (state, { productId, data }) => ({
      ...state,
      status: ProductsComparatorStatus.UpdateCompetitorProductSuccess,
      entities: {
        ...state.entities,
        product: {
          ...state.entities.product,
          [productId]: {
            ...state.entities.product[productId],
            ...data
          }
        }
      }
    })
  ),
  on(
    ProductComparatorActions.updateCompetitorProductError,
    (state, { productId, error }) => ({
      ...state,
      error,
      status: ProductsComparatorStatus.UpdateCompetitorProductError
    })
  ),
  on(
    ProductComparatorActions.wrongProductIntoComparison,
    (state, { comparisonId, productId: string }) => ({
      ...state,
      status: ProductsComparatorStatus.WrongProductIntoComparisonRequested
    })
  ),
  on(
    ProductComparatorActions.wrongProductIntoComparisonSuccess,
    (state, { comparisonId, productId, response }) => ({
      ...state,
      status: ProductsComparatorStatus.WrongProductIntoComparisonSuccess,
      entities: {
        ...state.entities,
        comparison: {
          ...state.entities.comparison,
          [comparisonId]: {
            ...state.entities.comparison[comparisonId],
            competitors: state.entities.comparison[
              comparisonId
            ].competitors.filter(competitor => competitor.product !== productId)
          }
        }
      }
    })
  ),
  on(
    ProductComparatorActions.wrongProductIntoComparisonError,
    (state, { comparisonId, productId, error }) => ({
      ...state,
      error,
      status: ProductsComparatorStatus.WrongProductIntoComparisonError
    })
  ),
  on(
    ProductComparatorActions.filterCompetitorsProductsOfComparison,
    (state, { comparisonId, filter }) => ({
      ...state,
      status:
        ProductsComparatorStatus.filterCompetitorsProductsOfComparisonRequested
    })
  ),
  on(
    ProductComparatorActions.filterCompetitorsProductsOfComparisonSuccess,
    (state, { comparison, filter }) => {
      return {
        ...state,
        filterProp: {
          ...filter
        },
        entities: {
          ...state.entities,
          comparison: {
            ...state.entities.comparison,
            [comparison["_id"]]: {
              ...comparison
            }
          }
        },
        status:
          ProductsComparatorStatus.filterCompetitorsProductsOfComparisonSuccess
      };
    }
  ),
  /**
   * Reducers for export actions
   */
  on(
    ProductComparatorActions.exportComparisonTOCSV,
    (state, { comparisonId, competitors, fileName }) => ({
      ...state,
      status: ProductsComparatorStatus.ExportComparisonTOCSVRequested
    })
  ),
  on(
    ProductComparatorActions.exportComparisonTOCSV,
    (state, { comparisonId, competitors, fileName }) => ({
      ...state,
      status: ProductsComparatorStatus.ExportComparisonTOCSVSuccess
    })
  ),
  on(
    ProductComparatorActions.exportComparisonTOCSV,
    (state, { comparisonId, competitors, fileName }) => ({
      ...state,
      status: ProductsComparatorStatus.ExportComparisonTOCSVError
    })
  ),
  /**
   * Sort comparison
   */
  on(
    ProductComparatorActions.sortComparison,
    (state, { comparisonId, comparison, filter }) => {
      let sortedCompetitors = [...comparison.competitors];

      if (filter.sortByRegularPrice) {
        if (filter.sortByRegularPrice === 1) {
          sortedCompetitors.sort((a, b) => {
            if (a.product.regularPrice > b.product.regularPrice) return 1;
            if (a.product.regularPrice < b.product.regularPrice) return -1;
            else 0;
          });
        } else {
          sortedCompetitors.sort((a, b) => {
            if (a.product.regularPrice > b.product.regularPrice) return -1;
            if (a.product.regularPrice < b.product.regularPrice) return 1;
            else 0;
          });
        }
      }

      if (filter.sortByDiscountedPrice) {
        if (filter.sortByDiscountedPrice === 1) {
          sortedCompetitors.sort((a, b) => {
            // equal items sort equally
            if (!a.product.discountedPrice && !b.product.discountedPrice) {
              return 0;
            }
            // nulls sort after anything else
            else if (!a.product.discountedPrice) {
              return 1;
            } else if (!b.product.discountedPrice) {
              return -1;
            }
            return a.product.discountedPrice < b.product.discountedPrice
              ? -1
              : 1;
          });
        } else {
          sortedCompetitors.sort((a, b) => {
            // equal items sort equally
            if (!a.product.discountedPrice && !b.product.discountedPrice) {
              return 0;
            }
            // nulls sort after anything else
            else if (!a.product.discountedPrice) {
              return -1;
            } else if (!b.product.discountedPrice) {
              return 1;
            }
            return a.product.discountedPrice < b.product.discountedPrice
              ? 1
              : -1;
          });
        }
      }

      comparison.competitors = [...sortedCompetitors];
      return {
        ...state,
        entities: {
          ...state.entities,
          comparison: {
            ...state.entities.comparison,
            [comparisonId]: {
              ...comparison,
              product: comparison.product["_id"],
              competitors: comparison.competitors.map(competitor => ({
                ...competitor,
                ecommerce: competitor.ecommerce["_id"],
                product: competitor.product["_id"]
              }))
            }
          }
        },
        status: ProductsComparatorStatus.SortComparisonRequested
      };
    }
  ),
  on(
    ProductComparatorActions.sortComparisonSuccess,
    (state, { comparisonId, comparison, filter }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          comparison: {
            ...state.entities.comparison,
            [comparisonId]: comparison
          }
        },
        status: ProductsComparatorStatus.SortComparisonSuccess
      };
    }
  ),
  on(ProductComparatorActions.sortComparisonError, (state, { error }) => ({
    ...state,
    error,
    status: ProductsComparatorStatus.SortComparisonError
  })),
  on(ProductComparatorActions.getMissingEcommercesFromComparison, (state, { comparisonId }) => ({
    ...state,
    status: ProductsComparatorStatus.GetMissingEcommercesFromComparison
  })),
  on(ProductComparatorActions.getMissingEcommercesFromComparisonSuccess, (state, { comparisonId, entities }) => ({
    ...state,
    entities: {
      ...state.entities,
      missingEcommerce: entities.ecommerce
    },
    status: ProductsComparatorStatus.GetMissingEcommercesFromComparisonSuccess
  }
  )),
  on(ProductComparatorActions.getMissingEcommercesFromComparisonError, (state, { error }) => ({
    ...state,
    error,
    status: ProductsComparatorStatus.GetMissingEcommercesFromComparisonError
  })),
  on(ProductComparatorActions.getViolatingEcommerces, (state) => ({
    ...state,
    status: ProductsComparatorStatus.GetViolatingEcommerces
  })),
  on(ProductComparatorActions.getViolatingEcommercesSuccess, (state, { entities }) => ({
    ...state,
    entities: {
      ...state.entities,
      violatingEcommerces: entities
    },
    status: ProductsComparatorStatus.GetViolatingEcommercesSuccess
  })),
  on(ProductComparatorActions.getViolatingEcommercesError, (state, { error }) => ({
    ...state,
    error,
    status: ProductsComparatorStatus.GetViolatingEcommercesError
  }))
);

export function reducer(state: ProductsComparatorState, action: Action) {
  return productComparatorReducers(state, action);
}

export const getProductsComparatorEntities = (state: ProductsComparatorState) =>
  state.entities;
export const getProductsComparatorStatus = (state: ProductsComparatorState) =>
  state.status;
export const getProductsComparatorError = (state: ProductsComparatorState) =>
  state.error;
