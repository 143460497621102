import { NgModule } from '@angular/core';
import { IMPORTS, DECLARATIONS, EXPORTS } from '.';

@NgModule({
  declarations: [
    ...DECLARATIONS
  ],
  imports: [
    ...IMPORTS
  ],
  exports: [
    ...EXPORTS
  ]
})
export class ProductsMatchingModule { }
