import { createSelector } from "@ngrx/store";
import { ApplicationState } from "../reducers";
import { AuthState } from "../reducers/auth.reducer";
import * as fromFeature from "../reducers";
import * as fromAuth from "../reducers/auth.reducer";

export const selectAuthState = (app: ApplicationState) => app.auth;

export const selectLoginError = createSelector(
  fromFeature.getAuthState,
  (state: AuthState) => {
    if (state.status === "Login Error") {
      return state.error;
    }
  }
);

export const selectUser = createSelector(
  fromFeature.getAuthState,
  fromAuth.getUser
);

export const selectUserToken = createSelector(
  fromFeature.getAuthState,
  (auth: AuthState) => auth.user.token
);

export const selectIfUserIsAdmin = createSelector(
  fromFeature.getAuthState,
  (auth: AuthState) => JSON.parse(localStorage.getItem('isAdmin')) as boolean);
