import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { ApplicationState } from "src/app/store/reducers";
import { AuthSelectors, EcommerceProductsSelectors } from "src/app/store/selectors";
import { filter } from "rxjs/operators";
import { ProductType } from "@sprintstudioco/ts-types";
import { EcommerceProductsActions } from "src/app/store/actions";
import { Observable } from "rxjs";

@Component({
  selector: "my-products",
  template: `
    <div class="flex flex-row bg-gray-100 space-x-4">
      <sidebar></sidebar>
      <div
        class="h-screen pb-5 w-full flex flex-col pt-4 space-y-3 overflow-y-scroll"
      >
        <div class=" pr-10 flex flex-col md:flex-row justify-between">
          <h4 class="text-indigo-900 font-semibold">Riepilogo</h4>
          <button
            *ngIf="isAnAdminUser$ | async"
            class="px-3 py-1 bg-gray-300 text-gray-700 font-semibold text-sm rounded"
            [routerLink]="['/my-product-manager/add']"
          >
            Aggiungi un prodotto
          </button>
        </div>
        <alert
          *ngIf="isEcommerceProductsSuccess | async"
          class="flex"
          [type]="'success'"
          [text]="isEcommerceProductsSuccess | async | json"
        ></alert>
        <alert
          *ngIf="isEcommerceProductsError | async"
          class="flex"
          [type]="'danger'"
          [text]="'Errore durante il tracciamento del prodotto'"
        ></alert>
        <my-products-lister
        [isAnAdminUser]="isAnAdminUser$ | async"
          [products]="products"
          [selectedProducts]="selectedProducts"
          [page]="page"
          (track)="track($event)"
          (untrack)="untrack($event)"
          (edit)="editProduct($event)"
          (select)="select($event)"
          (checkAll)="checkAll($event)"
          (previousPage)="previousPage($event)"
          (nextPage)="nextPage($event)"
        ></my-products-lister>
      </div>
    </div>
  `,
  styles: []
})
export class MyProductsComponent implements OnInit {
  private products: ProductType[] | any;
  private selectedProducts: { [id: string]: ProductType };
  private isEcommerceProductsSuccess: Observable<any>;
  private isEcommerceProductsError: Observable<any>;
  private page: number;
  private isAnAdminUser$: Observable<boolean>;

  constructor(private store: Store<ApplicationState>) { }

  ngOnInit() {
    this.isAnAdminUser$ = this.store.pipe(select(AuthSelectors.selectIfUserIsAdmin));

    this.store
      .pipe(
        select(EcommerceProductsSelectors.selectEcommerceProductsWithCategory),
        filter(data => data !== null)
      )
      .subscribe(products => (this.products = products));

    this.store
      .pipe(select(EcommerceProductsSelectors.selectCurrentPage))
      .subscribe(page => (this.page = page));

    this.store
      .pipe(
        select(EcommerceProductsSelectors.selectSelectedProducts),
        filter(data => data !== null)
      )
      .subscribe(selectedProducts => {
        this.selectedProducts = selectedProducts;
      });

    this.isEcommerceProductsSuccess = this.store.pipe(
      select(EcommerceProductsSelectors.selectEcommerceProductsSuccess),
      filter(data => data !== null)
    );

    this.isEcommerceProductsError = this.store.pipe(
      select(EcommerceProductsSelectors.selectEcommerceProductsError),
      filter(data => data !== null)
    );
  }

  track(id: string) {
    if (id) {
      this.store.dispatch(EcommerceProductsActions.trackProduct({ id }));
    } else {
      this.store.dispatch(
        EcommerceProductsActions.trackProducts({
          products: Object.keys(this.selectedProducts)
        })
      );
    }
  }

  untrack(id: string) {
    if (id) {
      this.store.dispatch(EcommerceProductsActions.untrackProduct({ id }));
    } else {
      this.store.dispatch(
        EcommerceProductsActions.untrackProducts({
          products: Object.keys(this.selectedProducts)
        })
      );
    }
  }

  select(id: string) {
    if (this.selectedProducts[id]) {
      this.store.dispatch(EcommerceProductsActions.uncheckProduct({ id }));
    } else {
      this.store.dispatch(EcommerceProductsActions.checkProduct({ id }));
    }
  }

  checkAll(ids: string[]) {
    if (Object.keys(this.selectedProducts).length > 0) {
      this.store.dispatch(EcommerceProductsActions.uncheckAllProducts());
    } else {
      this.store.dispatch(EcommerceProductsActions.checkAllProducts());
    }
  }

  previousPage() {
    this.store.dispatch(
      EcommerceProductsActions.previousPage({ page: this.page })
    );
  }

  nextPage() {
    this.store.dispatch(EcommerceProductsActions.nextPage({ page: this.page }));
  }
}
