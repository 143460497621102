import { createSelector } from "@ngrx/store";
import * as fromFeature from "../reducers";
import * as fromEcommerceProducts from "../reducers/ecommerce-products.reducer";
import { ProductsEntities } from "src/app/shared/models/product-entity";
import { EcommerceProductsStatus } from "src/app/shared/models/status";
import { ProductType } from "@sprintstudioco/ts-types";
import { Alert } from "src/app/shared/models/alert";

export const selectEcommerceProductsEntities = createSelector(
  fromFeature.getEcommerceProductsState,
  fromEcommerceProducts.getEcommerceProductsEntites
);

export const selectEcommerceProducts = createSelector(
  selectEcommerceProductsEntities,
  (entities: ProductsEntities) => Object.values(entities.product)
);

export const selectEcommerceProductById = (productId: string) =>
  createSelector(
    selectEcommerceProductsEntities,
    (entities: ProductsEntities) => entities.product[productId]
  );

export const selectLatestCreatedProduct = createSelector(
  selectEcommerceProductsEntities,
  (entities: ProductsEntities) => {
    const { length, [length - 1]: last } = Object.values(entities.product);
    return last;
  }
);

export const selectLatestStatus = createSelector(
  fromFeature.getEcommerceProductsState,
  (state: fromEcommerceProducts.EcommerceProductsState) => state.status
);

export const selectLatestCreatedProductIfSuccess = createSelector(
  selectLatestStatus,
  selectLatestCreatedProduct,
  (status, latestProduct) => {
    if (status === EcommerceProductsStatus.CreateProductSuccess) {
      return latestProduct;
    }
  }
);

// TODO Aggiungere a ProductType il campo category
export const selectEcommerceProductsWithCategory = createSelector(
  selectEcommerceProductsEntities,
  (entities: ProductsEntities) => {
    if (!entities) return;

    return Object.values(entities.product).map(product => ({
      ...product,
      category: entities.category[product["category"]]
    }));
  }
);

export const selectSelectedProducts = createSelector(
  fromFeature.getEcommerceProductsState,
  (state: fromEcommerceProducts.EcommerceProductsState) =>
    state.selectedProducts
);

export const selectEcommerceProductsSuccess = createSelector(
  fromFeature.getEcommerceProductsState,
  (state: fromEcommerceProducts.EcommerceProductsState) =>
    state.status === EcommerceProductsStatus.EcommerceProductsSuccess
      ? state.success
      : null
);

export const selectEcommerceProductsError = createSelector(
  fromFeature.getEcommerceProductsState,
  (state: fromEcommerceProducts.EcommerceProductsState) =>
    state.status === EcommerceProductsStatus.EcommerceProductsError
      ? state.error
      : null
);

export const selectUntrackedProductsSuccess = createSelector(
  fromFeature.getEcommerceProductsState,
  (state: fromEcommerceProducts.EcommerceProductsState) =>
    state.status === EcommerceProductsStatus.UntrackedProductsSuccess
      ? state.success
      : null
);

export const selectUntrackedProductsError = createSelector(
  fromFeature.getEcommerceProductsState,
  (state: fromEcommerceProducts.EcommerceProductsState) =>
    state.status === EcommerceProductsStatus.UntrackedProductsError
);

export const selectCurrentPage = createSelector(
  fromFeature.getEcommerceProductsState,
  (state: fromEcommerceProducts.EcommerceProductsState) => state.page
);

export const selectProductById = (productId: string) =>
  createSelector(
    selectEcommerceProductsEntities,
    (entities: ProductsEntities) => entities.product[productId]
  );

export const selectAlertSelected = createSelector(
  fromFeature.getEcommerceProductsState,
  (state: fromEcommerceProducts.EcommerceProductsState) => {
    if (state.selectedAlert) {
      return state.selectedAlert;
    }
  }
);

export const selectedAlertAndProductById = (productId: string) =>
  createSelector(
    selectProductById(productId),
    selectAlertSelected,
    (product: ProductType, alert: Alert) => ({ product, alert })
  );

export const selectIfProductAddUpdateIsSuccess = createSelector(
  fromFeature.getEcommerceProductsState,
  (state: fromEcommerceProducts.EcommerceProductsState) => {
    if (state.status === EcommerceProductsStatus.UpdateProductSuccess) {
      return {
        isSuccess: true,
        message: "Prodotto modificato correttamente"
      };
    }

    if (state.status === EcommerceProductsStatus.CreateProductSuccess) {
      return {
        isSuccess: true,
        message: "Prodotto creato correttamente"
      };
    }
  }
);

export const selectIfUpdateAlertByProductIdIsSuccess = createSelector(
  fromFeature.getEcommerceProductsState,
  (state: fromEcommerceProducts.EcommerceProductsState) => {
    if (
      state.status === EcommerceProductsStatus.UpdateAlertByProductIdSuccess
    ) {
      return {
        isSuccess: true,
        message: "Modifica salvata"
      };
    }
  }
);
