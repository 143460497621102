import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import CheckboxRadioItem from "src/app/shared/models/checkbox-item";

@Component({
  selector: "checkboxes-list-description",
  template: `
    <fieldset class="space-y-4">
      <legend class="sr-only">{{ title }}}}</legend>
      <div
        *ngFor="let item of items; let i = index"
        class="relative flex items-start"
        [class.opacity-25]="!item.enabled"
      >
        <div class="flex items-center h-5">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            [disabled]="!item.enabled"
            [checked]="selected === i"
            (click)="checkedItem.emit(i)"
          />
        </div>
        <div class="ml-3 text-sm">
          <label for="comments" class="font-medium text-gray-700">{{
            item.title
          }}</label>
          <p id="comments-description" class="text-gray-500">
            {{ item.description }}
          </p>
        </div>
      </div>
    </fieldset>
  `,
  styles: [],
})
export class CheckboxesListDescriptionComponent implements OnInit {
  @Input() title: string;
  @Input() items: CheckboxRadioItem[];
  @Input() selected: number;
  @Output() checkedItem: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  checkItem(index: number) {
    this.selected = index;
    this.checkedItem.emit(index);
  }
}
