import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { EcommerceType } from "@sprintstudioco/ts-types";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { User } from "src/app/shared/models/user";
import { ApplicationState } from "src/app/store/reducers";
import {
  AuthSelectors,
  ProductsComparatorSelectors
} from "src/app/store/selectors";

@Component({
  selector: "products-overview",
  template: `
    <div class="mb-10 space-y-3">
      <h4 class="text-indigo-900 font-semibold">Riepilogo</h4>
      <div class="flex flex-row justify-start space-x-3">
        <card-info
          class="w-64"
          [heading]="'Prodotti Monitorati'"
          [totalProducts]="productsComparison?.length"
        >
          <div class="h-12 px-3 py-2">
            <a
              class="h-full w-full cursor-pointer inline-flex items-center justify-center text-sm text-gray-400 text-center"
              routerLink="/my-products"
            >
              <span class="text-gray-600 font-semibold">Vedi tutti</span>
              <svg
                class="h-3 w-3 ml-2 mt-0 fill-current text-gray-500"
                version="1.1"
                id="Chevron_thin_right"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 20 20"
                enable-background="new 0 0 20 20"
                xml:space="preserve"
              >
                <path
                  d="M13.25,10L6.109,2.58c-0.268-0.27-0.268-0.707,0-0.979c0.268-0.27,0.701-0.27,0.969,0l7.83,7.908
    c0.268,0.271,0.268,0.709,0,0.979l-7.83,7.908c-0.268,0.271-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.979L13.25,10z"
                /></svg
            ></a>
          </div>
        </card-info>
     
        <card-info
          class="w-64"
          [heading]="'Rivenditori Monitorati'"
          [totalProducts]="(user$ | async)?.trackedEcommerces?.length"
        >
          <div class="h-12 px-3 py-2">
            <a
              class="h-full w-full cursor-pointer inline-flex items-center justify-center text-sm text-gray-400 text-center"
              routerLink="/competitor"
            >
              <span class="text-gray-600 font-semibold">Vedi tutti</span>
              <svg
                class="h-3 w-3 ml-2 mt-0 fill-current text-gray-500"
                version="1.1"
                id="Chevron_thin_right"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 20 20"
                enable-background="new 0 0 20 20"
                xml:space="preserve"
              >
                <path
                  d="M13.25,10L6.109,2.58c-0.268-0.27-0.268-0.707,0-0.979c0.268-0.27,0.701-0.27,0.969,0l7.83,7.908
c0.268,0.271,0.268,0.709,0,0.979l-7.83,7.908c-0.268,0.271-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.979L13.25,10z"
                /></svg
            ></a>
          </div>
        </card-info>
        <card-info
          class="w-64"
          [heading]="'Ultimo aggiornamento dei dati'"
          [content]="(user$ | async).lastDataUpdate | date"
        >
          <div class="h-12 px-3 py-2">
            <p class="text-xs uppercase text-gray-500">Prossimo aggiornamento dei dati</p>
            <p class="text-xs text-gray-600 font-semibold">
              {{ nextDataUpdate | date }}
            </p>
          </div>
        </card-info>
      </div>
    </div>
    <div class="space-y-3">
      <h4 class="text-indigo-900 font-semibold">Prodotti</h4>
      <div>
        <pricing-comparator
          [productsComparison]="productsComparison"
          [competitors]="competitors"
        ></pricing-comparator>
      </div>
    </div>
  `
})
export class ProductsOverviewComponent implements OnInit {
  private productsComparison: any[];
  private competitors: EcommerceType[];
  private user$: Observable<User>;
  private nextDataUpdate: Date;

  constructor(private store: Store<ApplicationState>) { }

  //dataUpdateFrequencyInDays

  ngOnInit(): void {
    this.store
      .pipe(
        select(ProductsComparatorSelectors.selectEcommerceCompetitors),
        filter(data => data !== null)
      )
      .subscribe(competitors => {
        this.competitors = competitors;
      });

    this.store
      .pipe(
        select(ProductsComparatorSelectors.selectCompetitorsProducts),
        filter(data => data !== null)
      )
      .subscribe(products => {
        this.productsComparison = products;
      });

    this.user$ = this.store.pipe(
      select(AuthSelectors.selectUser),
      filter(data => !!data),
      map(user => {
        if (user.lastDataUpdate) {
          const lastDataUpdate = new Date(user.lastDataUpdate);
          this.nextDataUpdate = new Date();
          this.nextDataUpdate.setDate(lastDataUpdate.getDate() + user.dataUpdateFrequencyInDays);
        }

        return user;
      })
    );
  }
}
