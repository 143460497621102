import { NgModule, } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';

import { AuthRoutingModule } from "./auth-routing.module";
import { LoginFormComponent } from "./presentationals/login-form/login-form.component";
import { AuthComponent } from "./containers/auth/auth.component";
import { RegisterFormComponent } from "./presentationals/register-form/register-form.component";

@NgModule({
  declarations: [LoginFormComponent, AuthComponent, RegisterFormComponent],
  imports: [CommonModule, FormsModule, AuthRoutingModule],
})
export class AuthModule {}
