import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { merge, of } from "rxjs";
import { catchError, mergeMap, map } from "rxjs/operators";
import { ReportsService } from "src/app/core/services/reports/reports.service";
import { ReportsAction } from "../actions/reports.action";

@Injectable()
export default class ReportsEffect {
  constructor(
    private actions$: Actions,
    private reportsService: ReportsService
  ) {}

  loadComparisonCompetitorsProductGroupedByCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsAction.loadComparisonCompetitorsProductGroupedByCountry),
      mergeMap(({ comparisonId }) =>
        this.reportsService
          .getComparisonCompetitorsProductGroupedByCountry(comparisonId)
          .pipe(
            map(data =>
              ReportsAction.loadComparisonCompetitorsProductGroupedByCountrySuccess(
                { comparisonId, chartData: data }
              )
            ),
            catchError(err =>
              of(
                ReportsAction.loadComparisonCompetitorsProductGroupedByCountryError(
                  { error: err }
                )
              )
            )
          )
      )
    )
  );

  loadComparisonCompetitorsProductGroupedByChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsAction.loadComparisonCompetitorsProductGroupedByChannels),
      mergeMap(({ comparisonId, channels }) =>
        this.reportsService
          .getComparisonCompetitorsProductGroupedByChannel(
            comparisonId,
            channels
          )
          .pipe(
            map(chartData =>
              ReportsAction.loadComparisonCompetitorsProductGroupedByyChannelsSuccess(
                { comparisonId, channels, chartData }
              )
            ),
            catchError(err =>
              of(
                ReportsAction.loadComparisonCompetitorsProductGroupedByyChannelsError(
                  { error: err }
                )
              )
            )
          )
      )
    )
  );

  loadAllProductsSplittedByCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsAction.loadAllProductsSplittedByCountry),
      mergeMap(() =>
        this.reportsService.getAllProductsSplittedByCountry().pipe(
          map(chartData =>
            ReportsAction.loadAllProductsSplittedByCountrySuccess({
              chartData
            })
          ),
          catchError(err =>
            of(
              ReportsAction.loadAllProductsSplittedByCountryError({
                error: err
              })
            )
          )
        )
      )
    )
  );

  loadFraudolentCompetitorsByMAPGroupedByCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsAction.loadFraudolentCompetitorsByMAPGroupedByCountry),
      mergeMap(props =>
        this.reportsService
          .getFraudulentCompetitorsByMAPGroupedByCountry(
            props.comparisonId,
            props.map
          )
          .pipe(
            map(chartData => {
              return ReportsAction.loadFraudolentCompetitorsByMAPGroupedByCountrySuccess(
                { comparisonId: props.comparisonId, map: props.map, chartData }
              );
            }),
            catchError(err => {
              return of(
                ReportsAction.loadFraudolentCompetitorsByMAPGroupedByCountryError(
                  { error: err }
                )
              );
            })
          )
      )
    )
  );

  loadFraudolentCompetitorsByMSRPGroupedByCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsAction.loadFraudolentCompetitorsByMSRPGroupedByCountry),
      mergeMap(({ comparisonId, msrp }) =>
        this.reportsService
          .getFraudulentCompetitorsByMSRPGroupedByCountry(comparisonId, msrp)
          .pipe(
            map(chartData =>
              ReportsAction.loadFraudolentCompetitorsByMSRPGroupedByCountrySuccess(
                { comparisonId, msrp, chartData }
              )
            ),
            catchError(err =>
              of(
                ReportsAction.loadFraudolentCompetitorsByMSRPGroupedByCountryError(
                  { error: err }
                )
              )
            )
          )
      )
    )
  );

  loadFraudulentCompetitorsByMAP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsAction.loadFraudolentCompetitorsByMAP),
      mergeMap(props =>
        this.reportsService
          .loadFraudulentCompetitorsByMAP(props.comparisonId, props.map)
          .pipe(
            map(competitors =>
              ReportsAction.loadFraudolentCompetitorsByMAPSuccess({
                competitors
              })
            ),
            catchError(err =>
              of(
                ReportsAction.loadFraudolentCompetitorsByMAPError({
                  error: err
                })
              )
            )
          )
      )
    )
  );

  loadProductInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsAction.loadProductInsights),
      mergeMap(({ comparisonId }) =>
        this.reportsService.loadProductInsights(comparisonId).pipe(
          map(chartData =>
            ReportsAction.loadProductInsightsSuccess({
              chartData
            })
          ),
          catchError(err =>
            of(
              ReportsAction.loadProductInsightsError({
                error: err
              })
            )
          )
        )
      )
    )
  );
}
