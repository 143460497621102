import { createSelector } from "@ngrx/store";
import * as fromFeature from "../reducers";
import * as fromProductComparator from "../reducers/products-comparator.reducer";
import {
  Comparison,
  CompetitorProductEmbedded,
  CompetitorProductNormalized,
  ProductComparator,
} from "src/app/shared/models/product-comparator";
import { EcommerceType, ProductType } from "@sprintstudioco/ts-types";
import ViolationsByEcommerce from "src/app/shared/models/violations-by-ecommerce";

/**
 * Select the array of products comparison
 */
export const selectProductComparatorEntities = createSelector(
  fromFeature.getProductsComparatorState,
  fromProductComparator.getProductsComparatorEntities
);

export const selectAllProductComparisonId = createSelector(
  selectProductComparatorEntities,
  (entities: ProductComparator) => Object.keys(entities.comparison)
);

export const selectFirstProductComparisonId = createSelector(
  selectProductComparatorEntities,
  (entities: ProductComparator) => Object.keys(entities.comparison)[0]
);

export const selectCompetitorsProducts = createSelector(
  selectProductComparatorEntities,
  (comparator: ProductComparator) => {
    if (!comparator || Object.keys(comparator).length === 0) return;

    let { product, ecommerce, comparison } = comparator;

    let competitors = new Array(Object.keys(ecommerce).length).fill({
      ecommerce: {},
      product: {},
      is_lowest: false,
    });

    let rest = [];

    return Object.values(comparison).map((elem) => {
      elem.competitors.forEach((competitor) => {
        rest.push({
          ecommerce: ecommerce[competitor.ecommerce],
          product: product[competitor.product],
          is_lowest: competitor.is_lowest,
        });
      });

      competitors = [...rest, ...competitors];

      return {
        ...elem,
        product: product[elem.product],
        competitors: elem.competitors.map((competitor) => ({
          ecommerce: ecommerce[competitor.ecommerce],
          product: product[competitor.product],
          is_lowest: competitor.is_lowest,
        })),
      };
    });
  }
);

export const selectEcommerceCompetitors = createSelector(
  selectProductComparatorEntities,
  (entities: ProductComparator) => {
    if (!entities || Object.keys(entities).length === 0) return;

    return entities ? Object.values(entities.ecommerce) : null;
  }
);

export const selectEcommerceCompetitorsById = (ids: string[]) =>
  createSelector(
    selectProductComparatorEntities,
    (entities: ProductComparator) => {
      if (!entities || Object.keys(entities).length === 0) return;

      let ecommerces = {};

      ids.map((id) => {
        ecommerces = {
          ...ecommerces,
          [id]: entities.ecommerce[id],
        };
      });

      return ecommerces;
    }
  );

export const selectEcommerceCompetitorById = (id: string) =>
  createSelector(
    selectProductComparatorEntities,
    (entities: ProductComparator) => {
      if (!entities || Object.keys(entities).length === 0) return;

      return entities.ecommerce[id];
    }
  );

export const selectEcommerceByName = (ecommerceName: string) =>
  createSelector(
    selectProductComparatorEntities,
    (entities: ProductComparator) => {
      if (!entities) return;

      return Object.values(entities.ecommerce).find(
        (ecommerce) => ecommerce.name === ecommerceName
      );
    }
  );

export const selectProductComparisonById = (comparisonId: string) =>
  createSelector(
    selectProductComparatorEntities,
    (entities: ProductComparator) => {
      if (!entities) return;

      return {
        ...entities.comparison[comparisonId],
        product: entities.product[entities.comparison[comparisonId].product],
        competitors: entities.comparison[comparisonId].competitors.map(
          (competitor) => ({
            ...competitor,
            product: entities.product[competitor.product],
            ecommerce: entities.ecommerce[competitor.ecommerce],
          })
        ),
      };
    }
  );

export const selectCompetitorByProductId = (
  comparisonId: string,
  productId: string
) =>
  createSelector(
    selectProductComparisonById(comparisonId),
    (comparison: Comparison<CompetitorProductEmbedded, ProductType>) => {
      const [first, ...rest] = comparison.competitors.filter(
        (competitor) => competitor.product["_id"] === productId
      );

      return first;
    }
  );

export const selectAllCompetitorsProductUrlFromSelectedComparison = (
  comparisonId: string
) =>
  createSelector(selectProductComparisonById(comparisonId), (comparison) => {
    if (!comparison) return;

    return Object.values(comparison.competitors).map(
      (competitor) => competitor.product.url
    );
  });

export const selectAllCompetitorsProductFromSelectedComparison = (
  comparisonId: string
) =>
  createSelector(selectProductComparisonById(comparisonId), (comparison) => {
    if (!comparison) return;

    return Object.values(comparison.competitors).map(
      (competitor) => competitor.product
    );
  });

export const selectFilterProp = createSelector(
  fromFeature.getProductsComparatorState,
  (state: fromProductComparator.ProductsComparatorState) => state.filterProp
);

export const selectAllCompetitorsProductsId = createSelector(
  selectProductComparatorEntities,
  (entities: ProductComparator) => {
    if (!entities) return;

    return [
      ...Object.values(entities.comparison).map(({ competitors }) =>
        competitors.map(({ product }) => product)
      ),
    ];
  }
);

export const selectAllCompetitorsProducts = createSelector(
  selectProductComparatorEntities,
  (entities: ProductComparator) => {
    if (!entities) return;

    // TODO Sistemare qui ed usare ComparisonType se presente
    const allCompetitors: any[] = [].concat(
      ...(Object.values(entities.comparison) as any[]).map(
        ({ product, map, msrp, competitors }) =>
          competitors.map((competitor) => ({
            productName: entities.product[product].name,
            map,
            msrp,
            ...competitor,
          }))
      )
    );

    const products = allCompetitors.map(
      ({ productName, map, msrp, product }) => ({
        productName,
        name: entities.product[product].name,
        country: entities.product[product].country,
        regularPrice: entities.product[product].regularPrice,
        discountedPrice: entities.product[product].discountedPrice || "-",
        discount: entities.product[product].discount || "-",
        MAP: map || "-",
        "Differenza MAP": entities.product[product].discountedPrice
          ? (entities.product[product].discountedPrice - map).toFixed(2)
          : (entities.product[product].regularPrice - map).toFixed(2),
        MSRP: msrp || "-",
        url: entities.product[product].url,
        image: entities.product[product].url,
        timestamp: entities.product[product]["timestamp"],
        ecommerce: entities.product[product].ecommerce,
      })
    );

    return products;
  }
);

export const selectAllCompetitorsProductsWithEcommercePopulatedByName = createSelector(
  selectProductComparatorEntities,
  selectAllCompetitorsProducts,
  (entities: ProductComparator, products: any[]) => {
    if (!entities || !products) return;

    return products.map((product) => ({
      Rivenditore: entities.ecommerce[product.ecommerce]?.name,
      ...product,
    }));
  }
);

export const selectAllCompetitorsProductsWithEcommercePopulatedByName2 = createSelector(
  selectProductComparatorEntities,
  selectAllCompetitorsProducts,
  (entities: ProductComparator, products: any[]) => {
    if (!entities || !products) return;

    return products.map((product) => ({
      ...product,
      ecommerce: entities.ecommerce[product.ecommerce].name,
    }));
  }
);

export const selectEcommerceAllProductsByEcommerceId = (ecommerceId: string) =>
  createSelector(
    selectProductComparatorEntities,
    (entities: ProductComparator) => {
      if (!entities) return;

      const allProducts = [].concat(
        ...Object.values(entities.comparison).map((comparison) =>
          comparison.competitors.map((competitor) => {
            return {
              map: entities.comparison[comparison._id]["map"],
              msrp: entities.comparison[comparison._id]["msrp"],
              ...entities.product[competitor.product],
            };
          })
        )
      );

      return []
        .concat(allProducts)
        .filter((product) => product.ecommerce === ecommerceId);
    }
  );

export const selectMissingEcommercesIntoComparison = createSelector(
  selectProductComparatorEntities,
  (entities: ProductComparator) => {
    if (!entities || !entities.missingEcommerce) return;

    return Object.values(entities.missingEcommerce);
  }
);

export const selectEcommercesViolatingPolicies = createSelector(
  selectProductComparatorEntities,
  (entities: ProductComparator) => {
    if (!entities || !entities.violatingEcommerces) return;

    return Object.keys(entities.violatingEcommerces).map(ecommerceId => ({
      ...entities.violatingEcommerces[ecommerceId],
      ecommerce: entities.ecommerce[ecommerceId]
    }));
  }
);
