import { Component, Input, OnInit } from "@angular/core";
import { ProductType } from '@sprintstudioco/ts-types';

@Component({
  selector: "my-product",
  template: `
    <div class="flex flex-col">
      <div class="w-full h-64 flex items-center justify-center">
        <img class="h-full place-self-center" [src]="product?.image">
      </div>
      <div class="w-full p-2">
        <product-specs [product]="product"></product-specs>
      </div>
    </div>
  `,
  styles: [],
})
export class MyProductComponent {
  @Input() product: ProductType;
}
