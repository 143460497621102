import { ThrowStmt } from "@angular/compiler";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "pr-switch",
  template: `
    <button
      type="button"
      class="bg-gray-200 relative inline-flex items-center flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
      role="switch"
      [ngClass]="[checked ? classNamesWhenEnabled : classNamesWhenDisabled]"
      (click)="switchClicked()"
    >
      <span class="sr-only">Use setting</span>
      <span
        class="translate-x-0 pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        [ngClass]="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
      >
        <span
          class="opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          aria-hidden="true"
          [ngClass]="{
            'opacity-0 ease-out duration-100': checked,
            'opacity-100 ease-in duration-200': !checked
          }"
        >
          <ng-content select="[icon-disabled]"></ng-content>
        </span>
        <span
          class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          aria-hidden="true"
          [ngClass]="{
            'opacity-100 ease-in duration-200': checked,
            'opacity-0 ease-out duration-100': !checked
          }"
        >
          <ng-content select="[icon-checked]"></ng-content>
        </span>
      </span>
    </button>
  `,
  styles: [],
})
export class PrSwitchComponent implements OnInit {
  @Input() checked: boolean;
  @Input() classNamesWhenEnabled: string;
  @Input() classNamesWhenDisabled: string;
  @Output() switched: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  switchClicked() {
    this.checked = !this.checked;
    this.switched.emit(this.checked);
  }
}
