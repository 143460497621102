import { createAction, props } from "@ngrx/store";

export const RoutingActions = {
  routerGo: createAction(
    "[Router] Go",
    props<{ path: any[]; queryParams?: object; extras?: object }>()
  ),

  routerBack: createAction("[Router] Back"),
};
