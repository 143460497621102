import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProductType } from "@sprintstudioco/ts-types";
import { Alert } from "src/app/shared/models/alert";

@Component({
  selector: "map-msrp-manager",
  template: `
    <div class="grid grid-cols-1 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            MAP e MSRP
          </h3>
          <p class="mt-1 text-sm text-gray-600">
            Imposta il MAP e MSRP che verranno utilizzati in fase di analisi
          </p>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form
          #mapMsrpForm="ngForm"
          action="#"
          method="POST"
          (ngSubmit)="onSubmit.emit(mapMsrpForm.value)"
        >
          <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6 space-y-4">
              <ng-content></ng-content>
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="map"
                    class="block text-sm font-medium text-gray-700"
                    >MAP (Minimum Advertised Price)</label
                  >
                  <input
                    type="number"
                    name="map"
                    id="map"
                    autocomplete="MAP"
                    class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    [ngModel]="MAPandMSRP?.map"
                    required=""
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="MSRP"
                    class="block text-sm font-medium text-gray-700"
                    >MSRP (Manufacturer’s Suggested Retail Price)</label
                  >
                  <input
                    type="number"
                    name="msrp"
                    id="msrp"
                    autocomplete="MSRP"
                    class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    [ngModel]="MAPandMSRP?.msrp"
                    required=""
                  />
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  `,
  styles: []
})
export class MapMsrpManagerComponent {
  @Input() MAPandMSRP: Alert;
  @Output() onSubmit: EventEmitter<{
    map: number;
    msrp: number;
  }> = new EventEmitter();

  constructor() {}
}
