import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList
} from "@angular/core";
import { PrButtonToggleComponent } from "../pr-button-toggle/pr-button-toggle.component";

@Component({
  selector: "pr-button-toggle-group",
  template: `
    <div class="p-1 flex space-x-1 justify-end">
      <ng-content select="pr-button-toggle"></ng-content>
    </div>
  `,
  styles: []
})
export class PrButtonToggleGroupComponent implements AfterContentInit {
  @ContentChildren(PrButtonToggleComponent) toggleButtons: QueryList<
    PrButtonToggleComponent
  >;

  constructor() {}

  ngAfterContentInit(): void {
    this.toggleButtons.changes.subscribe(({ id, value }) => {});
  }
}
