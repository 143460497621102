import { createAction, props } from "@ngrx/store";
import { Credential } from "src/app/shared/models/credential";
import { User } from "src/app/shared/models/user";

export const AuthActions = {
  login: createAction("[LoginForm Component] Login", props<Credential>()),

  loginSuccess: createAction(
    "[Auth Effects] Login Success",
    props<{ token: string }>()
  ),

  logout: createAction("[Sidebar] Logout"),

  logoutSuccess: createAction("[Auth Effects] Logout"),

  loginError: createAction(
    "[Auth Effects] Login Error",
    props<{ error: any }>()
  ),

  loadAuthToken: createAction("[App Component] Load Auth Token"),

  loadAuthTokenSuccess: createAction(
    "[Token Effects] Load Auth Token Success",
    props<{ token: string }>()
  ),

  loadAuthTokenError: createAction(
    "[Token Effects] Load Auth Token Error",
    props<{ error: object }>()
  ),

  loadUserProfile: createAction("[UserResolver] Load User Profile Requested"),

  loadUserProfileSuccess: createAction(
    "[UserEffect] Load User Profile Success",
    props<{ user: User }>()
  ),

  loadUserProfileError: createAction(
    "[UserEffect] Load User Profile Error",
    props<{ error: any }>()
  ),
};
