import { createReducer, on, Action } from "@ngrx/store";
import { ReportsCharts } from "src/app/shared/models/reports-entities";
import { ReportsStatus } from "src/app/shared/models/status";
import { ReportsAction } from "../actions/reports.action";

export interface ReportsState {
  entities?: ReportsCharts;
  status?: string;
  error?: string;
}

export const initialState: ReportsState = {};

export const reportsReducers = createReducer(
  initialState,
  on(
    ReportsAction.loadComparisonCompetitorsProductGroupedByCountry,
    (state, { comparisonId }) => ({
      ...state,
      status:
        ReportsStatus.LoadComparisonCompetitorsProductGroupedByCountryRequested
    })
  ),
  on(
    ReportsAction.loadComparisonCompetitorsProductGroupedByCountrySuccess,
    (state, { comparisonId, chartData }) => ({
      ...state,
      entities: {
        ...state.entities,
        comparisonCompetitorsGroupedByCountry: chartData
      },
      status:
        ReportsStatus.LoadComparisonCompetitorsProductGroupedByCountrySucess
    })
  ),
  on(
    ReportsAction.loadComparisonCompetitorsProductGroupedByCountryError,
    (state, { error }) => ({
      ...state,
      error,
      status:
        ReportsStatus.LoadComparisonCompetitorsProductGroupedByCountryError
    })
  ),
  // Load All Products Splitted by Country
  on(ReportsAction.loadAllProductsSplittedByCountry, state => ({
    ...state,
    status: ReportsStatus.LoadAllProductsSplittedByCountryRequested
  })),
  on(
    ReportsAction.loadAllProductsSplittedByCountrySuccess,
    (state, { chartData }) => ({
      ...state,
      entities: {
        ...state.entities,
        allProductsSplittedByCountry: chartData
      },
      status: ReportsStatus.LoadAllProductsSplittedByCountrySuccess
    })
  ),
  on(
    ReportsAction.loadAllProductsSplittedByCountryError,
    (state, { error }) => ({
      ...state,
      error,
      status: ReportsStatus.LoadAllProductsSplittedByCountryError
    })
  ),
  // Load into state all products grouped by channels
  on(
    ReportsAction.loadComparisonCompetitorsProductGroupedByChannels,
    state => ({
      ...state,
      status:
        ReportsStatus.LoadComparisonCompetitorsProductGroupedByChannelsRequested
    })
  ),
  on(
    ReportsAction.loadComparisonCompetitorsProductGroupedByyChannelsSuccess,
    (state, { comparisonId, channels, chartData }) => ({
      ...state,
      entities: {
        ...state.entities,
        allProductsSplittedByChannels: chartData
      },
      status:
        ReportsStatus.LoadComparisonCompetitorsProductGroupedByChannelsSucess
    })
  ),
  //
  on(
    ReportsAction.loadFraudolentCompetitorsByMAP,
    (state, { comparisonId }) => ({
      ...state,
      status: ReportsStatus.LoadFraudolentCompetitorsByMAPRequested
    })
  ),
  on(
    ReportsAction.loadFraudolentCompetitorsByMAPSuccess,
    (state, { competitors }) => ({
      ...state,
      entities: {
        ...state.entities,
        fraudolentCompetitorsByMAP: competitors
      },
      status: ReportsStatus.LoadFraudolentCompetitorsByMAPSuccess
    })
  ),
  on(ReportsAction.loadFraudolentCompetitorsByMAPError, (state, { error }) => ({
    ...state,
    error,
    status: ReportsStatus.LoadFraudolentCompetitorsByMAPError
  })),
  // Fraudolent Competitors by MAP Grouped by Country
  on(
    ReportsAction.loadFraudolentCompetitorsByMAPGroupedByCountry,
    (state, { comparisonId, map }) => ({
      ...state,
      status:
        ReportsStatus.LoadFraudolentCompetitorsByMAPGroupedByCountryRequested
    })
  ),

  /**
   * .map(data => ({
          ...data,
          series: [...data.series, { name: "map", value: map }]
        }))
   */
  on(
    ReportsAction.loadFraudolentCompetitorsByMAPGroupedByCountrySuccess,
    (state, { comparisonId, map, chartData }) => ({
      ...state,
      entities: {
        ...state.entities,
        fraudolentCompetitorsByMAP: chartData
      },
      status:
        ReportsStatus.LoadFraudolentCompetitorsByMAPGroupedByCountrySuccess
    })
  ),
  on(
    ReportsAction.loadFraudolentCompetitorsByMAPGroupedByCountryError,
    (state, { error }) => ({
      ...state,
      error,
      status: ReportsStatus.LoadFraudolentCompetitorsByMAPGroupedByCountryError
    })
  ),
  // Fraudolent Competitors by MSRP Grouped by Country
  on(
    ReportsAction.loadFraudolentCompetitorsByMSRPGroupedByCountry,
    (state, { comparisonId, msrp }) => ({
      ...state,
      status:
        ReportsStatus.LoadFraudolentCompetitorsByMSRPGroupedByCountryRequested
    })
  ),
  on(
    ReportsAction.loadFraudolentCompetitorsByMSRPGroupedByCountrySuccess,
    (state, { comparisonId, msrp, chartData }) => ({
      ...state,
      entities: {
        ...state.entities,
        fraudolentCompetitorsByMSRP: chartData
      },
      status:
        ReportsStatus.LoadFraudolentCompetitorsByMSRPGroupedByCountrySuccess
    })
  ),
  on(
    ReportsAction.loadFraudolentCompetitorsByMSRPGroupedByCountryError,
    (state, { error }) => ({
      ...state,
      error,
      status: ReportsStatus.LoadFraudolentCompetitorsByMSRPGroupedByCountryError
    })
  ),
  /**
   * Product Insights on Profile
   */
  on(ReportsAction.loadProductInsights, state => ({
    ...state,
    status: ReportsStatus.LoadAllProductsSplittedByCountryRequested
  })),
  on(
    ReportsAction.loadProductInsightsSuccess,
    (state, { comparisonId, chartData }) => ({
      ...state,
      entities: {
        ...state.entities,
        productInsights: chartData
      },
      status: ReportsStatus.LoadAllProductsSplittedByCountrySuccess
    })
  ),
  on(ReportsAction.loadProductInsightsError, (state, { error }) => ({
    ...state,
    error,
    status: ReportsStatus.LoadAllProductsSplittedByCountryError
  }))
);

export function reducer(state: ReportsState, action: Action) {
  return reportsReducers(state, action);
}

export const getReportsEntities = (state: ReportsState) => state.entities;
export const getReportsStatus = (state: ReportsState) => state.status;
export const getReportsError = (state: ReportsState) => state.error;
