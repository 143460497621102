import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  EventEmitter
} from "@angular/core";
import { PrTabNavigationComponent } from "../pr-tab-navigation/pr-tab-navigation.component";
import { PrTabPanelComponent } from "../pr-tab-panel/pr-tab-panel.component";

@Component({
  selector: "pr-tab-group",
  template: `
    <pr-tab-navigation [tabLabels]="tabLabels"></pr-tab-navigation>
    <ng-content select="pr-tab-panel"></ng-content>
  `,
  styles: []
})
export class PrTabGroupComponent
  implements OnInit, AfterContentInit, AfterViewInit {
  private tabLabels: { id: string; label: string }[];

  @ViewChildren(PrTabNavigationComponent) tabs: QueryList<
    PrTabNavigationComponent
  >;
  @ContentChildren(PrTabPanelComponent) panels: QueryList<PrTabPanelComponent>;

  @Output() labelClicked: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngAfterContentInit(): void {
    this.tabLabels = this.panels.map(panel => ({
      id: panel.id,
      label: panel.label
    }));
  }

  ngAfterViewInit(): void {
    this.tabs.map(tab =>
      tab.changeTab.subscribe(_tab => {
        this.panels.forEach(panel => (panel.show = false));
        this.panels.filter(panel => panel.label === _tab.label)[0].show = true;
        this.labelClicked.emit(_tab);
      })
    );
  }

  ngOnInit(): void {}
}
