import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { EcommerceType, ProductType } from "@sprintstudioco/ts-types";
import { combineLatest, forkJoin, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ApplicationState } from "src/app/store/reducers";
import { ProductsComparatorSelectors } from "src/app/store/selectors";

@Component({
  selector: "product-profile",
  template: `
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        (click)="close()"
      ></div>

      <div class="w-full h-full flex justify-end">
        <div
          class="w-1/4 border-l z-20 p-5 flex flex-col justify-between items-center space-y-8 bg-white overflow-y-scroll"
        >
          <div class="w-full flex flex-col">
            <div>
              <span class="text-xl font-medium text-gray-800"
                >{{ ecommerce.name }} &bull;
                <span
                  class="px-2 w- flex-none text-sm rounded-full bg-gray-200 text-gray-700"
                  >{{ product.country }}</span
                ></span
              >
            </div>
            <p class="text-sm text-gray-600">
              Scorri per aumentare o diminuire lo zoom dell'immagine
            </p>
          </div>
          <lib-ngx-image-zoom
            [thumbImage]="product.image"
            [fullImage]="product.image"
            [enableLens]="true"
            [circularLens]="true"
            [enableScrollZoom]="true"
            [magnification]="2"
            [lensWidth]="200"
            [lensHeight]="200"
            [scrollStepSize]="0.2"
          ></lib-ngx-image-zoom>
          <div class="w-full px-5 py-4 flex flex-col border rounded">
            <dl>
              <dt class="text-xs text-gray-500 uppercase font-semibold">
                Nome
              </dt>
              <dd class="pb-5 text-lg">{{ product.name }}</dd>
              <dd class="pb-5">
                <div class="flex space-x-10">
                  <dl>
                    <dt class="text-xs text-gray-500 uppercase font-semibold">
                      Prezzo regolare
                    </dt>
                    <dd class="pb-5">{{ product.regularPrice }} €</dd>
                  </dl>
                  <dl>
                    <dt class="text-xs text-gray-500 uppercase font-semibold">
                      Prezzo scontato
                    </dt>
                    <dd class="pb-5">
                      {{
                        product.discountedPrice
                          ? product.discountedPrice + "€"
                          : "-"
                      }}
                    </dd>
                  </dl>
                </div>
              </dd>
              <dd class="pb-5">
                <details>
                  <summary
                    class="text-xs text-gray-500 uppercase font-semibold outline-none"
                  >
                    Descrizione
                  </summary>
                  {{
                    product.description ||
                      "Non è stata rilevata una descrizione per " +
                        ecommerce.name
                  }}
                </details>
              </dd>
              <dt class="">
                <a
                  class="inline-flex space-x-2 text-sm text-indigo-700 font-medium"
                  [href]="product.url"
                  target="_blank"
                  ><span class="flex-1">Vedi scheda prodotto</span>
                  <svg
                    class="w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    /></svg
                ></a>
              </dt>
            </dl>
          </div>
          <div
            *ngIf="false"
            class="w-full flex justify-between items-center border rounded"
          >
            <button
              class="py-2 inline-flex justify-center items-center flex-1 border-r"
            >
              <svg
                class="w-5 h-5 text-gray-800"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
            </button>
            <button class="py-2 inline-flex justify-center items-center flex-1">
              <svg
                class="w-5 h-5 text-gray-800"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class ProductProfileComponent implements OnInit {
  private ecommerce: EcommerceType;
  private product: ProductType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<ApplicationState>
  ) {}

  ngOnInit(): void {
    const activatedRouteParams$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get("productId")),
      map(s => {
        return s;
      })
    );

    const parentRouteParams$ = this.route.parent.paramMap.pipe(
      map(paramMap => paramMap.get("comparisonId")),
      map(s => {
        return s;
      })
    );

    combineLatest([parentRouteParams$, activatedRouteParams$])
      .pipe(
        switchMap(([comparisonId, productId]) => {
          return this.store.pipe(
            select(
              ProductsComparatorSelectors.selectCompetitorByProductId(
                comparisonId,
                productId
              )
            )
          );
        })
      )
      .subscribe(({ ecommerce, product }) => {
        this.ecommerce = ecommerce;
        this.product = product;
      });
  }

  close() {
    this.router.navigate(["../.."], { relativeTo: this.route });
  }
}
