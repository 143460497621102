import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Alert } from "src/app/shared/models/alert";

@Component({
  selector: "my-products-notifications-manager",
  template: `
    <div class="grid grid-cols-1 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Notifications & Actions
          </h3>
          <p class="mt-1 text-sm text-gray-600">
            Ti invieremo un'istantanea del mercato una volta aver analizzato
            tutti i rivenditori collegati al tuo account
          </p>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form
          #notificationsForm="ngForm"
          action="#"
          method="POST"
          (ngSubmit)="onSubmit.emit(notificationsForm.value)"
        >
          <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <fieldset>
                <legend class="text-base font-medium text-gray-900">
                  Notifiche
                </legend>
                <div class="mt-4 space-y-4">
                  <div class="flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="notifyWhenPriceLessThanMAP"
                        name="notifyWhenPriceLessThanMAP"
                        type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        [checked]="alert?.notifyWhenPriceLessThanMAP"
                        [ngModel]="alert?.notifyWhenPriceLessThanMAP"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="notifyWhenPriceLessThanMAP"
                        class="font-medium text-gray-700"
                        >Inviami un'email quando il prezzo di vendita è minore
                        del MAP</label
                      >
                      <p class="text-gray-500"></p>
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="notifyWhenPriceGreaterThanMSRP"
                        name="notifyWhenPriceGreaterThanMSRP"
                        type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        [checked]="alert?.notifyWhenPriceGreaterThanMSRP"
                        [ngModel]="alert?.notifyWhenPriceGreaterThanMSRP"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="notifyWhenPriceGreaterThanMSRP"
                        class="font-medium text-gray-700"
                        >Inviami un'email quando il prezzo di vendita è maggiore
                        del MSRP</label
                      >
                      <p class="text-gray-500"></p>
                    </div>
                  </div>
                </div>
                <div
                  class="mt-3 pt-3 border-t flex items-start pointer-events-none opacity-25"
                >
                  <div class="flex items-center h-5">
                    <input
                      id="screenshot"
                      name="screenshot"
                      type="checkbox"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      ngModel
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="screenshot" class="font-medium text-gray-700"
                      >Fai uno screenshot al sito quando il prezzo di vendita è
                      minore del MAP</label
                    >
                    <p class="text-gray-500"></p>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  `,
  styles: [],
})
export class MyProductsNotificationsManagerComponent implements OnInit {
  @Input() alert: Alert;
  @Output() onSubmit: EventEmitter<Alert> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
