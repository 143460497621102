import { BaseState } from ".";
import { createReducer, on, Action } from "@ngrx/store";
import { CompetitorsActions } from "../actions";
import { CompetitorsStatus } from "src/app/shared/models/status";
import { EcommerceType } from "@sprintstudioco/ts-types";

export interface CompetitorsState extends BaseState {
  entities: Record<string, EcommerceType>;
}

export const initialState: CompetitorsState = {
  entities: {},
  page: 1,
  sort: {
    sortBy: "",
    order: "",
  },
};

export const competitorsReducers = createReducer(
  initialState,
  on(CompetitorsActions.loadCompetitors, (state) => ({
    ...state,
    status: CompetitorsStatus.LoadingCompetitors,
  })),
  on(CompetitorsActions.loadCompetitorsSuccess, (state, { entities }) => ({
    ...state,
    entities,
    status: CompetitorsStatus.LoadedCompetitorsSuccess,
  })),
  on(CompetitorsActions.loadCompetitorsError, (state, { error }) => ({
    ...state,
    error,
    status: CompetitorsStatus.LoadedCompetitorsError,
  })),
  on(
    CompetitorsActions.sortByFieldSuccess,
    (state, { entities, field, order }) => ({
      ...state,
      sort: {
        ...state.sort,
        sortBy: field,
        order,
      },
      entities,
      status: CompetitorsStatus.SortByFieldSuccess,
    })
  ),
  on(CompetitorsActions.sortByFieldError, (state, { error }) => ({
    ...state,
    error,
    status: CompetitorsStatus.SortByFieldError,
  })),
  on(CompetitorsActions.previousCompetitorsPage, (state, { page }) => ({
    ...state,
    page: state.page > 1 ? state.page - 1 : 1,
  })),
  on(CompetitorsActions.nextCompetitorsPage, (state, { page }) => ({
    ...state,
    page: state.page + 1,
  }))
);

export function reducer(state: CompetitorsState, action: Action) {
  return competitorsReducers(state, action);
}

export const getCompetitorsEntities = (state: CompetitorsState) =>
  state.entities;
