import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FilterProp } from "../../models/filter";

@Component({
  selector: "dropdown",
  template: `
    <div class="relative">
      <a class="flex items-center space-x-1" (click)="showStatusDropdown = !showStatusDropdown">
        <ng-content></ng-content>
        <svg
          class="w-4"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M19 9l-7 7-7-7"></path>
        </svg>
      </a>
      <div
        [hidden]="!showStatusDropdown"
        class="mt-1 w-40 absolute right-0 bg-white shadow-md rounded-md border space-y-2 capitalize text-sm font-normal"
      >
        <div class="flex flex-col items-center space-x-1">
          <label
            *ngFor="let label of labels"
            class="w-full inline-flex space-x-2 items-center p-2 text-sm text-left border-b"
          >
            <input
              type="radio"
              name="country"
              class=""
              (click)="click(label)"
            />
            <label for="all">{{ label.name }}</label>
          </label>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class DropdownComponent implements OnInit {
  private showStatusDropdown: boolean;

  @Input() labels: FilterProp[];
  @Output() clickOnLabel: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  click(event) {
    this.showStatusDropdown = false;
    this.clickOnLabel.emit(event)
  }
}
