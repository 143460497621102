import { ActionReducerMap, MetaReducer, createSelector } from "@ngrx/store";

import * as fromRouterStore from "@ngrx/router-store";

import { environment } from "src/environments/environment";
import * as fromEcommerceProducts from "./ecommerce-products.reducer";
import * as fromProductsMatching from "./products-matching.reducer";
import * as fromProductsComparator from "./products-comparator.reducer";
import * as fromCompetitors from "./competitors.reducer";
import * as fromAuth from "./auth.reducer";
import * as fromReports from "./reports.reducer";
import * as fromDistribution from "./distribution.reducer";
import * as fromCommercialAssistant from "./commercial-assistant.reducer";

export interface BaseState {
  selectedItems?: any[];
  sort?: {
    sortBy?: string;
    order?: string;
  };
  page?: number;
  status?: string;
  error?: string;
  success?: object;
}

// TODO Estrarre lo stato in un file dedicato
export interface ApplicationState {
  auth: fromAuth.AuthState;
  trackedProducts: fromEcommerceProducts.EcommerceProductsState;
  productsMatching: fromProductsMatching.ProductsMatchingState;
  productsComparator: fromProductsComparator.ProductsComparatorState;
  distribution: fromDistribution.DistributionState;
  competitors: fromCompetitors.CompetitorsState;
  reports: fromReports.ReportsState;
  commercialAssistant: fromCommercialAssistant.CommercialAssistantState;
  router: fromRouterStore.RouterReducerState;
}

export const reducers: ActionReducerMap<ApplicationState> = {
  auth: fromAuth.reducer,
  trackedProducts: fromEcommerceProducts.reducer,
  productsMatching: fromProductsMatching.reducer,
  productsComparator: fromProductsComparator.reducer,
  distribution: fromDistribution.reducer,
  competitors: fromCompetitors.reducer,
  reports: fromReports.reducer,
  commercialAssistant: fromCommercialAssistant.reducer,
  router: fromRouterStore.routerReducer,
};

export const metaReducers: MetaReducer<ApplicationState>[] =
  !environment.production ? [] : [];

// TODO Approfondire
export const getApplicationState = (state: ApplicationState) => state;

export const getAuthState = (state: ApplicationState) => state.auth;

export const getEcommerceProductsState = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.trackedProducts
);

export const getProductsMatchingState = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.productsMatching
);

export const getProductsComparatorState = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.productsComparator
);

export const getCompetitorsState = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.competitors
);

export const getReportsState = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.reports
);

export const getDistributionState = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.distribution
);

export const getCommercialAssistantState = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.commercialAssistant
);
