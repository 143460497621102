import { UrlResolver } from "@angular/compiler";
import { Component, Input, OnInit } from "@angular/core";
import { EcommerceType, ProductType } from "@sprintstudioco/ts-types";

@Component({
  selector: "product-specs",
  template: `
    <table class="w-full">
      <tbody>
        <tr class="w-full">
          <td class="text-gray-800 text-xs text-left font-semibold">Nome</td>
          <td class="space-y-1 text-gray-700 text-xs text-center">
            <span class="block">{{ product.name }}</span>
          </td>
        </tr>
        <tr class="w-full">
          <td class="text-gray-800 text-xs text-left font-semibold">
            Ecommerce
          </td>
          <td class="text-gray-700 text-xs text-right">
            {{ extractEcommerce(product.url) }}
          </td>
        </tr>
        <tr *ngIf="false" class="w-full">
          <td class="text-gray-800 text-xs font-semibold">SKU</td>
          <td class="text-gray-700 text-xs text-right">{{ product.sku }}</td>
        </tr>

        <tr>
          <td colspan="2" class="text-center">
            <a
              class="inline-flex space-x-2 text-sm text-indigo-700 font-medium"
              target="_blank"
              [href]="product.url"
              ><span class="flex-1">Vedi prodotto</span>
              <svg
                class="w-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                /></svg
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  `,
  styles: [],
})
export class ProductSpecsComponent {
  @Input() product: ProductType;

  constructor() {}

  extractEcommerce(productUrl: string) {
    try {
      return new URL(productUrl).hostname;
    } catch (e) {
      //console.error(e);
    }
  }
}
