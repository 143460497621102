import { createReducer, on, Action } from "@ngrx/store";
import { DistributionEntities } from "src/app/shared/models/distribution-entities";
import { DistributionStatus } from "src/app/shared/models/status";
import { DistributionActions } from "../actions";

export interface DistributionState {
  entities?: DistributionEntities;
  status?: string;
  error?: any;
}

const initialState: DistributionState = {};

export const distributionReducer = createReducer(
  initialState,
  on(DistributionActions.loadDistributionOverviewByChannels, state => ({
    ...state,
    status: DistributionStatus.LoadDistributionOverviewByChannelsRequested
  })),
  on(
    DistributionActions.loadDistributionOverviewByChannelsSuccess,
    (state, { chartData }) => ({
      ...state,
      entities: {
        ...state.entities,
        distributionOverviewByChannels: chartData
      },
      status: DistributionStatus.LoadDistributionOverviewByChannelsSuccess
    })
  ),
  on(
    DistributionActions.loadDistributionOverviewByChannelsError,
    (state, { error }) => ({
      ...state,
      error,
      status: DistributionStatus.LoadDistributionOverviewByChannelsError
    })
  ),
  on(DistributionActions.exportProductsDistribution, state => ({
    ...state,
    status: DistributionStatus.ExportProductsDistributionRequested
  })),
  on(DistributionActions.exportProductsDistributionSuccess, state => ({
    ...state,
    status: DistributionStatus.ExportProductsDistributionSuccess
  })),
  on(
    DistributionActions.exportProductsDistributionError,
    (state, { error }) => ({
      ...state,
      error,
      status: DistributionStatus.ExportProductsDistributionError
    })
  )
);

export function reducer(state: DistributionState, action: Action) {
  return distributionReducer(state, action);
}

export const getDistributionEntities = (state: DistributionState) =>
  state.entities;
