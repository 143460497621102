import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

/**
 * Custom modules
 */
import { DashboardModule } from "./dashboard/dashboard.module";
import { SharedModule } from "./shared/shared.module";

import { reducers, metaReducers } from "./store/reducers";
import {
  EcommerceProductsEffects,
  AuthEffects,
  ProductComparatorEffects,
} from "./store/effects";
import { environment } from "src/environments/environment";

import { CompetitorModule } from "./competitor/competitor.module";
import { AuthModule } from "./auth/auth.module";
import { CoreModule } from "./core/core.module";
import { MyProductsModule } from "./my-products/my-products.module";
import { CompetitorsEffect } from "./store/effects/competitors.effect";
import { ProductsMatchingModule } from "./products-matching/products-matching.module";
import ProductsMatchingEffects from "./store/effects/products-matching.effect";
import { ReportsModule } from "./reports/reports.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CubejsClientModule } from "@cubejs-client/ngx";
import { CommercialAssistantEffect } from "./store/effects/commercial-assistant.effect";
// import { RootStoreModule } from './root-store/root-store.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    /* Custom modules */
    CoreModule,
    SharedModule,
    AuthModule,
    DashboardModule,
    CompetitorModule,
    MyProductsModule,
    ProductsMatchingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      AuthEffects,
      EcommerceProductsEffects,
      ProductComparatorEffects,
      CompetitorsEffect,
      ProductsMatchingEffects,
      CommercialAssistantEffect,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [{ provide: "googleTagManagerId", useValue: "GTM-5L2RZD6" }],
  bootstrap: [AppComponent],
})
export class AppModule {}
