import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { EcommerceType } from "@sprintstudioco/ts-types";
import { FilterProp } from "src/app/shared/models/filter";
import {
  CompetitorProductEmbedded,
  ProductComparator
} from "src/app/shared/models/product-comparator";

@Component({
  selector: "pricing-comparator-full",
  template: `
    <div class="bg-white rounded shadow-md overflow-auto">
      <table class="w-full">
        <thead class="border-b bg-white">
          <tr>
            <th
              class="px-6 py-3 sticky top-0 bg-white border-b border-gray-200
    text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider border-r"
            >
              <div class="flex flex-col">
                <span>Rivenditori</span>
              </div>
            </th>
            <th
              class="px-4 py-3 sticky top-0 bg-white border-b border-gray-200
    text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider border-r"
            >
              <div class="flex items-center space-x-1">
                <dropdown
                  [labels]="labels"
                  (clickOnLabel)="filterProductsByCountry.emit($event)"
                >
                  <span>Nazione</span>
                </dropdown>
              </div>
            </th>
            <th
              class=" px-6 py-3 sticky top-0 bg-white border-b border-gray-200
    text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider border-r"
            >
              Immagine
            </th>
            <th
              class="px-6 py-3 sticky top-0 bg-white border-b border-gray-200
          text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider border-r"
            >
              Nome
            </th>
            <th
              class="px-6 py-3 sticky top-0 bg-white border-b border-gray-200
              text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider border-r"
            >
              <a
                class="text-center"
                (click)="onSort.emit({ sortByRegularPrice: true })"
              >
                <span>Prezzo</span>
              </a>
            </th>
            <th
              hidden=""
              class="px-6 py-3 sticky top-0 bg-white border-b border-gray-200
              text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider border-r"
            >
              <a
                class="flex items-center space-x-1"
                (click)="onSort.emit({ sortByDiscountedPrice: true })"
              >
                <span>Prezzo scontato</span>
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="selector w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </th>
            <th
              class="px-4 sticky top-0 bg-white border-b border-r border-gray-200
              text-center text-xs leading-4 font-medium text-gray-500 uppercase"
            >
              Differenza MAP
            </th>
            <th
              class="px-4 sticky top-0 bg-white border-b border-gray-200
              text-center text-xs leading-4 font-medium text-gray-500 uppercase"
            >
              Differenza MSRP
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="let competitor of productsComparison?.competitors"
          >
            <tr
              class="py-2 hover:bg-gray-100 border-t border-b cursor-pointer"
              (click)="onRowClick.emit(competitor)"
            >
              <td
                class="h-20 px-5 border-r truncate"
              >
                {{ competitor.ecommerce.name }}
              </td>

              <td
                class="h-20 px-5 border-r"
              >
                {{ competitor.product.country }}
              </td>
              <td class="px-5 py-2 border-r flex items-center justify-center">
                <img
                  class="h-16 self-center"
                  [src]="competitor.product.image"
                />
              </td>
              <td
                class="h-20 px-5 border-r"
              >
                <a
                  class="h-full w-full underline text-gray-800 text-sm font-medium"
                  [href]="competitor.product.url"
                  target="_blank"
                >
                  {{ competitor.product.name }}
                </a>
              </td>
              <td class="p-3 border-r text-center">
                <span class="block">{{ competitor.product.discountedPrice || competitor.product.regularPrice }} €</span>
                <span *ngIf="competitor.product.discount" class="inline-flex space-x-2 text-xs">({{
                    competitor.product.regularPrice
                }}€) - {{ competitor.product.discount * 100 | number: '1.00' }} %</span>
              </td>
              <td
                class="text-center border-r"
                *ngVar="{
                  diffMAPDiscounted:
                    competitor.product.discountedPrice - productsComparison.map,
                  diffMAPRegular:
                    competitor.product.regularPrice - productsComparison.map
                } as diffMAP"
                [ngClass]="{
                  'bg-red-400':
                    (competitor.product.discountedPrice &&
                      diffMAP.diffMAPDiscounted < 0) ||
                    (competitor.product.regularPrice &&
                      diffMAP.diffMAPRegular < 0)
                }"
              >
                {{
                  productsComparison.map
                    ? competitor.product.discountedPrice &&
                      diffMAP.diffMAPDiscounted.toFixed(2) !== "0.00 "
                      ? diffMAP.diffMAPDiscounted.toFixed(2)
                      : diffMAP.diffMAPRegular.toFixed(2) !== "0.00"
                      ? diffMAP.diffMAPRegular.toFixed(2)
                      : "-"
                    : "-"
                }}
              </td>
              <td
                class="text-center"
                *ngVar="
                  competitor.product.regularPrice -
                  productsComparison.msrp as diffMSRP
                "
                [ngClass]="{
                  'bg-red-400': productsComparison.msrp && diffMSRP > 0
                }"
              >
                {{
                  productsComparison.msrp
                    ? diffMSRP.toFixed(2) === "0.00"
                      ? "-"
                      : diffMSRP.toFixed(2)
                    : "-"
                }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <router-outlet></router-outlet>
    </div>
  `
})
export class PricingComparatorFullComponent implements OnInit {
  private labels: FilterProp[];

  @Input() productsComparison: ProductComparator[];
  @Input() competitors: EcommerceType[];
  @Output()
  filterProductsByCountry: EventEmitter<FilterProp> = new EventEmitter();
  @Output()
  onRowClick: EventEmitter<CompetitorProductEmbedded> = new EventEmitter();
  @Output() onSort: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.labels = [
      { id: "Italy", name: "Italia", country: "Italia" },
      { id: "Germania", name: "Germania", country: "Germania" },
      { id: "all", name: "Tutti" }
    ];
  }
}
