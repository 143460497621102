import { createSelector } from "@ngrx/store";
import { ReportsCharts } from "src/app/shared/models/reports-entities";
import * as fromFeature from "../reducers";
import * as fromReports from "../reducers/reports.reducer";

export const selectReportsEntities = createSelector(
  fromFeature.getReportsState,
  fromReports.getReportsEntities
);

export const selectAllProductsSplittedByCountry = createSelector(
  selectReportsEntities,
  (entities: ReportsCharts) => {
    if (!entities) return;

    return entities.allProductsSplittedByCountry;
  }
);

export const selectComparisonCompetitorsProductGroupedByCountry = createSelector(
  selectReportsEntities,
  (entities: ReportsCharts) => {
    if (!entities) return;
    return entities.comparisonCompetitorsGroupedByCountry;
  }
);

export const selectComparisonCompetitorsProductGroupedByChannels = createSelector(
  selectReportsEntities,
  (entities: ReportsCharts) => {
    if (!entities) return;
    return entities.allProductsSplittedByChannels;
  }
);

export const selectGraphFraudolentCompetitorsByMAP = createSelector(
  selectReportsEntities,
  (entities: ReportsCharts) => {
    if (!entities) return;
    return entities.fraudolentCompetitorsByMAP;
  }
);

export const selectGraphFraudolentCompetitorsByMSRP = createSelector(
  selectReportsEntities,
  (entities: ReportsCharts) => {
    if (!entities) return;
    return entities.fraudolentCompetitorsByMSRP;
  }
);

export const selectProductInsights = createSelector(
  selectReportsEntities,
  (entities: ReportsCharts) => {
    if (!entities) return;
    return entities.productInsights;
  }
);

export const selectChartForProductComparison = createSelector(
  selectComparisonCompetitorsProductGroupedByCountry,
  selectComparisonCompetitorsProductGroupedByChannels,
  selectGraphFraudolentCompetitorsByMAP,
  selectGraphFraudolentCompetitorsByMSRP,
  selectProductInsights,
  (
    comparisonCompetitorsGroupedByCountry: any,
    comparisonCompetitorsGroupedByChannels: any,
    fraudolentCompetitorsByMAP: any,
    fraudolentCompetitorsByMSRP: any,
    productInsights
  ) => ({
    comparisonCompetitorsGroupedByCountry,
    comparisonCompetitorsGroupedByChannels,
    fraudolentCompetitorsByMAP,
    fraudolentCompetitorsByMSRP,
    productInsights
  })
);
