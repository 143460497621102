import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { CustomSerializer } from "./store/custom-route-serializer";

const routes: Routes = [
  {
    path: "dashboard/products/overview",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "products/distribution",
    loadChildren: () =>
      import("./distribution/distribution.module").then(
        (m) => m.DistributionModule
      ),
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./reports/reports.module").then((m) => m.ReportsModule),
  },
  {
    path: "commercial-assistant",
    loadChildren: () =>
      import("./commercial-assistant/commercial-assistant.module").then(
        (m) => m.CommercialAssistantModule
      ),
  },
  {
    path: "",
    redirectTo: "/products/overview",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
