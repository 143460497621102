import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthService } from "../services/auth/auth.service";
import { Router } from "@angular/router";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  // TODO Con l'implementazione di NgRx, valutare l'utilizzo di effects, oppure spostare questa logica in un reducer lasciando qui solo l'action richiesta
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          // TODO Richiamare l'action di logout
          this.authService.logout();
          // TODO trovare un metodo ottimale per ricaricare la pagina
          this.router.navigate(["/login"]);
        }

        return throwError(err);
      })
    );
  }
}
