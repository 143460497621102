import { createReducer, on, Action } from "@ngrx/store";
import { EcommerceType } from "@sprintstudioco/ts-types";
import {
  ProductsMatching,
  ProductsMatchingNew,
} from "src/app/shared/models/products-matching";
import { ProductsMatchingStatus } from "src/app/shared/models/status";
import { CompetitorsActions, ProductsMatchingActions } from "../actions";

export interface ProductsMatchingStateOld {
  entities: ProductsMatching;
  status?: string;
  error?: string;
}

export interface ProductsMatchingStateNew {
  entities?: ProductsMatchingNew;
  nextCompetitorToMatch?: EcommerceType;
  status?: string;
  error?: string;
}

export type ProductsMatchingState = ProductsMatchingStateNew;

export const initialState: ProductsMatchingState = {
  entities: null,
};

export const productsMatchingReducers = createReducer(
  initialState,
  on(CompetitorsActions.loadCompetitorsSuccess, (state, { entities }) => ({
    ...state,
    entities: {
      ...state.entities,
      ecommerce: {
        ...entities.ecommerce,
      },
    },
  })),
  on(
    ProductsMatchingActions.loadNextCompetitorToMatch,
    (state, { nextCompetitorToMatch }) => {
      /**
       * TODO
       * Non mi piace molto questo accrocchio perché permette l'utilizzo del seguente reducer in due scenari
       * 1. Quando il backend ritorna dei prodotti simili
       * 2. Quando il backend ritorna un array vuoto di prodotti simili
       * **/
      if (!nextCompetitorToMatch) {
        const competitorsIds = Object.keys(state.entities.ecommerce);
        let nextCompetitorId = competitorsIds[0];

        if (state.nextCompetitorToMatch) {
          const currentIndexCompetitor = competitorsIds.indexOf(
            state.nextCompetitorToMatch["_id"]
          );

          nextCompetitorId = competitorsIds[currentIndexCompetitor + 1];
        }

        return {
          ...state,
          status: ProductsMatchingStatus.LoadingProductsMatching,
          nextCompetitorToMatch: state.entities.ecommerce[nextCompetitorId],
        };
      } else {
        return {
          ...state,
          status: ProductsMatchingStatus.LoadingProductsMatching,
          nextCompetitorToMatch,
        };
      }
    }
  ),
  on(
    ProductsMatchingActions.loadNextCompetitorToMatchSuccess,
    (state, { product }) => ({
      ...state,
      status: ProductsMatchingStatus.LoadingProductsMatching,
    })
  ),
  on(ProductsMatchingActions.loadSimilarProducts, (state) => ({
    ...state,
    status: ProductsMatchingStatus.LoadSimilarProductsRequested,
  })),
  on(
    ProductsMatchingActions.loadSimilarProductsSuccess,
    (state, { similarProducts }) => ({
      ...state,
      status: ProductsMatchingStatus.LoadSimilarProductsSuccess,
      entities: {
        ...state.entities,
        products: similarProducts,
      },
    })
  ),
  on(ProductsMatchingActions.loadSimilarProductsError, (state, { error }) => ({
    ...state,
    status: ProductsMatchingStatus.LoadSimilarProductsError,
    error,
  })),
  on(ProductsMatchingActions.loadProductsForMatching, (state) => ({
    ...state,
    status: ProductsMatchingStatus.LoadingProductsMatching,
  })),
  on(
    ProductsMatchingActions.loadProductsForMatchingSuccess,
    (state, { entities }) => ({
      ...state,
      entities,
      status: ProductsMatchingStatus.LoadedProductsMatchingSuccess,
    })
  ),
  on(
    ProductsMatchingActions.loadProductsForMatchingError,
    (state, { error }) => ({
      ...state,
      status: ProductsMatchingStatus.LoadedProductsMatchingError,
      error,
    })
  ),
  on(ProductsMatchingActions.matchProducts, (state) => ({
    ...state,
    status: ProductsMatchingStatus.MatchRequested,
  })),
  on(
    ProductsMatchingActions.matchProductSuccess,
    (state, { productId, similarProductID }) => {
      const currentCompetitorIndex = Object.keys(
        state.entities.ecommerce
      ).indexOf(state.nextCompetitorToMatch["_id"]);
      const nextCompetitorId = Object.keys(state.entities.ecommerce)[
        currentCompetitorIndex + 1
      ];

      return {
        ...state,
        nextCompetitorToMatch: state.entities.ecommerce[nextCompetitorId],
        status: ProductsMatchingStatus.MatchSuccess,
      };
    }
  ),
  on(ProductsMatchingActions.matchProductError, (state, { error }) => ({
    ...state,
    error,
    status: ProductsMatchingStatus.MatchError,
  }))
);

export function reducer(state: ProductsMatchingState, action: Action) {
  return productsMatchingReducers(state, action);
}

export const getProductsMatchingEntities = (state: ProductsMatchingState) =>
  state.entities;
export const getProductsMatchingStatus = (state: ProductsMatchingState) =>
  state.status;
export const getProductsMatchingError = (state: ProductsMatchingState) =>
  state.error;
