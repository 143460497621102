import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { createEffect, Actions, ofType } from "@ngrx/effects";

import { ProductService } from "src/app/core/services/product/product.service";
import { ProductComparatorActions, ProductsMatchingActions } from "../actions";
import ProductsComparatorSchema from "../schemas/products-comparator.schema";
import { normalize } from "normalizr";
import { CSVExporterService } from "src/app/core/services/csv-exporter/csvexporter.service";
import { select, Store } from "@ngrx/store";
import { ApplicationState } from "../reducers";
import { ProductsComparatorSelectors } from "../selectors";
import { EcommerceType } from "@sprintstudioco/ts-types";
import { EcommerceSchema } from "../schemas";

@Injectable()
export class ProductComparatorEffects {
  constructor(
    private store: Store<ApplicationState>,
    private actions$: Actions,
    private productService: ProductService,
    private csvExporterService: CSVExporterService
  ) { }

  loadProductComparison$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductComparatorActions.loadProductComparator),
      mergeMap(() =>
        this.productService.getProductsComparison().pipe(
          map(comparison => {
            const schema = new ProductsComparatorSchema();
            return ProductComparatorActions.loadProductComparatorSuccess({
              productsComparator: normalize(comparison, [schema]).entities
            });
          }),
          catchError(error =>
            of(ProductComparatorActions.loadProductComparatorError({ error }))
          )
        )
      )
    )
  );

  updatedProductComparator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductComparatorActions.updateCompetitorProduct),
      mergeMap(({ productId, data }) =>
        this.productService.updatedCompetitorProduct(productId, data).pipe(
          map(response =>
            ProductComparatorActions.updateCompetitorProductSuccess({
              productId,
              data
            })
          ),
          catchError(err =>
            of(
              ProductComparatorActions.updateCompetitorProductError({
                productId,
                error: err
              })
            )
          )
        )
      )
    )
  );

  wrongProductIntoComparison$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductComparatorActions.wrongProductIntoComparison),
      mergeMap(({ comparisonId, productId }) =>
        this.productService
          .wrongProductIntoComparison(comparisonId, productId)
          .pipe(
            map(response =>
              ProductComparatorActions.wrongProductIntoComparisonSuccess({
                comparisonId,
                productId,
                response
              })
            ),
            catchError(err =>
              of(
                ProductComparatorActions.wrongProductIntoComparisonError({
                  comparisonId,
                  productId,
                  error: err
                })
              )
            )
          )
      )
    )
  );

  filterCompetitorsProductsOfComparison$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductComparatorActions.filterCompetitorsProductsOfComparison),
      mergeMap(({ comparisonId, filter }) =>
        this.productService.getProductComparisonById(comparisonId, filter).pipe(
          map(comparison => {
            const schema = new ProductsComparatorSchema();
            const normalizedComparison = normalize([comparison], [schema])
              .entities;
            return ProductComparatorActions.filterCompetitorsProductsOfComparisonSuccess(
              {
                comparison: Object.values(normalizedComparison.comparison)[0],
                filter
              }
            );
          }),
          catchError(err =>
            of(
              ProductComparatorActions.filterCompetitorsProductsOfComparisonError(
                { error: err }
              )
            )
          )
        )
      )
    )
  );

  exportComparisonTOCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductComparatorActions.exportComparisonTOCSV),
      map(({ comparisonId, competitors, fileName }) => ({
        comparisonId,
        competitors: competitors.map(competitor => ({
          ecommerceName: competitor.ecommerce.name,
          ecommerceUrl: competitor.ecommerce.url,
          ...competitor.product
        })),
        fileName
      })),
      mergeMap(({ comparisonId, competitors, fileName }) =>
        this.csvExporterService
          .exportCSV(competitors, { filename: fileName })
          .pipe(
            map(response =>
              ProductComparatorActions.exportComparisonTOCSVSuccess({
                comparisonId
              })
            ),
            catchError(err =>
              of(
                ProductComparatorActions.exportComparisonTOCSVError({
                  error: err
                })
              )
            )
          )
      )
    )
  );

  getMissingEcommercesFromComparison$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductComparatorActions.getMissingEcommercesFromComparison),
      mergeMap(({ comparisonId }) =>
        this.productService.getMissingEcommercesIntoComparison(comparisonId).pipe(
          map((ecommerces: EcommerceType[]) => {
            const ecommerceSchema = new EcommerceSchema();
            const ecommercesEntities = normalize<EcommerceType>(ecommerces, [ecommerceSchema]).entities;
            return ProductComparatorActions.getMissingEcommercesFromComparisonSuccess({ comparisonId, entities: ecommercesEntities })
          }
          ),
          catchError(err =>
            of(
              ProductComparatorActions.getMissingEcommercesFromComparisonError({ error: err })
            )
          )
        )
      )
    )
  );

  getViolatingEcommerces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductComparatorActions.getViolatingEcommerces),
      mergeMap(() =>
        this.productService.getEcommercesViolatingPolicy().pipe(
          map(entities => ProductComparatorActions.getViolatingEcommercesSuccess({ entities: entities })),
          catchError(err => of(ProductComparatorActions.getViolatingEcommercesError({ error: err })))
        )
      )
    )
  );
}
