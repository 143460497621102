import { createReducer, on, Action } from "@ngrx/store";
import { EcommerceProductsActions } from "../actions";
import { ProductsEntities } from "src/app/shared/models/product-entity";
import { ProductType } from "@sprintstudioco/ts-types";
import { EcommerceProductsStatus } from "src/app/shared/models/status";
import { Alert } from "src/app/shared/models/alert";

// declare type Status = "Loading" | "Loaded" | "Error";

// TODO Il campo status dovrebbe essere di tipo Status così da vincolare i valori che può assumere. Aggiungere questo tipo a @sprintstudioco/ts-types
// TODO Verificare se aggiungere un campo error nel caso in cui ci sono errori da mostrare nella UI
export interface EcommerceProductsState {
  entities: ProductsEntities;
  page: number;
  selectedProducts: { [id: string]: ProductType };
  latestProductCreated?: ProductType;
  selectedAlert?: Alert;
  status?: EcommerceProductsStatus | string;
  success?: object;
  error?: object;
}

export const trackedProductsInitialState: EcommerceProductsState = {
  entities: {
    ecommerce: {},
    category: {},
    product: {}
  },
  page: 1,
  selectedProducts: {},
  status: "Loaded"
};

export const trackedProductsReducers = createReducer(
  trackedProductsInitialState,
  on(EcommerceProductsActions.loadEcommerceProducts, state => ({
    ...state,
    status: "Loading"
  })),
  on(
    EcommerceProductsActions.loadEcommerceProductsSuccess,
    (state, { entities }) => ({
      ...state,
      entities,
      status: "Loaded"
    })
  ),
  on(
    EcommerceProductsActions.loadEcommerceProductsError,
    (state, { error }) => ({
      ...state,
      status: "Error",
      error
    })
  ),
  on(EcommerceProductsActions.checkProduct, (state, { id }) => ({
    ...state,
    selectedProducts: {
      ...state.selectedProducts,
      [id]: state.entities.product[id]
    }
  })),
  on(EcommerceProductsActions.uncheckProduct, (state, { id }) => {
    let selectedProducts = { ...state.selectedProducts };
    delete selectedProducts[id];
    return {
      ...state,
      selectedProducts
    };
  }),
  on(EcommerceProductsActions.checkAllProducts, state => ({
    ...state,
    selectedProducts: state.entities.product
  })),
  on(EcommerceProductsActions.uncheckAllProducts, state => ({
    ...state,
    selectedProducts: {}
  })),
  on(EcommerceProductsActions.trackProducts, state => ({
    ...state,
    status: "Loading"
  })),
  on(
    EcommerceProductsActions.trackProductsSuccess,
    (state, { products, response }) => {
      let selectedProducts = {};

      products.forEach(key => {
        selectedProducts[key] = {
          ...state.selectedProducts[key],
          status: "Tracked",
          is_tracked: true
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          product: {
            ...state.entities.product,
            ...selectedProducts
          }
        },
        selectedProducts: {},
        status: EcommerceProductsStatus.EcommerceProductsSuccess,
        success: response
      };
    }
  ),
  on(EcommerceProductsActions.trackProductsError, (state, { error }) => ({
    ...state,
    status: EcommerceProductsStatus.EcommerceProductsError,
    error
  })),
  on(EcommerceProductsActions.untrackProducts, state => ({
    ...state,
    status: EcommerceProductsStatus.UntrackedProductsLoading
  })),
  on(EcommerceProductsActions.untrackProductsSuccess, (state, { response }) => {
    let selectedProducts = {};

    Object.keys(state.selectedProducts).forEach(key => {
      selectedProducts[key] = {
        ...state.selectedProducts[key],
        status: "Untracked",
        is_tracked: false
      };
    });

    return {
      ...state,
      entities: {
        ...state.entities,
        product: {
          ...state.entities.product,
          ...selectedProducts
        }
      },
      selectedProducts: {},
      status: EcommerceProductsStatus.EcommerceProductsSuccess,
      success: response
    };
  }),
  on(EcommerceProductsActions.untrackProductsError, state => ({
    ...state,
    selectedProducts: {},
    status: EcommerceProductsStatus.EcommerceProductsError
  })),
  on(EcommerceProductsActions.previousPageSuccess, (state, { entities }) => ({
    ...state,
    entities,
    page: state.page > 1 ? state.page - 1 : 1,
    status: "Loaded"
  })),
  on(EcommerceProductsActions.nextPageSuccess, (state, { entities }) => ({
    ...state,
    entities,
    page: state.page + 1,
    status: "Loaded"
  })),
  /**
   * Reducers for Create a product
   */
  on(EcommerceProductsActions.createProduct, (state, { product }) => ({
    ...state,
    status: EcommerceProductsStatus.CreateProductRequested,
    latestProductCreated: undefined
  })),
  on(
    EcommerceProductsActions.createProductSuccess,
    (state, { product, response }) => ({
      ...state,
      status: EcommerceProductsStatus.CreateProductSuccess,
      entities: {
        ...state.entities,
        product: {
          ...state.entities.product,
          [response["_id"]]: response
        }
      },
      latestProductCreated: product
    })
  ),
  on(EcommerceProductsActions.createProductError, (state, { error }) => ({
    ...state,
    status: EcommerceProductsStatus.CreateProductError
  })),
  /**
   * Reducers for Update a products
   */
  on(EcommerceProductsActions.updateProduct, (state, { product }) => ({
    ...state,
    status: EcommerceProductsStatus.UpdateProductRequested
  })),
  on(EcommerceProductsActions.updateProductSuccess, (state, { product }) => ({
    ...state,
    status: EcommerceProductsStatus.UpdateProductSuccess,
    entities: {
      ...state.entities,
      product: {
        ...state.entities.product,
        [product["_id"]]: {
          ...state.entities.product[product["_id"]],
          ...product
        }
      }
    }
  })),
  on(EcommerceProductsActions.updateProductError, (state, { error }) => ({
    ...state,
    status: EcommerceProductsStatus.UpdateProductError,
    error
  })),
  /**
   * Reducers for Save MAP and MSRP values
   */
  on(EcommerceProductsActions.saveMAPandMSRP, (state, { data }) => ({
    ...state,
    status: EcommerceProductsStatus.SaveMAPandMSRPRequested
  })),
  on(
    EcommerceProductsActions.saveMAPandMSRPSuccess,
    (state, { data, response }) => ({
      ...state,
      entities: {
        ...state.entities
      },
      status: EcommerceProductsStatus.SaveMAPandMSRPSuccess
    })
  ),
  on(EcommerceProductsActions.saveMAPandMSRPError, (state, { error }) => ({
    ...state,
    status: EcommerceProductsStatus.SaveMAPandMSRPError,
    error
  })),
  on(EcommerceProductsActions.loadAlertByProductId, state => ({
    ...state,
    status: EcommerceProductsStatus.LoadAlertByProductIdRequest
  })),
  on(
    EcommerceProductsActions.loadAlertByProductIdSuccess,
    (state, { alert }) => ({
      ...state,
      status: EcommerceProductsStatus.LoadAlertByProductIdSuccess,
      selectedAlert: alert
    })
  ),
  on(
    EcommerceProductsActions.loadAlertByProductIdError,
    (state, { error }) => ({
      ...state,
      status: EcommerceProductsStatus.LoadAlertByProductIdError,
      error
    })
  ),
  on(
    EcommerceProductsActions.updateAlertByProductId,
    (state, { productId, data }) => ({
      ...state,
      status: EcommerceProductsStatus.UpdateAlertByProductIdRequested
    })
  ),
  on(
    EcommerceProductsActions.updateAlertByProductIdSuccess,
    (state, { productId, alert }) => ({
      ...state,
      status: EcommerceProductsStatus.UpdateAlertByProductIdSuccess
    })
  ),
  on(
    EcommerceProductsActions.updateAlertByProductIdError,
    (state, { error }) => ({
      ...state,
      status: EcommerceProductsStatus.UpdateAlertByProductIdError,
      error
    })
  )
);

export function reducer(state: EcommerceProductsState, action: Action) {
  return trackedProductsReducers(state, action);
}

export const getEcommerceProductsEntites = (state: EcommerceProductsState) =>
  state.entities;
export const getEcommerceProductsStatus = (state: EcommerceProductsState) =>
  state.status;
export const getEcommerceProductsError = (state: EcommerceProductsState) =>
  state.error;
