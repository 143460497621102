import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "alert",
  template: `
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div
      class="rounded-md bg-green-50 p-4"
      [ngClass]="{
        'bg-red-200 text-red-600': type === 'danger',
        'bg-yellow-50': type === 'warning',
        'bg-green-50': type === 'success'
      }"
    >
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: solid/check-circle -->
          <svg
            *ngIf="type === 'success'"
            class="h-5 w-5 text-green-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>

          <svg
            *ngIf="type === 'danger'"
            class="h-5 w-5 text-red-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div class="ml-3">
          <h3
            class="text-sm font-medium"
            [ngClass]="{
              'text-red-600': type === 'danger',
              'text-yellow-600': type === 'warning',
              'text-green-600': type === 'success'
            }"
          >
            {{ title || text }}
          </h3>
          <div class="mt-2 text-sm text-green-700">
            <p>
              {{ message }}
            </p>
          </div>
          <div *ngIf="false" class="mt-4">
            <div class="-mx-2 -my-1.5 flex">
              <button
                type="button"
                class="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              >
                View status
              </button>
              <button
                type="button"
                class="ml-3 bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="false"
      class=" w-2/4 h-12 px-4 py-2 flex items-center text-sm rounded-md border space-x-1"
      [ngClass]="{
        'bg-red-200 text-red-600': type === 'danger',
        'bg-yellow-200 text-yellow-600': type === 'warning',
        'bg-green-100 text-green-600': type === 'success'
      }"
    >
      <svg
        *ngIf="type === 'success'"
        class="w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <svg
        *ngIf="type === 'warning'"
        class="w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        ></path>
      </svg>

      <svg
        *ngIf="type === 'danger'"
        class="w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <span>{{ text }}</span>
    </div>
  `,
  styles: [],
})
export class AlertComponent implements OnInit {
  @Input() type: string;
  @Input() title: string;
  @Input() text: string;

  constructor() {}

  ngOnInit() {}
}
