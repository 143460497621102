import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgSelectModule } from '@ng-select/ng-select';

/**
 * Custom components and modules
 */
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { PricingComparatorComponent } from './presentationals/pricing-comparator/pricing-comparator.component';
import { SharedModule } from '../shared/shared.module';
import { ProductPricesComponent } from './containers/product-prices/product-prices.component';
import { ProductsOverviewComponent } from './containers/products-overview/products-overview.component';
import { ProductPriceStatsComponent } from './presentationals/product-price-stats/product-price-stats.component';
import { PriceHistoryComponent } from './presentationals/price-history/price-history.component';
import { PricesAnomaliesComponent } from './containers/prices-anomalies/prices-anomalies.component';
import { PricingComparatorFullComponent } from './presentationals/pricing-comparator-full/pricing-comparator-full.component';
import { ReportsModule } from '../reports/reports.module';

@NgModule({
  declarations: [
    DashboardComponent,
    PricingComparatorComponent,
    PricingComparatorFullComponent,
    ProductPricesComponent,
    ProductsOverviewComponent,
    ProductPriceStatsComponent,
    PriceHistoryComponent,
    PricesAnomaliesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgxImageZoomModule,
    // Custom modules
    SharedModule,
    ReportsModule,
    DashboardRoutingModule
  ],
  entryComponents: [],
  exports: [DashboardComponent]
})
export class DashboardModule { }
