import { query } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { CubejsClient } from "@cubejs-client/ngx";
import { LegendComponent } from "@swimlane/ngx-charts";
import { Observable, Subject } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

@Component({
  selector: "pie-chart",
  template: `
    <ngx-charts-pie-chart
      [view]="view"
      [scheme]="colorScheme"
      [results]="chartData$ | async"
      [gradient]="gradient"
      [legend]="showLegend"
      [legendPosition]="legendPosition"
      [labels]="showLabels"
      [doughnut]="isDoughnut"
    >
    </ngx-charts-pie-chart>
  `,
  styles: []
})
export class PieChartComponent implements OnInit {
  private chartData$: Observable<any>;

  @Input() isDoughnut: boolean;
  @Input() showLabel: boolean;
  @Input() showLegend: boolean;
  @Input() legendPosition: string;
  @Input() gradient: boolean;
  @Input() colorScheme: any;
  @Input() view: any[];

  @Input() querySubject$: Subject<any>;
  @Input() results: [];

  constructor(private cubejs: CubejsClient) {}

  ngOnInit(): void {
    this.isDoughnut = true;
    this.showLabel = true;
    this.showLegend = true;
    this.legendPosition = "below";
    this.gradient = true;
    this.view = [300, 400];
    this.colorScheme = {
      domain: ["#5AA454", "#E44D25", "#CFC0BB", "#7aa3e5", "#a8385d", "#aae3f5"]
    };

    this.chartData$ = this.cubejs.watch(this.querySubject$).pipe(
      map(response => response.series()),
      map(([first, ...rest]) => {
        return first.series.map(serie => ({
          value: serie.value,
          name: serie.x,
          category: serie.category
        }));
      })
    );
  }
}
