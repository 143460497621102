import { Component } from '@angular/core';

@Component({
  selector: "dashboard",
  template: `
    <div class="flex flex-row bg-gray-100">
      <sidebar></sidebar>
      <div class="h-screen p-4 flex flex-grow flex-col pt-4 space-y-8 overflow-y-auto">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
})
export class DashboardComponent {

  constructor() { }

}
