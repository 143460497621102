import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { mergeMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import { AuthActions } from "../actions/auth.action";
import { AuthService } from "src/app/core/services/auth/auth.service";

@Injectable()
export class AuthEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private authService: AuthService
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      mergeMap((credential) =>
        this.authService.login(credential).pipe(
          map((data: { token: string }) => {
            window.location.href = `${
              window.location.origin
            }${"/products/overview"}`;
            return AuthActions.loginSuccess(data);
          }),
          catchError((err) => of(AuthActions.loginError({ error: err })))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      mergeMap(() =>
        this.authService.logout().pipe(
          map(() => {
            this.router.navigate(["/login"]);
            return AuthActions.logoutSuccess();
          })
        )
      )
    )
  );

  loadAuthToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadAuthToken),
      mergeMap(() =>
        this.authService.getToken().pipe(
          map(
            (token) => AuthActions.loadAuthTokenSuccess({ token }),
            catchError((error) => of(AuthActions.loadAuthTokenError({ error })))
          )
        )
      )
    )
  );

  loadUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadUserProfile),
      mergeMap(() =>
        this.authService.me().pipe(
          map((user) => AuthActions.loadUserProfileSuccess({ user })),
          catchError((err) =>
            of(AuthActions.loadUserProfileError({ error: err }))
          )
        )
      )
    )
  );
}
