import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/services/auth/auth.service";

@Component({
  selector: "register-form",
  template: `
    <div
      class="h-screen w-full flex flex-col justify-center items-center bg-gray-300"
    >
      <div class="w-5/6 max-w-md py-8 px-8 space-y-8 bg-white shadow rounded">
        <form
          #registerForm="ngForm"
          class="space-y-4"
          (ngSubmit)="register(registerForm.value)"
        >
          <div class="flex flex-col space-y-1">
            <label name="firstName" class="text-gray-700 font-bold text-sm"
              >First name</label
            >
            <input
              type="text"
              name="firstName"
              ngModel
              class="bg-white border rounded px-2 py-2 focus:border-gray-600 focus:outline-none"
              required=""
            />
          </div>
          <div class="flex flex-col space-y-1">
            <label class="text-gray-700 font-bold text-sm">Email</label>
            <input
              type="email"
              name="email"
              ngModel
              class="bg-white border rounded px-2 py-2 focus:border-gray-600 focus:outline-none"
              required=""
            />
          </div>
          <div class="flex flex-col space-y-1">
            <label class="text-gray-700 font-bold text-sm">Password</label>
            <input
              type="password"
              name="password"
              ngModel
              class="bg-white border rounded px-2 py-2 focus:border-gray-600 focus:outline-none"
              required=""
            />
          </div>
          <div class="inline-block text-gray-800 text-sm">
            <input
              type="checkbox"
              class="bg-white border rounded px-2 py-2 focus:border-gray-600 focus:outline-none"
            />
            Accept our <a class="underline" href="">privacy policy</a>
          </div>
          <button
            type="submit"
            class="w-full bg-gray-600 text-white text-sm rounded px-1 py-2"
          >
            Signup
          </button>
        </form>
        <div class="flex flex-col space-y-1">
          <h3 class="text-gray-800 text-sm">Hai un account?</h3>
          <button
            class="w-full bg-gray-600 text-white text-sm rounded px-1 py-2"
            [routerLink]="[{ outlets: { 'login-form': ['login'] } }]"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  `,
})
export class RegisterFormComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {}

  register(value: { email: string; password: string }) {
    //Quando verrà integrato NgRx richiamare l'action specifica
    this.authService.signup(value).subscribe((user) => {
      alert("Registered");
    }, err => {
      alert("Error");
    });
  }
}
