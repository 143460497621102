import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "line-chart",
  template: `
    <chart [querySubject$]="querySubject$" [result$]="chartData$">
      <ngx-charts-line-chart
        [curve]="curve"
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="chartData$ | async"
      >
      </ngx-charts-line-chart>
    </chart>
  `,
  styles: [],
})
export class LineChartComponent implements OnInit {
  private chartData$: Subject<any>;

  @Input() curve: any;
  @Input() legend: boolean;
  @Input() showLabels: boolean;
  @Input() animations: boolean;
  @Input() xAxis: boolean;
  @Input() yAxis: boolean;
  @Input() showYAxisLabel: boolean;
  @Input() showXAxisLabel: boolean;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() timeline: boolean;

  @Input() view: any[];
  @Input() results: any[];
  @Input() querySubject$: Subject<any>;
  @Input() result$: Subject<any>;

  @Input() colorScheme;

  constructor() {}

  ngOnInit(): void {
    this.chartData$ = new Subject();
    this.legend = true;
    this.showLabels = true;
    this.animations = true;
    this.xAxis = true;
    this.yAxis = true;
    this.showYAxisLabel = true;
    this.showXAxisLabel = true;
    this.timeline = true;
    this.view = [1200, 400];
    this.colorScheme = {
      domain: [
        "#5AA454",
        "#E44D25",
        "#CFC0BB",
        "#7aa3e5",
        "#a8385d",
        "#aae3f5",
      ],
    };
  }
}
