import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ProductComparator } from 'src/app/shared/models/product-comparator';
import { EcommerceType } from '@sprintstudioco/ts-types';
import { BasicTableColumn } from 'src/app/shared/models/basic-tabel';

@Component({
  selector: "pricing-comparator",
  template: `
    <div class="bg-white rounded shadow-md overflow-auto">
      <basic-table [columnLabels]="labels" [items]="productsComparison">
        <ng-template row-tmp let-item="item" let-index="index">
          <tr
            class="py-2 hover:bg-gray-300 cursor-pointer"
            gtmPush
            [gtmEventReport]="'comparison'"
            [gtmEventName]="item.product.name"
            (click)="navigateToComparison(item)"
          >
            <td class="px-4 py-2">
              {{ item.product.name }}
            </td>
            <td class="pl-4 py-3l text-left border-l">
              {{ item.lowest_price }}€
            </td>
            <td class="p-3 text-left">
              {{ item.highest_price }}€
            </td>
            <td class="p-3 text-left">
              <span>{{ item.map ? item.map + "€" : "-" }} </span>
              <span>{{ item.map ? "(" + item.stats.resellersViolatingMAP + ")" : '-' }}</span>
            </td>
            <td class="p-3 text-left" >
              <span>{{ item.msrp ? item.msrp + "€" : "-" }}</span>
              <span>{{ item.msrp ? "(" + item.stats.resellersViolatingMSRP + ")" : "" }}</span>
              </td>
            <td class="p-3 text-left" >
              {{ item.competitors.length }}
            </td>
          </tr>
        </ng-template>
      </basic-table>
    </div>
  `
})
export class PricingComparatorComponent implements OnInit {
  private labels: BasicTableColumn[];

  @Input() productsComparison: ProductComparator[];
  @Input() competitors: EcommerceType[];

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.labels = [{
      id: 'product',
      name: ''
    }, {
      id: 'minimumPrice',
      name: 'Prezzo minimo'
    }, {
      id: 'maxPrice',
      name: 'Prezzo più alto'
    }, {
      id: 'MAP',
      name: 'MAP (Violazioni)'
    }, {
      id: 'MSRP',
      name: 'MSRP (Violazioni)'
    }, {
      id: 'sellers',
      name: 'Rivenditori'
    }];
  }

  navigateToComparison(comparison: any) {
    this.router.navigate([
      '/product/',
      comparison['_id'],
      comparison.product.name.toLowerCase().replace(' ', '-')
    ]);
  }
}
