import { CommonModule } from '@angular/common';
import { ProductsMatchingComponent } from './containers/products-matching/products-matching.component';
import { ProductsMatchingRoutingModule } from './products-matching-routing.module';
import { MyProductComponent } from './presentationals/my-product/my-product.component';
import { ProductsMatchingFooterComponent } from './presentationals/products-matching-footer/products-matching-footer.component';
import { SharedModule } from '../shared/shared.module';
import { ProductSpecsComponent } from './presentationals/product-specs/product-specs.component';

export const IMPORTS = [
    CommonModule,
    SharedModule,
    ProductsMatchingRoutingModule
];

export const DECLARATIONS = [
    ProductsMatchingComponent,
    MyProductComponent,
    ProductsMatchingFooterComponent,
    ProductSpecsComponent
];

export const EXPORTS = [
    ProductsMatchingComponent
];