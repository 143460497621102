import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "products-matching-footer",
  template: `
    <div class="flex justify-between md:justify-center space-x-3">
      <button
        class="px-4 py-2 flex-1 bg-gray-200 rounded text-gray-700 text-sm font-semibold"
        (click)="match.emit(undefined)"
      >
        Sono uguali
      </button>
    </div>
  `,
  styles: [],
})
export class ProductsMatchingFooterComponent {
  @Output() match: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
