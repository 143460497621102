import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ProductType } from "@sprintstudioco/ts-types";

@Component({
  selector: "my-product-adder",
  template: `
    <div class="grid grid-cols-1 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Specifiche Prodotto
          </h3>
          <p class="mt-1 text-sm text-gray-600">
            Compila la form qui sotto per aggiungere un prodotto al tuo catalogo
            così da poterlo tracciare e confrontarlo con i rivenditori collegati
            al tuo account.
          </p>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form
          #formProduct="ngForm"
          action="#"
          method="POST"
          (ngSubmit)="save.emit(formProduct.value)"
        >
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <ng-content></ng-content>
              <div class="grid grid-cols-4 gap-6">
                <div class="col-span-2 sm:col-span-2">
                  <label
                    for="name"
                    class="block text-sm font-medium text-gray-700"
                    >Name</label
                  >
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autocomplete="name"
                    class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    [ngModel]="product?.name"
                    required=""
                  />
                </div>
                <div class="col-span-2 sm:col-span-2">
                  <label
                    for="url"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Url
                  </label>
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="url"
                      id="url"
                      class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded sm:text-sm border border-gray-300"
                      placeholder="www.example.com"
                      required=""
                      [ngModel]="product?.url"
                    />
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-4 gap-6">
                <div class="col-span-2 sm:col-span-2">
                  <label
                    for="brand"
                    class="block text-sm font-medium text-gray-700"
                    >Prezzo regolare</label
                  >
                  <input
                    type="number"
                    name="regularPrice"
                    id="regularPrice"
                    class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required=""
                    [ngModel]="product?.regularPrice"
                  />
                </div>
                <div class="col-span-2 sm:col-span-2">
                  <label
                    for="EAN"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Prezzo scontato
                  </label>
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="number"
                      name="discountedPrice"
                      id="discountedPrice"
                      class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded sm:text-sm border border-gray-300"
                      required=""
                      [ngModel]="product?.discountedPrice"
                    />
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-4 gap-6">
                <div class="col-span-2 sm:col-span-2">
                  <label
                    for="brand"
                    class="block text-sm font-medium text-gray-700"
                    >Brand</label
                  >
                  <input
                    type="text"
                    name="brand"
                    id="brand"
                    class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required=""
                    [ngModel]="product?.brand"
                  />
                </div>
                <div class="col-span-2 sm:col-span-2">
                  <label
                    for="EAN"
                    class="block text-sm font-medium text-gray-700"
                  >
                    EAN
                  </label>
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="EAN"
                      id="EAN"
                      class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded sm:text-sm border border-gray-300"
                      required=""
                      [ngModel]="product?.ean"
                    />
                  </div>
                </div>
              </div>

              <div>
                <label
                  for="description"
                  class="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <div class="mt-1">
                  <textarea
                    id="description"
                    name="description"
                    rows="3"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Descrizione del prodotto"
                    required=""
                    [ngModel]="product?.description"
                  ></textarea>
                </div>
                <p class="mt-2 text-sm text-gray-500"></p>
              </div>
              <div class="col-span-2 sm:col-span-2">
                <label
                  for="street_address"
                  class="block text-sm font-medium text-gray-700"
                  >Url immagine</label
                >
                <input
                  type="text"
                  name="image"
                  id="image"
                  autocomplete="image"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  [ngModel]="product?.image"
                  required=""
                />
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  `,
  styles: []
})
export class MyProductAdderComponent {
  @Input() product: ProductType;
  @Output() save: EventEmitter<ProductType> = new EventEmitter();
  @Output() saveMAPandMSRP: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
