import { schema } from "normalizr";

export class ProductSchema extends schema.Entity {
  constructor(category?: schema.Entity, ecommerce?: schema.Entity) {
    super(
      "product",
      {
        ecommerce,
        category
      },
      { idAttribute: "_id" }
    );
  }
}
