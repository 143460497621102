import { Component, Input, OnInit } from "@angular/core";
import BasicChartComponent from "../../models/basic-chart-component";

@Component({
  selector: "vertical-bar-chart",
  template: `
    <ngx-charts-bar-vertical
      [view]="view"
      [scheme]="colorScheme"
      [results]="data"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [showGridLines]="showGridLines"
      (select)="onSelect($event)"
    >
    </ngx-charts-bar-vertical>
  `,
  styles: []
})
export class VericalBarChartComponent extends BasicChartComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
