import { createAction, props } from "@ngrx/store";
import { EcommerceType, ProductType } from "@sprintstudioco/ts-types";
import { FilterProp } from "src/app/shared/models/filter";
import {
  Comparison,
  CompetitorProductEmbedded,
  CompetitorProductNormalized,
  ProductComparator
} from "src/app/shared/models/product-comparator";
import ViolationsByEcommerce from "src/app/shared/models/violations-by-ecommerce";

export const ProductComparatorActions = {
  // TODO Valutare il type delle actions e come viene creato
  loadProductComparator: createAction(
    "[Dashboard Component] Load Products Comparasion"
  ),

  loadProductComparatorSuccess: createAction(
    "[ProductComparator Effects] Load Product Comparasion Success",
    props<{ productsComparator: ProductComparator }>()
  ),

  loadProductComparatorError: createAction(
    "[ProductComparator Effects] Load Product Comparasion Error",
    props<{ error: any }>()
  ),

  // TODO togliere any come tipo di data
  updateCompetitorProduct: createAction(
    "[ProductsAnomalies Component] Update Competitor Product",
    props<{ productId: string; data: any }>()
  ),

  updateCompetitorProductSuccess: createAction(
    "[ProductComparator Effects] Update Competitor Product Success",
    props<{ productId: string; data: ProductType }>()
  ),

  updateCompetitorProductError: createAction(
    "[ProductComparator Effects] Update Competitor Product Error",
    props<{ productId: string; error: any }>()
  ),

  wrongProductIntoComparison: createAction(
    "[ProductsAnomalies Component] Wrong Product Into Comparison",
    props<{ comparisonId: string; productId: string }>()
  ),

  wrongProductIntoComparisonSuccess: createAction(
    "[ProductComparator Effects] Wrong Product Into Comparison Success",
    props<{ comparisonId: string; productId: string; response?: any }>()
  ),

  wrongProductIntoComparisonError: createAction(
    "[ProductComparator Effects] Wrong Product Into Comparison Error",
    props<{ comparisonId: string; productId: string; error: any }>()
  ),

  loadProductPriceHistory: createAction(
    "[ProductPrices Resolver] Load Product Price History Requested",
    props<{ comparisonId: string }>()
  ),

  loadProductPriceHistorySuccess: createAction(
    "[ProductPrices Resolver] Load Product Price History Success",
    props<{ comparisonId: string }>()
  ),

  loadProductPriceHistoryError: createAction(
    "[ProductPrices Resolver] Load Product Price History Error",
    props<{ error: any }>()
  ),

  /**
   * Filter Actions
   */
  filterCompetitorsProductsOfComparison: createAction(
    "[ProductPrices] Filter Competitors Products Requested",
    props<{ comparisonId: string; filter?: FilterProp }>()
  ),

  filterCompetitorsProductsOfComparisonSuccess: createAction(
    "[ProductPrices] Filter Competitors Products Requested Success",
    props<{
      comparisonId?: string;
      filter?: FilterProp;
      comparison?: Comparison<CompetitorProductNormalized>;
      competitors?: any;
    }>()
  ),

  filterCompetitorsProductsOfComparisonError: createAction(
    "[ProductPrices] Filter Competitors Products Error",
    props<{ error: any }>()
  ),

  /**
   * Actions to export  file
   */
  exportComparisonTOCSV: createAction(
    "[ProductPrices Component] Export Product Comparison Requested",
    props<{
      comparisonId?: string;
      competitors: CompetitorProductEmbedded[];
      fileName?: string;
    }>()
  ),

  exportComparisonTOCSVSuccess: createAction(
    "[ProductPrices Component] Export Product Comparison Success",
    props<{ comparisonId: string }>()
  ),

  exportComparisonTOCSVError: createAction(
    "[ProductPrices Component] Export Product Comparison Error",
    props<{ error: any }>()
  ),

  /**
   * Sorting actions
   */
  sortComparison: createAction(
    "[ProductPrices Component] Sort selected Comparison",
    props<{ comparisonId?: string; comparison: any; filter: FilterProp }>()
  ),
  sortComparisonSuccess: createAction(
    "[ProductPrices Component] Sort selected Comparison Success",
    props<{
      comparisonId: string;
      comparison: Comparison<CompetitorProductNormalized>;
      filter: FilterProp;
    }>()
  ),
  sortComparisonError: createAction(
    "[ProductPrices Component] Sort selected Comparison Error",
    props<{ error: any }>()
  ),

  /**
   * Missing Ecommerce actions
   */
  getMissingEcommercesFromComparison: createAction(
    "[ProductPrices Resolver] Load Missing Ecommerces from Comparison",
    props<{ comparisonId: string }>()
  ),
  getMissingEcommercesFromComparisonSuccess: createAction(
    "[ProductPrices Component] Load Missing Ecommerces from Comparison Success",
    props<{ comparisonId?: string, entities: any }>()
  ),
  getMissingEcommercesFromComparisonError: createAction(
    "[ProductPrices Component] Load Missing Ecommerces from Comparison Error",
    props<{ error: any }>()
  ),

  /**
   * Get Ecommerce are violating the MAP and MSRP policies
   */
  getViolatingEcommerces: createAction(
    "[ProductComparator Resolver] Load Ecommerces are violating policies",
  ),
  getViolatingEcommercesSuccess: createAction(
    "[ProductComparator Effect] Load Ecommerces are violating policies Success",
    props<{ entities: Record<string, ViolationsByEcommerce<string>> }>()
  ),
  getViolatingEcommercesError: createAction(
    "[ProductComparator Effect] Load Ecommerces are violating policies Error",
    props<{ error: any }>()
  ),

};
