import { Provider } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule  } from "@angular/forms";

import { SharedModule } from "../shared/shared.module";

import { MyProductsRoutingModule } from "./my-products.routing.module";
import { MyProductsComponent } from "./containers/my-products/my-products.component";
import { MyProductsListerComponent } from "./presentationals/my-products-lister/my-products-lister.component";
import { MyProductManagerComponent } from "./containers/my-product-manager/my-product-manager.component";
import { MyProductAdderComponent } from "./presentationals/my-product-adder/my-product-adder.component";
import { MapMsrpManagerComponent } from './presentationals/map-msrp-manager/map-msrp-manager.component';
import { MyProductsNotificationsManagerComponent } from './presentationals/my-products-notifications-manager/my-products-notifications-manager.component';

export const IMPORTS = [
  CommonModule,
  FormsModule,
  // Custom modules
  SharedModule,
  MyProductsRoutingModule,
];

export const PROVIDERS: Provider[] = [];

export const DECLARATIONS = [
  MyProductsComponent,
  MyProductsListerComponent,
  MyProductManagerComponent,
  MyProductAdderComponent,
  MapMsrpManagerComponent,
  MyProductsNotificationsManagerComponent
];

export const EXPORTS = [];
