import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Route, RouterModule } from "@angular/router";
import { ReportsComponent } from "./containers/reports/reports.component";
import ReportsResolver from "../core/resolvers/reports-resolver/reports-resolver";

const routes: Route[] = [
  {
    path: '',
    component: ReportsComponent,
    resolve: {
      charts: ReportsResolver
    }
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
