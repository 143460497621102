import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "wizard-panel",
  template: `<ng-container *ngIf="show">
    <ng-content></ng-content
  ></ng-container>`,
  styles: [],
})
export class WizardPanelComponent implements OnInit {
  @Input() id: string;
  @Input() show: boolean;

  constructor() {}

  ngOnInit() {
    this.show = false;
  }
}
