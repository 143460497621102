import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from "@angular/common/http";
import { Observable } from "rxjs";
import { flatMap } from "rxjs/operators";
import { Store, select } from "@ngrx/store";

import { User } from "src/app/shared/models/user";
import { AuthService } from "../services/auth/auth.service";

import { ApplicationState } from "src/app/store/reducers";
import { selectUserToken } from "src/app/store/selectors";

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<ApplicationState>,
    private authService: AuthService
  ) {}

  // TODO Con l'implementazione di NgRx, valutare l'utilizzo di effects, oppure spostare questa logica in un reducer lasciando qui solo l'action richiesta
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser: User = this.authService.currentUser();
    let _req: HttpRequest<any>;

    return this.store.pipe(select(selectUserToken)).pipe(
      flatMap((token: string) => {
        _req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });

        return next.handle(_req);
      })
    );
  }
}
