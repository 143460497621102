import { createAction, createSelector, props } from "@ngrx/store";
import {
  AutomationNotificationType,
  AutomationReferrerType,
  AutomationType,
} from "@sprintstudioco/ts-types";
import { AutomationFrequency } from "@sprintstudioco/ts-types";

export const loadAllAutomations = createAction(
  "[CommercialAssistant Resolver] Load All Automations Requested"
);

export const loadAllAutomationsSuccess = createAction(
  "[CommercialAssistant Effect] Load All Automations Success",
  props<{ entities: any }>()
);

export const loadAllAutomationsError = createAction(
  "[CommercialAssistant Effect] Load All Automations Error",
  props<{ error: any }>()
);

export const selectAutomation = createAction(
  "[CommercialAssistant Component] Select Specific Automation",
  props<{ automationId: string }>()
);

export const removeSelectedAutomation = createAction(
  "[CommercialAssistant Component] Remove Selected Automation",
  props<{ automationId: string }>()
);

export const editAutomation = createAction(
  "[CommercialAssistant Component] Edit Automation",
  props<{ automationId: string; data?: Partial<AutomationType> }>()
);

export const editAutomationSuccess = createAction(
  "[CommercialAssistant Component] Edit Automation Success",
  props<{ automationId: string; data: Partial<AutomationType> }>()
);

export const editAutomationError = createAction(
  "[CommercialAssistant Component] Edit Automation Error",
  props<{ error: any }>()
);

export const deleteAutomations = createAction(
  "[CommercialAssistant Component] Delete Selected Automations",
  props<{ automationsIds: string[] }>()
);

export const deleteAutomationsSuccess = createAction(
  "[CommercialAssistant Component] Delete Selected Automations Success",
  props<{ automationsIds: string[] }>()
);

export const deleteAutomationsError = createAction(
  "[CommercialAssistant Component] Delete Selected Automations Error",
  props<{ error: any }>()
);

/**
 * Automation creator
 */
export const initAutomationDraft = createAction(
  "[AutomationCreator Component] Init Automation Draft"
);

export const setEcommerceToNotify = createAction(
  "[AutomationCreator Component] Select Ecommerce to Notify",
  props<{ ecommerceId: string }>()
);

export const setAutomationType = createAction(
  "[AutomationCreator Component] Select Automation Type",
  props<{ notificationType: AutomationNotificationType }>()
);

export const setAutomationFrequency = createAction(
  "[AutomationCreator Component] Select Automation Frequency",
  props<{ frequencyInDays: AutomationFrequency }>()
);

export const addReferrerForEcommerce = createAction(
  "[AutomationCreator Component] Add Reference for Ecommerce",
  props<{ referent: AutomationReferrerType }>()
);

export const addReferrerForEcommerceSuccess = createAction(
  "[AutomationCreator Component] Add Reference for Ecommerce Success",
  props<{ reference: AutomationReferrerType }>()
);

export const addReferrerForEcommerceError = createAction(
  "[AutomationCreator Component] Add Reference for Ecommerce Error",
  props<{ error: any }>()
);

export const removeReferenceForEcommerce = createAction(
  "[AutomationCreator Component] Remove Reference for Ecommerce",
  props<{ referentEmail: string }>()
);

export const removeReferenceForEcommerceSuccess = createAction(
  "[AutomationCreator Componente] Remove Reference for Ecommerce Success",
  props<{ reference: string }>()
);

export const removeReferenceForEcommerceError = createAction(
  "[AutomationCreator Component] Remove Reference for Ecommerce Error",
  props<{ error: any }>()
);

export const changeEmailSubjectIntoDraft = createAction(
  "[AutomationCreator Component] Change Email Subject Into Draft",
  props<{ emailSubject: string }>()
);

export const changeEmailTemplateIntoDraft = createAction(
  "[AutomationCreator Component] Change Email Template Into Draft",
  props<{ emailTemplate: string }>()
);

export const setAutomationStatus = createAction(
  "[AutomationCreator Component] Set Automation Status",
  props<{ isActive: boolean }>()
);

export const saveDraftedAutomation = createAction(
  "[AutomationCreator Component] Save Automation",
  props<{ automation: AutomationType }>()
);

export const saveDraftedAutomationSuccess = createAction(
  "[CommercialAssistant Effect] Save Automation Success",
  props<{ automation: AutomationType }>()
);

export const saveDraftedAutomationError = createAction(
  "[CommercialAssistant Effect] Save Automation Error",
  props<{ error: any }>()
);

export const activeAutomations = createAction(
  "[CommercialAssistant Component] Active Automations",
  props<{ automationsIds: string[] }>()
);

export const activeAutomationsSuccess = createAction(
  "[CommercialAssistant Effect] Active Automations Success",
  props<{ automationsIds: string[] }>()
);

export const activeAutomationsError = createAction(
  "[CommercialAssistant Effect] Active Automations Error",
  props<{ error: any }>()
);

export const deactiveAutomations = createAction(
  "[CommercialAssistant Component] Deactive Automations",
  props<{ automationsIds: string[] }>()
);

export const deactiveAutomationsSuccess = createAction(
  "[CommercialAssistant Effect] Deactive Automations Success",
  props<{ automationsIds: string[] }>()
);

export const deactiveAutomationsError = createAction(
  "[CommercialAssistant Effect] Deactive Automations Error",
  props<{ error: any }>()
);
