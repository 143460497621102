import { ErrorHandler, Injectable } from "@angular/core";
import Bugsnag from "@bugsnag/js";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";

@Injectable({
  providedIn: "root"
})
export default class PricyoErrorHandler implements ErrorHandler {
  private bugsnag: BugsnagErrorHandler;

  constructor() {
    this.bugsnag = new BugsnagErrorHandler();
  }

  handleError(error: any): void {
    Bugsnag.notify(new Error(error));
    throw error;
  }
}
