import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationState } from "./store/reducers";
import { select, Store } from "@ngrx/store";
import { AuthActions } from "./store/actions/auth.action";
import { ProductComparatorActions } from "./store/actions";
import { AuthSelectors } from "./store/selectors";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "ecommerce-scraper-front";

  constructor(private store: Store<ApplicationState>) {}

  ngOnInit() {
    this.store.dispatch(AuthActions.loadAuthToken());
    this.store.dispatch(AuthActions.loadUserProfile());
    this.store.dispatch(ProductComparatorActions.loadProductComparator());
  }
}
