import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { CompetitorsActions } from "../actions";
import { mergeMap, map, catchError, filter } from "rxjs/operators";
import { EcommerceService } from "src/app/core/services/ecommerce/ecommerce.service";
import { of } from "rxjs";
import { EcommerceSchema } from "../schemas";
import { normalize } from "normalizr";

@Injectable()
export class CompetitorsEffect {
  constructor(
    private actions$: Actions,
    private ecommerceService: EcommerceService
  ) { }

  loadCompetitors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompetitorsActions.loadCompetitors),
      mergeMap(() =>
        this.ecommerceService.getCompetitors({ page: 1 }).pipe(
          map((competitors) => {
            const schema = new EcommerceSchema();
            return CompetitorsActions.loadCompetitorsSuccess({
              entities: normalize(competitors, [schema]).entities,
            });
          }),
          catchError((err) =>
            of(CompetitorsActions.loadCompetitorsError({ error: err }))
          )
        )
      )
    )
  );

  sortByField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompetitorsActions.sortByField),
      mergeMap(({ field, order }) =>
        this.ecommerceService
          .getCompetitors({
            sort: field,
            order: order === "asc" ? "desc" : "asc",
          })
          .pipe(
            map((competitors) => {
              const schema = new EcommerceSchema();
              return CompetitorsActions.sortByFieldSuccess({
                field,
                order: order === "asc" ? "desc" : "asc",
                entities: normalize(competitors, [schema]).entities,
              });
            }),
            catchError((err) =>
              of(CompetitorsActions.sortByFieldError({ error: err }))
            )
          )
      )
    )
  );

  previousPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompetitorsActions.previousCompetitorsPage),
      filter(({ page }) => page > 1),
      mergeMap(({ page, sort, order }) =>
        this.ecommerceService
          .getCompetitors({ page: --page, sort, order })
          .pipe(
            map((competitors) => {
              const schema = new EcommerceSchema();
              return CompetitorsActions.loadCompetitorsSuccess({
                entities: normalize(competitors, [schema]).entities,
              });
            }),
            catchError((err) =>
              of(CompetitorsActions.loadCompetitorsError({ error: err }))
            )
          )
      )
    )
  );

  nextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompetitorsActions.nextCompetitorsPage),
      mergeMap(({ page, sort, order }) =>
        this.ecommerceService
          .getCompetitors({ page: ++page, sort, order })
          .pipe(
            map((competitors) => {
              const schema = new EcommerceSchema();
              return CompetitorsActions.loadCompetitorsSuccess({
                entities: normalize(competitors, [schema]).entities,
              });
            }),
            catchError((err) =>
              of(CompetitorsActions.loadCompetitorsError({ error: err }))
            )
          )
      )
    )
  );
}
