import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import PrTabNavigation from "src/app/shared/models/pr-tab-navigation";

@Component({
  selector: "pr-tab-navigation",
  template: `
    <div class="mb-2">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <select
          id="tabs"
          name="tabs"
          class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        >
          <option
            *ngFor="let tab of tabLabels"
            [selected]="selectedTab.label === tab.label"
            [id]="tab.id"
            (click)="labelClicked(tab)"
            >{{ tab.label }}</option
          >
        </select>
      </div>
      <div class="hidden sm:block">
        <nav
          class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          <a
            class="text-gray-900 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
            aria-current="page"
            *ngFor="let tab of tabLabels; let i = index"
            [id]="tab.id"
            [ngClass]="{
              'rounded-l-lg': i === 0,
              'rounded-r-lg': i === tabLabels.length - 1
            }"
            (click)="labelClicked(tab)"
          >
            <span>{{ tab.label }}</span>
            <span
              *ngIf="selectedTab.label === tab.label"
              aria-hidden="true"
              class="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"
            ></span>
          </a>
        </nav>
      </div>
    </div>
  `,
  styles: []
})
export class PrTabNavigationComponent implements OnInit {
  private selectedTab: PrTabNavigation;

  @Input() tabLabels: PrTabNavigation[];
  @Output() changeTab: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.selectedTab = this.tabLabels[0];
  }

  labelClicked(tab) {
    this.selectedTab = tab;
    this.changeTab.emit(tab);
  }
}
