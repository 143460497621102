import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "card-info",
  template: `
    <div class="rounded shadow-sm bg-white">
      <div class="flex flex-col p-5 space-y-1">
        <h4 class="text-xs font-semibold text-gray-500 uppercase">
          {{ heading }}
        </h4>
        <p *ngIf="totalProducts || content" class="text-xl text-gray-700">{{ totalProducts || content }}</p>
      </div>
      <div class="flex flex-col justify-center border-t">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [],
})
export class CardInfoComponent implements OnInit {
  @Input() heading: string;
  @Input() content: string;
  @Input() totalProducts: string;
  @Input() lastTracked: string;

  constructor() { }

  ngOnInit() { }
}
