import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'page-header',
  template: `
    <div class="w-full mt-8 flex justify-between items-center text-indigo-900 font-semibold">
      <h3>{{ title }}</h3>
      <ng-content></ng-content>
    </div>
  `,
  styles: [
  ]
})
export class PageHeaderComponent {
  @Input() title: string;

  constructor() { }

}
