import { Injectable } from "@angular/core";
import { ProductService } from "src/app/core/services/product/product.service";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { mergeMap, map, catchError, filter, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { ProductType } from "@sprintstudioco/ts-types";

import { EcommerceProductsActions } from "../actions";
import { MyProductsSchema } from "../schemas";
import { normalize } from "normalizr";
import { ProductsEntities } from "src/app/shared/models/product-entity";
import { Router } from "@angular/router";
import { ApplicationState } from "../reducers";
import { select, Store } from "@ngrx/store";
import { EcommerceProductsSelectors } from "../selectors";

@Injectable()
export class EcommerceProductsEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<ApplicationState>,
    private productsService: ProductService
  ) {}

  loadEcommerceProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.loadEcommerceProducts),
      mergeMap(() =>
        this.productsService.getEcommerceProducts({ page: 1 }).pipe(
          map((products: ProductType[]) => {
            const schema = new MyProductsSchema();
            const entities: any = normalize<ProductsEntities>(products, [
              schema
            ]).entities;

            return EcommerceProductsActions.loadEcommerceProductsSuccess({
              entities
            });
          }),
          catchError(err =>
            of(
              EcommerceProductsActions.loadEcommerceProductsError({
                error: err
              })
            )
          )
        )
      )
    )
  );

  trackProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.trackProduct),
      mergeMap(({ id }) =>
        this.productsService.trackProducts([id]).pipe(
          map((response: ProductType[]) =>
            EcommerceProductsActions.trackProductSuccess({ id })
          ),
          catchError(err =>
            of(EcommerceProductsActions.trackProductError({ error: err }))
          )
        )
      )
    )
  );

  trackProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.trackProducts),
      mergeMap(({ products }) =>
        this.productsService.trackProducts(products).pipe(
          map((response: any) =>
            EcommerceProductsActions.trackProductsSuccess({
              products,
              response
            })
          ),
          catchError(err =>
            of(EcommerceProductsActions.trackProductsError({ error: err }))
          )
        )
      )
    )
  );

  untrackProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.untrackProduct),
      mergeMap(({ id }) =>
        this.productsService.untrackProducts([id]).pipe(
          map(response =>
            EcommerceProductsActions.untrackProductSuccess({ id })
          ),
          catchError(err =>
            of(EcommerceProductsActions.untrackProductError({ error: err }))
          )
        )
      )
    )
  );

  untrackProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.untrackProducts),
      mergeMap(({ products }) =>
        this.productsService.untrackProducts(products).pipe(
          map(response =>
            EcommerceProductsActions.untrackProductsSuccess({
              products,
              response
            })
          ),
          catchError(err =>
            of(EcommerceProductsActions.untrackProductsError({ error: err }))
          )
        )
      )
    )
  );

  createProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.createProduct),
      mergeMap(({ product }) =>
        this.productsService.createProduct(product).pipe(
          map(response => {
            //this.router.navigate(["/my-products"]);
            return EcommerceProductsActions.createProductSuccess({
              product,
              response
            });
          }),
          catchError(err =>
            of(EcommerceProductsActions.createProductError({ error: err }))
          )
        )
      )
    )
  );

  updateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.updateProduct),
      mergeMap(({ product }) => {
        const _id = product._id;
        delete product._id;
        delete product.category;
        delete product.ecommerce;
        delete product.discountedPrice;
        delete product.discount;

        return this.productsService.updatedProduct(_id, { ...product }).pipe(
          map(response =>
            EcommerceProductsActions.updateProductSuccess({
              product,
              response
            })
          ),
          catchError(err =>
            of(EcommerceProductsActions.updateProductError({ error: err }))
          )
        );
      })
    )
  );

  saveMAPandMSRP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.saveMAPandMSRP),
      mergeMap(({ productUrl, data }) =>
        this.productsService.saveMAPandMSRP(productUrl, data).pipe(
          map(response =>
            EcommerceProductsActions.saveMAPandMSRPSuccess({
              productUrl,
              data,
              response
            })
          ),
          catchError(err =>
            of(EcommerceProductsActions.saveMAPandMSRPError({ error: err }))
          )
        )
      )
    )
  );

  loadAlertByProductId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.loadAlertByProductId),
      mergeMap(({ productId, productUrl }) =>
        this.productsService.getAlertByProductId(productId).pipe(
          map(alert =>
            EcommerceProductsActions.loadAlertByProductIdSuccess({ alert })
          ),
          catchError(err =>
            of(
              EcommerceProductsActions.loadAlertByProductIdError({
                error: err,
                productUrl,
                productId
              })
            )
          )
        )
      )
    )
  );

  loadAlertByProductIdError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.loadAlertByProductIdError),
      mergeMap(({ productId, productUrl }) =>
        this.productsService.createAlert(productId, { productId }).pipe(
          map(
            response =>
              EcommerceProductsActions.createAlertSuccess({
                productId
              }),
            catchError(err =>
              of(
                EcommerceProductsActions.createAlertError({
                  error: err
                })
              )
            )
          )
        )
      )
    )
  );

  updateAlertByProductId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.updateAlertByProductId),
      mergeMap(({ productId, data }) =>
        this.productsService.updateAlertByProductId(productId, data).pipe(
          map(
            alert =>
              EcommerceProductsActions.updateAlertByProductIdSuccess({
                productId,
                alert
              }),
            catchError(err =>
              of(
                EcommerceProductsActions.updateAlertByProductIdError({
                  error: err
                })
              )
            )
          )
        )
      )
    )
  );

  previousPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.previousPage),
      filter(({ page }) => page > 1),
      mergeMap(({ page }) =>
        this.productsService.getEcommerceProducts({ page: --page }).pipe(
          map((products: ProductType[]) => {
            const schema: any = new MyProductsSchema();
            const entities: any = normalize<ProductsEntities>(products, [
              schema
            ]).entities;

            return EcommerceProductsActions.previousPageSuccess({
              entities
            });
          }),
          catchError(err =>
            of(EcommerceProductsActions.previousPageError({ error: err }))
          )
        )
      )
    )
  );

  nextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EcommerceProductsActions.nextPage),
      mergeMap(({ page }) =>
        this.productsService.getEcommerceProducts({ page: ++page }).pipe(
          map((products: ProductType[]) => {
            const schema = new MyProductsSchema();
            const entities: any = normalize<ProductsEntities>(products, [
              schema
            ]).entities;

            return EcommerceProductsActions.nextPageSuccess({ entities });
          }),
          catchError(err =>
            of(EcommerceProductsActions.previousPageError({ error: err }))
          )
        )
      )
    )
  );
}
