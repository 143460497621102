import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "basic-chart",
  template: ``,
  styles: []
})
export default class BasicChartComponent implements OnInit {
  @Input() view: any[] = [700, 400];
  // options
  @Input() showXAxis: boolean = true;
  @Input() showYAxis: boolean = true;
  @Input() gradient: boolean = false;
  @Input() showLegend: boolean = true;
  @Input() showXAxisLabel: boolean = true;
  @Input() xAxisLabel: string = "Products";
  @Input() showYAxisLabel: boolean = true;
  @Input() yAxisLabel: string = "Country";
  @Input() showGridLines: boolean = false;
  @Input() animations: boolean = true;

  @Input() data;

  colorScheme = {
    domain: ["#5AA454", "#C7B42C", "#AAAAAA"]
  };

  constructor() {}

  ngOnInit() {
    /* options
    this.showXAxis = true;
    this.showYAxis = true;
    this.gradient = false;
    this.showLegend = true;
    this.showXAxisLabel = true;
    this.xAxisLabel = this.xAxisLabel || "Products";
    this.showYAxisLabel = this.showYAxisLabel || true;
    this.yAxisLabel = this.yAxisLabel || "Country";
    this.animations = true;
    this.colorScheme = { domain: ["#5AA454", "#C7B42C", "#AAAAAA"] };*/
  }
}
