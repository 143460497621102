import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ProductComparatorActions } from "src/app/store/actions";
import { ReportsAction } from "src/app/store/actions/reports.action";
import { ApplicationState } from "src/app/store/reducers";

@Injectable({
  providedIn: "root"
})
export class ProductComparisonResolver implements Resolve<void> {
  constructor(private store: Store<ApplicationState>) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): void | Observable<void> | Promise<void> {

    this.store.dispatch(
      ProductComparatorActions.getMissingEcommercesFromComparison({ comparisonId: route.paramMap.get("comparisonId") })
    )

    this.store.dispatch(
      ReportsAction.loadComparisonCompetitorsProductGroupedByCountry({
        comparisonId: route.paramMap.get("comparisonId")
      })
    );
  }
}
