import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { LoginFormComponent } from "./presentationals/login-form/login-form.component";
import { AuthComponent } from "./containers/auth/auth.component";
import { RegisterFormComponent } from "./presentationals/register-form/register-form.component";

const routes: Route[] = [
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "login",
        component: LoginFormComponent,
        //outlet: "login-form",
      },
      {
        path: "register",
        component: RegisterFormComponent,
        // outlet: "register-form",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
