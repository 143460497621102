import { Injectable } from "@angular/core";
import { ExportToCsv, Options } from "export-to-csv";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CSVExporterService {
  constructor() {}

  exportCSV(data: any, options?: Options): Observable<any> {
    const _ptions = {
      fieldSeparator: "\t",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      ...options
    };

    const csvExpoter = new ExportToCsv(_ptions);
    csvExpoter.generateCsv(data);

    return new Observable();
  }
}
