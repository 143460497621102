import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "badge",
  template: `
    <label
      class="w-48 px-3 py-1 rounded-full  text-center font-semibold text-xs leading-5"
      [ngClass]="{
        'bg-green-100 text-green-800': type === 'success',
        'bg-gray-100 text-gray-800': type === 'secondary',
        'bg-red-100 text-red-800': type === 'danger'
      }"
      ><ng-content> </ng-content
    ></label>
  `,
  styles: [],
})
export class BadgeComponent {
  @Input() type: string;

  constructor() {}
}
