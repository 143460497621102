import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ProductType } from "@sprintstudioco/ts-types";
import { curveStepAfter } from "d3-shape";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "price-history",
  template: `
    <div class>
      <card-info [heading]="heading">
        <div class="w-2/4 m-5 flex flex-col space-y-1 text-sm">
          <ng-select
            class="text-sm rounded"
            [items]="comparison?.competitors"
            bindLabel="ecommerce.name"
            bindValue="product._id"
            [placeholder]="placeholder"
            [multiple]="true"
            [searchable]="true"
            (change)="onChangeSelectedCompetitorsProduct($event)"
          ></ng-select>
        </div>
        <line-chart
          [hidden]="!productsUrlSelected || productsUrlSelected?.length === 0"
          [xAxisLabel]=""
          [curve]="curve"
          [yAxisLabel]="'Price'"
          [querySubject$]="querySubject$"
        ></line-chart>
      </card-info>
    </div>
  `,
  styles: []
})
export class PriceHistoryComponent implements OnInit {
  private history: any[];
  private historyChartQuery: any;
  private curve: any = curveStepAfter;
  private querySubject$: Subject<any>;
  private cubejs$: Observable<any>;
  private productsUrlSelected: string[];

  @Input() heading: string;
  @Input() placeholder: string;
  @Input() products: ProductType[];
  @Input() comparison: any;
  @Output() onChangeProductsUrl: EventEmitter<string[]> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.querySubject$ = new Subject();

    this.historyChartQuery = {
      order: {
        "Products.visibleprice": "desc"
      },
      measures: ["Products.visibleprice"],
      timeDimensions: [
        {
          dimension: "Products.timestamp",
          granularity: "week",
          dateRange: ["2021-05-30", "2021-12-31"]
        }
      ],
      dimensions: ["Products.ecommerce", "Products.url"],
      filters: [
        {
          member: "Products.url",
          operator: "equals",
          values: []
        }
      ]
    };
  }

  onChangeSelectedCompetitorsProduct(competitors: any[]) {
    this.productsUrlSelected = [
      ...competitors.map(competitor => competitor.product.url)
    ];
    this.historyChartQuery.filters = [
      {
        member: "Products.url",
        operator: "equals",
        values: [...this.productsUrlSelected]
      }
    ];

    if (this.productsUrlSelected.length > 0) {
      this.querySubject$.next(this.historyChartQuery);
    }

    //this.onChangeProductsUrl.emit(this.productsUrl);
  }
}
