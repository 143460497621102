import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, mergeMap, map } from "rxjs/operators";
import { normalize } from "normalizr";

import { CommercialAssistantService } from "src/app/core/services/commercial-assistant/commercial-assistant.service";
import { CommercialAssistantActions } from "../actions";
import { ApplicationState } from "../reducers";
import AutomationSchema from "../schemas/automation.schema";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Injectable()
export class CommercialAssistantEffect {
  constructor(
    private store: Store<ApplicationState>,
    private actions$: Actions,
    private commercialAssistantService: CommercialAssistantService
  ) {}

  loadAllAutomations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommercialAssistantActions.loadAllAutomations),
      mergeMap(() =>
        this.commercialAssistantService.getAllAutomations().pipe(
          map((automations) => {
            const automationSchema = new AutomationSchema();
            return CommercialAssistantActions.loadAllAutomationsSuccess({
              entities: normalize(automations, [automationSchema]).entities,
            });
          }),
          catchError((err) =>
            of(
              CommercialAssistantActions.loadAllAutomationsError({ error: err })
            )
          )
        )
      )
    )
  );

  saveAutomationFromDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommercialAssistantActions.saveDraftedAutomation),
      mergeMap(({ automation }) =>
        this.commercialAssistantService.createAutomation(automation).pipe(
          map((automationCreated) =>
            CommercialAssistantActions.saveDraftedAutomationSuccess({
              automation: automationCreated,
            })
          ),
          catchError((err) =>
            of(
              CommercialAssistantActions.saveDraftedAutomationError({
                error: err,
              })
            )
          )
        )
      )
    )
  );

  deleteAutomations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommercialAssistantActions.deleteAutomations),
      mergeMap(({ automationsIds }) =>
        this.commercialAssistantService.deleteAutomations(automationsIds).pipe(
          map((response) =>
            CommercialAssistantActions.deleteAutomationsSuccess({
              automationsIds,
            })
          ),
          catchError((err) =>
            of(
              CommercialAssistantActions.deleteAutomationsError({ error: err })
            )
          )
        )
      )
    )
  );

  editAutomation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommercialAssistantActions.editAutomation),
      mergeMap(({ automationId, data }) =>
        this.commercialAssistantService.editAutomation(automationId, data).pipe(
          map((response) =>
            CommercialAssistantActions.editAutomationSuccess({
              automationId,
              data,
            })
          ),
          catchError((err) =>
            of(CommercialAssistantActions.editAutomationError({ error: err }))
          )
        )
      )
    )
  );

  activeAutomations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommercialAssistantActions.activeAutomations),
      mergeMap(({ automationsIds }) =>
        this.commercialAssistantService.activeAutomations(automationsIds).pipe(
          map(() =>
            CommercialAssistantActions.activeAutomationsSuccess({
              automationsIds,
            })
          ),
          catchError((err) =>
            of(
              CommercialAssistantActions.activeAutomationsError({ error: err })
            )
          )
        )
      )
    )
  );

  deactiveAutomations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommercialAssistantActions.deactiveAutomations),
      mergeMap(({ automationsIds }) =>
        this.commercialAssistantService
          .deactiveAutomations(automationsIds)
          .pipe(
            map(() =>
              CommercialAssistantActions.deactiveAutomationsSuccess({
                automationsIds,
              })
            ),
            catchError((err) =>
              of(
                CommercialAssistantActions.deactiveAutomationsError({
                  error: err,
                })
              )
            )
          )
      )
    )
  );

  /*
  addReferenceForEcommerce$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommercialAssistantActions.addReferenceForEcommerce),
      mergeMap(({ reference }) =>
        this.store
          .pipe(
            select(CommercialAssistantSelectors.selectAllReferentsIntoDraft)
          )
          .pipe(
            map((references) =>
              iif(
                () => references.find((ref) => ref.email === reference.email),
                CommercialAssistantActions.addReferenceForEcommerceSuccess({
                  reference,
                }),
                CommercialAssistantActions.addReferenceForEcommerceError({
                  reference,
                })
              )
            )
          )
      )
    )
  );
  */
}
