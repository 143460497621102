import { createAction, props } from "@ngrx/store";

export const loadCompetitors = createAction(
  "[Competitors Dashboard] Load Competitors"
);

export const loadCompetitorsSuccess = createAction(
  "[Competitors Effect] Load Competitors Success",
  props<{ entities: any }>()
);

export const loadCompetitorsError = createAction(
  "[Competitors Effect] Load Competitors Error",
  props<{ error: any }>()
);

export const sortByField = createAction(
  "[Competitors Dashboard] Sort by field",
  props<{ field: string; order?: string }>()
);

export const sortByFieldSuccess = createAction(
  "[Competitors Effects] Sort by field Success",
  props<{ field: string; order: string; entities: any }>()
);

export const sortByFieldError = createAction(
  "[Competitors Effects] Sort by field Error",
  props<{ error: any }>()
);

export const previousCompetitorsPage = createAction(
  "[Competitors Dashboard] Previous Competitors Page",
  props<{ page: number; sort?: string; order?: string }>()
);

export const nextCompetitorsPage = createAction(
  "[Competitors Dashboard] Next Competitors Page",
  props<{ page: number; sort?: string; order?: string }>()
);
