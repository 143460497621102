import { CommonModule } from '@angular/common';
import { CubejsClient, CubejsClientModule } from '@cubejs-client/ngx';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import ReportsEffect from '../store/effects/reports.effect';
import { ReportsComponent } from './containers/reports/reports.component';
import { ReportsRoutingModule } from './reports-routing.module';


export const IMPORTS = [
  CommonModule,
  NgSelectModule,
  SharedModule,
  ReportsRoutingModule,
  CubejsClientModule.forRoot({
    token: window.localStorage.getItem(environment.token_name),
    options: {
      apiUrl: environment.CubeJs.host
    }
  }),
  EffectsModule.forFeature([
    ReportsEffect,
  ]),
];
export const DECLARATIONS = [ReportsComponent];
export const EXPORTS = [];
