import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { normalize } from "normalizr";
import { merge, of, throwError } from "rxjs";
import {
  catchError,
  delay,
  map,
  mergeMap,
  skipUntil,
  skipWhile,
} from "rxjs/operators";
import { ProductService } from "src/app/core/services/product/product.service";
import { CompetitorsActions, ProductsMatchingActions } from "../actions";
import ProductsMatchingSchema from "../schemas/products-matching.schema";

@Injectable()
export default class ProductsMatchingEffects {
  constructor(
    private actions$: Actions,
    private productService: ProductService
  ) {}

  /*
  loadSimilarProductToMatch$ = createEffect(() => 
    this.actions$.pipe(
      ofType(ProductsMatchingActions.loadNextCompetitorToMatch),
      mergeMap(({ product }) => 
      )
    )
  );
  */

  loadCompetitorSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompetitorsActions.loadCompetitorsSuccess),
      map(({ entities }) => {
        const nextIndexCompetitor = Object.keys(entities.ecommerce)[0];
        return ProductsMatchingActions.loadNextCompetitorToMatch({
          nextCompetitorToMatch: entities.ecommerce[nextIndexCompetitor],
        });
      })
    )
  );

  loadSimilarProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsMatchingActions.loadSimilarProducts),
      mergeMap(({ productId, competitorId }) =>
        this.productService
          .getSimilarProductsOfASpecificEcommerce(productId, competitorId)
          .pipe(
            map((data) => {
              if (data.length === 0) {
                return ProductsMatchingActions.loadNextCompetitorToMatch({});
              } else {
                // TODO Inserire nello stato i prodotti simili appena creati ed usare normalizr
                const schema = new ProductsMatchingSchema();
                return ProductsMatchingActions.loadSimilarProductsSuccess({
                  similarProducts: data,
                });
              }
            })
          )
      )
    )
  );

  loadProductsMatching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsMatchingActions.loadProductsForMatching),
      mergeMap(({ productId }) =>
        this.productService.getProductsToMatch(productId).pipe(
          map((products: any) => {
            const schema = new ProductsMatchingSchema();
            return ProductsMatchingActions.loadProductsForMatchingSuccess({
              entities: normalize(products, schema).entities,
            });
          }),
          catchError((err) =>
            of(
              ProductsMatchingActions.loadProductsForMatchingError({
                error: err,
              })
            )
          )
        )
      )
    )
  );

  matchProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsMatchingActions.matchProducts),
      mergeMap(({ productId, similarProductID }) =>
        this.productService.matchProducts(productId, similarProductID).pipe(
          map((response) =>
            ProductsMatchingActions.matchProductSuccess({
              productId,
              similarProductID,
            })
          ),
          catchError((err) =>
            of(ProductsMatchingActions.matchProductError({ error: err }))
          )
        )
      )
    )
  );
}
