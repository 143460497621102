import { Component, Input, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { ProductType } from "@sprintstudioco/ts-types";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import {
  Comparison,
  CompetitorProductEmbedded
} from "src/app/shared/models/product-comparator";
import { ReportsAction } from "src/app/store/actions/reports.action";
import { ApplicationState } from "src/app/store/reducers";
import {
  ProductsComparatorSelectors,
  ReportsSelectors
} from "src/app/store/selectors";

@Component({
  selector: "reports",
  template: `
    <page-wrapper [title]="'Reports'">
      <div class="grid gap-3 grid-cols-1 md:grid-cols-2">
        <card-header-footer
          [heading]="'Confronta la Distribuzione di due prodotti'"
        >
          <div header>
            <div class="w-full flex space-x-2"></div>
          </div>
          <div body class="flex justify-between space-x-2">
            <div class="flex flex-1 flex-col space-y-2">
              <label class="text-xs">Prodotto 1</label>
              <ng-select
                class="w-full text-sm rounded z-20"
                [items]="comparisons$ | async"
                bindLabel="product.name"
                bindValue="_id"
                [searchable]="true"
                (change)="onChangeSelectedProduct1($event)"
              ></ng-select>
              <pie-chart
                [hidden]="!comparisonsSelected"
                [querySubject$]="countryQuerySubjectProd1$"
              ></pie-chart>
            </div>
            <div class="flex flex-1 flex-col space-y-2">
              <label class="text-xs">Prodotto 2</label>
              <ng-select
                class="w-full text-sm rounded z-20"
                [items]="comparisons$ | async"
                bindLabel="product.name"
                bindValue="_id"
                [searchable]="true"
                (change)="onChangeSelectedProduct2($event)"
              ></ng-select>
              <pie-chart
                [hidden]="!comparisonsSelected"
                [querySubject$]="countryQuerySubjectProd2$"
              ></pie-chart>
            </div>
          </div>
        </card-header-footer>
      </div>
    </page-wrapper>
  `,
  styles: []
})
export class ReportsComponent implements OnInit {
  private countryQuery: any;
  private countryQuerySubjectProd1$: Subject<any>;
  private countryQuerySubjectProd2$: Subject<any>;
  private products$: Observable<ProductType[]>;
  private comparisons$: any;
  private comparisonsSelected: Comparison<
    CompetitorProductEmbedded,
    ProductType
  >;

  constructor(private store: Store<ApplicationState>) {}

  ngOnInit(): void {
    this.comparisons$ = this.store.pipe(
      select(ProductsComparatorSelectors.selectCompetitorsProducts)
    );

    this.store.pipe(
      select(ReportsSelectors.selectGraphFraudolentCompetitorsByMAP)
    );

    this.store.pipe(
      select(ReportsSelectors.selectAllProductsSplittedByCountry)
    );

    this.countryQuerySubjectProd1$ = new Subject();
    this.countryQuerySubjectProd2$ = new Subject();

    this.countryQuery = {
      measures: ["Products.count"],
      timeDimensions: [
        {
          dimension: "Products.timestamp"
        }
      ],
      order: {
        "Products.count": "desc"
      },
      dimensions: ["Products.country"],
      filters: []
    };
  }

  onChangeSelectedProduct1(
    comparison: Comparison<CompetitorProductEmbedded, ProductType>
  ) {
    this.comparisonsSelected = comparison;
    // ...comparison.competitors.map(competitor => competitor.product.url)
    this.countryQuery.filters = [
      {
        member: "Products.country",
        operator: "contains",
        values: ["Italia", "Germania"]
      },
      {
        member: "Products.url",
        operator: "contains",
        values: [
          ...comparison.competitors.map(competitor => competitor.product.url)
        ]
      }
    ];

    this.countryQuerySubjectProd1$.next(this.countryQuery);
  }

  onChangeSelectedProduct2(
    comparison: Comparison<CompetitorProductEmbedded, ProductType>
  ) {
    this.comparisonsSelected = comparison;
    // ...comparison.competitors.map(competitor => competitor.product.url)
    this.countryQuery.filters = [
      {
        member: "Products.country",
        operator: "contains",
        values: ["Italia", "Germania"]
      },
      {
        member: "Products.url",
        operator: "contains",
        values: [
          ...comparison.competitors.map(competitor => competitor.product.url)
        ]
      }
    ];

    this.countryQuerySubjectProd2$.next(this.countryQuery);
  }
}
