import { Component, OnInit } from "@angular/core";

@Component({
  selector: "card-image",
  template: `
    <div class="rounded shadow bg-white">
      <div class="flex flex-row items-center px-5 py-3 space-y-1 space-x-2">
        <span class="w-10 h-10 bg-gray-300 rounded-full"></span>
        <h3 class="text-sm font-normal text-gray-600">Site name</h3>
      </div>
      <div class="flex flex-col px-5 py-3 bg-gray-100">
        <a
          class="h-full w-full cursor-pointer inline-flex items-center justify-center text-sm text-gray-400 text-center"
        >
          <span class="text-gray-600 font-semibold">Go to site</span>
          <svg
            class="h-3 w-3 ml-2 mt-0 fill-current text-gray-500"
            version="1.1"
            id="Chevron_thin_right"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 20 20"
            enable-background="new 0 0 20 20"
            xml:space="preserve"
          >
            <path
              d="M13.25,10L6.109,2.58c-0.268-0.27-0.268-0.707,0-0.979c0.268-0.27,0.701-0.27,0.969,0l7.83,7.908
	c0.268,0.271,0.268,0.709,0,0.979l-7.83,7.908c-0.268,0.271-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.979L13.25,10z"
            /></svg
        ></a>
      </div>
    </div>
  `,
  styles: [],
})
export class CardImageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
