import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EcommerceProductsActions } from "src/app/store/actions";
import { ApplicationState } from "src/app/store/reducers";
import { EcommerceProductsSelectors } from "src/app/store/selectors";

@Injectable({
  providedIn: "root",
})
export class AlertResolver implements Resolve<void> {
  constructor(private store: Store<ApplicationState>) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.store
      .pipe(
        select(
          EcommerceProductsSelectors.selectProductById(
            route.paramMap.get("productId")
          )
        )
      )
      .subscribe((product) => {
        this.store.dispatch(
          EcommerceProductsActions.loadAlertByProductId({
            productId: route.paramMap.get("productId"),
            productUrl: product.url,
          })
        );
      });
  }
}
