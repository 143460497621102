import { Component, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { Credential } from "src/app/shared/models/credential";
import { Store, select } from "@ngrx/store";
import { ApplicationState } from "src/app/store/reducers";
import { AuthActions } from "src/app/store/actions/auth.action";
import { selectLoginError } from "src/app/store/selectors";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "login-form",
  template: ` <div
    class="h-screen w-full flex justify-center items-center bg-gray-300"
  >
    <form
      #loginForm="ngForm"
      class="w-5/6 flex flex-col max-w-md bg-white shadow py-8 px-8 rounded space-y-4"
      (ngSubmit)="login(loginForm.value)"
    >
      <div
        *ngIf="error$ | async"
        class="px-3 py-2 w-full bg-red-200 border-red-600 text-red-800 text-sm border rounded"
      >
        {{ (error$ | async).message }}
      </div>
      <div class="flex flex-col space-y-1">
        <label class="text-gray-700 font-bold text-sm">Email</label>
        <input
          type="email"
          name="email"
          ngModel
          class="bg-white border rounded px-2 py-2 focus:border-gray-600 focus:outline-none"
          required=""
        />
      </div>
      <div class="flex flex-col space-y-1">
        <label class="text-gray-700 font-bold text-sm">Password</label>
        <input
          type="password"
          name="password"
          ngModel
          class="bg-white border rounded px-2 py-2 focus:border-gray-600 focus:outline-none"
          required=""
        />
        <a class="text-sm text-right text-gray-500" href=""
          >Password dimenticata</a
        >
      </div>
      <button class="w-full bg-gray-600 text-white text-sm rounded px-1 py-2">
        Login
      </button>
    </form>
  </div>`,
})
export class LoginFormComponent implements OnInit {
  private error$: any;

  constructor(
    private route: ActivatedRoute,
    private store: Store<ApplicationState>
  ) {}

  ngOnInit() {
    this.error$ = this.store.pipe(select(selectLoginError));
  }

  // TODO Spostare Credential in @sprintstudioco/ts-types
  login(credential: Credential) {
    this.store.dispatch(AuthActions.login(credential));
  }
}
