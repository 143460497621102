import { Component, Input, OnInit } from "@angular/core";
import BasicChartComponent from "../../models/basic-chart-component";

@Component({
  selector: "stacked-vertical-bar-chart",
  template: `
    <ngx-charts-bar-vertical-stacked
      [view]="view"
      [scheme]="colorScheme"
      [results]="data"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [showLegend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [animations]="animations"
      (select)="onSelect($event)"
    >
    </ngx-charts-bar-vertical-stacked>
  `,
  styles: []
})
export class StackedVerticalBarChartComponent extends BasicChartComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    console.log(this.data);
    const dataA = [
      {
        name: "Germany",
        series: [
          {
            name: "2010",
            value: 40632,
            extra: {
              code: "de"
            }
          },
          {
            name: "2000",
            value: 36953,
            extra: {
              code: "de"
            }
          }
        ]
      }
    ];
  }
}
