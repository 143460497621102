import { createAction, props } from "@ngrx/store";
import { ProductType } from "@sprintstudioco/ts-types";
import { Alert } from "src/app/shared/models/alert";
import { ProductsEntities } from "src/app/shared/models/product-entity";

/**
 * Load Tracked Products Actions
 */
export const loadEcommerceProducts = createAction(
  "[Dashboard Component] Load My Products"
);

export const loadEcommerceProductsSuccess = createAction(
  "[MyProducts Effects] Load My Products Success",
  props<{ entities: ProductsEntities }>()
);

export const loadEcommerceProductsError = createAction(
  "[MyProducts Effects] Load My Products Error",
  props<{ error: any }>()
);

/**
 * Track Product Actions
 */
export const trackProduct = createAction(
  "[MyProducts Component] Track Product",
  props<{ id: string }>()
);

export const trackProductSuccess = createAction(
  "[MyProducts Component] Track Product Success",
  props<{ id: string }>()
);

export const trackProductError = createAction(
  "[MyProducts Component] Track Product",
  props<{ error: any }>()
);

/**
 * Check Actions
 */
export const checkProduct = createAction(
  "[MyProducts Component] Check Product",
  props<{ id: string }>()
);

export const uncheckProduct = createAction(
  "[MyProducts Component] Uncheck Product",
  props<{ id: string }>()
);

export const checkAllProducts = createAction(
  "[MyProducts Component] Check All Products"
);

export const uncheckAllProducts = createAction(
  "[MyProducts Component] Uncheck All Products"
);

/**
 * Track multiple Products Actions
 */
export const trackProducts = createAction(
  "[MyProducts Component] Track Products",
  props<{ products: string[] }>()
);

export const trackProductsSuccess = createAction(
  "[MyProducts Effects] Track Products Success",
  props<{ products: string[]; response?: any }>()
);

export const trackProductsError = createAction(
  "[MyProducts Effects] Track Products Error",
  props<{ error: any }>()
);

/**
 * Untrack Product Actions
 */
export const untrackProduct = createAction(
  "[MyProducts Component] Untrack Product",
  props<{ id: string }>()
);

export const untrackProductSuccess = createAction(
  "[MyProducts Component] Untrack Product",
  props<{ id: string }>()
);

export const untrackProductError = createAction(
  "[MyProducts Component] Untrack Product",
  props<{ error: string }>()
);

/**
 * Untrack multiple Products Actions
 */
export const untrackProducts = createAction(
  "[MyProducts Component] Untrack Products",
  props<{ products: string[] }>()
);

export const untrackProductsSuccess = createAction(
  "[MyProducts Component] Untrack Products Success",
  props<{ products: string[]; response?: any }>()
);

export const untrackProductsError = createAction(
  "[MyProducts Component] Untrack Products Error",
  props<{ error: string }>()
);

/**
 * Pagination
 */
export const previousPage = createAction(
  "[MYProducts Component] Previous Page",
  props<{ page: number }>()
);

export const previousPageSuccess = createAction(
  "[MYProducts Effects] Previous Page Success",
  props<{ entities?: ProductsEntities }>()
);

export const previousPageError = createAction(
  "[MYProducts Effects] Previous Page Error",
  props<{ error: any }>()
);

export const nextPage = createAction(
  "[MyProducts Component] Next Page",
  props<{ page: number }>()
);

export const nextPageSuccess = createAction(
  "[MyProducts Effects] Next Page Success",
  props<{ entities: ProductsEntities }>()
);

export const nextPageError = createAction(
  "[MyProducts Component] Next Page",
  props<{ error: any }>()
);

/**
 * Create or Edit My Product
 */
export const createProduct = createAction(
  "[MyProduct Manager] Create new Product",
  props<{ product: ProductType }>()
);

export const createProductSuccess = createAction(
  "[Ecommerce Products Effect] Create new Product Success",
  props<{ product: ProductType; response?: any }>()
);

export const createProductError = createAction(
  "[Ecommerce Products Effect] Create new Product Error",
  props<{ error: any }>()
);

export const updateProduct = createAction(
  "[MyProduct Manager] Update Product",
  props<{ product: ProductType }>()
);

export const updateProductSuccess = createAction(
  "[Ecommerce Products Effect] Update Product Success",
  props<{ product: ProductType; response?: any }>()
);

export const updateProductError = createAction(
  "[Ecommerce Products Effect] Update Product Error",
  props<{ error: any }>()
);

/**
 * MAP, MSRP and Alerts actions
 */

// TODO far diventare { map: number, msrp: number } un tipo ben definito
export const saveMAPandMSRP = createAction(
  "[MyProduct Manager] Save MAP and MSRP for one Product",
  props<{
    productUrl: string;
    productId?: string;
    data: Alert;
  }>()
);

export const saveMAPandMSRPSuccess = createAction(
  "[MyProduct Manager] Save MAP and MSRP for one Product Success",
  props<{
    productUrl: string;
    productId?: string;
    data: Alert;
    response: any;
  }>()
);

export const saveMAPandMSRPError = createAction(
  "[MyProduct Manager] Save MAP and MSRP for one Product Error",
  props<{ error: any }>()
);

export const loadAlertByProductId = createAction(
  "[Alert Resolver] Load Alert By Product Id Requested",
  props<{ productId: string, productUrl?: string }>()
);

export const loadAlertByProductIdSuccess = createAction(
  "[MyProduct Manager] Load Alert By Product Id Success",
  props<{ productId?: string; productUrl?: string, alert: Alert }>()
);

export const loadAlertByProductIdError = createAction(
  "[MyProduct Manager] Load Alert By Product Id Error",
  props<{ productId?: string; productUrl?: string, error: any; }>()
);

/**
 * Create Alert Actions
 */
export const createAlert = createAction(
  "[EcommerceProduct Effect] Create Alert Requested",
  props<{ productId: string }>()
);

export const createAlertSuccess = createAction(
  "[EcommerceProduct Effect] Create Alert Success",
  props<{ productId?: string }>()
);

export const createAlertError = createAction(
  "[EcommerceProduct Effect] Create Alert Error",
  props<{ productId?: string; error: any; }>()
);


export const updateAlertByProductId = createAction(
  "[MyProduct Manager] Update Alert By Product Id Requested",
  props<{ productId: string, data: Alert }>()
);

export const updateAlertByProductIdSuccess = createAction(
  "[MyProduct Manager] Update Alert By Product Id Success",
  props<{ productId?: string; alert: Alert }>()
);

export const updateAlertByProductIdError = createAction(
  "[MyProduct Manager] Update Alert By Product Id Error",
  props<{ productId?: string; error: any; }>()
);
