import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "pr-tab-panel",
  template: `
    <ng-content *ngIf="show"></ng-content>
  `,
  styles: []
})
export class PrTabPanelComponent implements OnInit {
  @Input() id: string;
  @Input() show: boolean = false;
  @Input() label: string;

  constructor() {}

  ngOnInit(): void {}
}
