import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { Ecommerce } from "src/app/shared/models/ecommerce";
import { IEcommerceService } from "src/app/shared/interfaces/ecommerce.service";
import { EcommerceType } from "@sprintstudioco/ts-types";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class EcommerceService implements IEcommerceService {
  constructor(private http: HttpClient) {}

  getEcommerce(name?: string, productEAN?: string): Observable<Ecommerce> {
    let params = new HttpParams();
    params = params.set("name", name);
    params = params.set("product_ean", productEAN);

    return this.http.get<Ecommerce>("http://localhost:3000/api/ecommerces", {
      params
    });
  }

  getCompetitors({
    page,
    sort,
    order
  }: {
    page?: number;
    sort?: string;
    order?: string;
  }): Observable<EcommerceType[]> {
    let params = new HttpParams();
    // params = page ? params.set("page", page.toString()) : params;
    params = sort ? params.set("sort", sort) : params;
    params = order ? params.set("order", order) : params;

    return this.http.request<EcommerceType[]>(
      "get",
      environment.API.getCompetitors,
      { params }
    );
  }
}
