import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EcommerceType, ProductType } from "@sprintstudioco/ts-types";
import {
  Comparison,
  CompetitorProductEmbedded,
  ProductComparator
} from "src/app/shared/models/product-comparator";
import { Alert } from "src/app/shared/models/alert";
import { SimilarProduct } from "src/app/shared/models/products-matching";
import ViolationsByEcommerce from "src/app/shared/models/violations-by-ecommerce";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ProductService {
  constructor(private http: HttpClient) { }

  /**
   * Get the list of tracked products of the current user
   */
  getEcommerceProducts(params?: {
    page?: number;
    perPage?: number;
  }): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(environment.API.getEcommerceProducts, {
      params: {
        page: params.page.toString(),
        per_page: params.perPage?.toString() || "100"
      }
    });
  }

  /**
   * Add the specified products to the collection of tracked products.
   * @param products is an array of products id
   */
  trackProducts(products: string[]): Observable<ProductType[]> {
    return this.http.post<ProductType[]>(environment.API.trackProducts, {
      productIDs: products
    });
  }

  /**
   * Add the specified products to the collection of untracked products.
   * @param products is an array of products id
   */
  untrackProducts(products: string[]): Observable<any> {
    return this.http.request("delete", environment.API.untrackProducts, {
      body: {
        productIDs: products
      }
    });
  }

  /**
   * Use this function to create new Product and add to the catalogue
   * @param product
   */
  createProduct(product: ProductType): Observable<any> {
    return this.http.post<ProductType>(environment.API.createProduct, product);
  }

  /**
   * Use this function to create new Product and add to the catalogue
   * @param product
   */
  updatedProduct(productId, data: ProductType): Observable<any> {
    return this.http.patch<ProductType>(
      environment.API.updateProduct(productId),
      data
    );
  }

  /**
   * Use this function to save MAP and MSRP values for a specified product
   * @param productId
   * @param data
   */
  saveMAPandMSRP(productId: string, data: Alert): Observable<any> {
    return this.http.post<any>(environment.API.saveMAPandMSRP, {
      productId,
      ...data
    });
  }

  createAlert(productUrl: string, data: Alert): Observable<any> {
    return this.saveMAPandMSRP(productUrl, data);
  }

  /**
   * Use this function to retrieve alert associate to a product
   * @param productId
   * @returns
   */
  getAlertByProductId(productId: string): Observable<any> {
    return this.http.get<any>(environment.API.getAlertByProductId(productId));
  }

  /**
   * Use this function to update an alert for a specified product
   * @param productId
   * @param data
   * @returns
   */
  updateAlertByProductId(productId: string, data: Alert): Observable<Alert> {
    return this.http.patch<any>(
      environment.API.updateAlertByProductId(productId),
      data
    );
  }

  /**
   * Get a price comparison of all tracked products
   */
  getProductsComparison(): Observable<ProductComparator[]> {
    return this.http.get<ProductComparator[]>(
      environment.API.getProductsComparison
    );
  }

  /**
   * Get a specific comparison
   * @param comparisonId
   * @returns
   */
  getProductComparisonById(
    comparisonId: string,
    filter?: {
      id?: string;
      name?: string;
      country?: string;
    }
  ): Observable<Comparison<CompetitorProductEmbedded>> {
    let params = new HttpParams({ fromObject: filter ? filter : {} });
    return this.http.get<Comparison<CompetitorProductEmbedded>>(
      environment.API.getProductComparisonById(comparisonId),
      { params }
    );
  }

  /**
   * Get Products to Match
   * @param comparisonId id of the comparison
   */
  getProductsToMatch(productId: string): Observable<any> {
    return this.http.get<any>(
      `${environment.API.host}${environment.API.getProductsToMatch(productId)}`
    );
  }

  /**
   * Get similar products of a specified product.
   * @param productId
   * @param ecommerceId
   * @returns
   */
  getSimilarProductsOfASpecificEcommerce(
    productId: string,
    ecommerceId: string
  ): Observable<SimilarProduct[]> {
    return this.http.get<any>(
      `${environment.API.host}${environment.API.getSimilarProducts(
        productId,
        ecommerceId
      )}`
    );
  }

  /**
   * Match two products
   * @param comparisonId Id of the comparison
   * @param similarProductID Competitor's product
   * @param isTheSame Boolean to explain if similarProductID is the same of client's product
   */
  matchProducts(
    comparisonId: string,
    similarProductID: string,
    isTheSame?: boolean
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.API.host}${environment.API.matchProducts(comparisonId)}`,
      { similarProductID, isTheSame: true }
    );
  }

  /**
   * Use this function to updated a product included into a comparison that has an error
   * @param productId
   * @param data
   */
  updatedCompetitorProduct(productId: string, data: ProductType) {
    return this.http.patch<any>(
      `${environment.API.host}${environment.API.updatedCompetitorProduct(
        productId
      )}`,
      { ...data }
    );
  }

  /**
   * Use this function to report a wrong product into a comparison.
   * Use very carefully this function because the result is delete product from the comparison
   * @param productId
   */
  wrongProductIntoComparison(comparisonId: string, productId: string) {
    return this.http.delete<any>(
      `${environment.API.host}${environment.API.wrongProductIntoComparison(
        comparisonId,
        productId
      )}`
    );
  }

  /**
   * This function return all Ecommerces missing into the selected Comparison. Is useful to know what are the ecommerces that
   * don't have the client product associated to the selected comparison
   * @param comparisonId selected comparison by user
   * @returns An Array of Ecommerces that aren't into the selected comparison
   */
  getMissingEcommercesIntoComparison(comparisonId: string): Observable<EcommerceType[]> {
    return this.http.get<EcommerceType[]>(`${environment.API.host}${environment.API.getMissingEcommercesIntoComparison(comparisonId)}`)
  }


  /**
   * This function return all MAP / MSRP violations grouped by EcommerceType
   * @return An Array of violations
   */
  getEcommercesViolatingPolicy(): Observable<Record<string, ViolationsByEcommerce<string>>> {
    return this.http.get<any>(`
      ${environment.API.host}${environment.API.getEcommercesViolatingPolicies}`)
      .pipe(map(response => {
        ;
        return response
      }))
  }
}
