import {
  Component,
  ContentChildren,
  Input,
  OnInit,
  Output,
  QueryList,
  EventEmitter,
  AfterContentInit,
} from "@angular/core";
import WizardPanelTab from "src/app/shared/models/wizard-panel-tab";
import { WizardPanelComponent } from "../wizard-panel/wizard-panel.component";

@Component({
  selector: "wizard-container",
  template: ` <div class="w-full overflow-hidden shadow rounded-lg space-y-">
    <step-panels-description
      [tabs]="wizardTabs"
      [current]="currentStep"
    ></step-panels-description>

    <div class="h-3/4 px-4 py-5 sm:p-6 bg-white">
      <ng-content select="wizard-panel"></ng-content>
    </div>
    <div
      class="px-4 py-4 sm:px-6 flex"
      [ngClass]="{
        'justify-end': currentStep === 0,
        'justify-between': currentStep > 0
      }"
    >
      <a
        [hidden]="currentStep === 0"
        class="py-2 text-gray-700 text-xs leading-4 font-semibold"
        (click)="previousTab()"
        >Indietro</a
      >
      <a
        *ngIf="currentStep < wizardTabs.length - 1"
        class="px-5 py-2 bg-gray-600 text-white text-xs leading-4 font-medium rounded"
        (click)="nextTab()"
        >Avanti</a
      >
      <a
        *ngIf="currentStep === wizardTabs.length - 1"
        class="px-5 py-2 bg-gray-600 text-white text-xs leading-4 font-medium rounded"
        (click)="save.emit()"
        >{{ btnFinishText }}</a
      >
    </div>
  </div>`,
  styles: [],
})
export class WizardContainerComponent implements AfterContentInit {
  @ContentChildren(WizardPanelComponent)
  wizardPanels: QueryList<WizardPanelComponent>;

  @Input() wizardTabs: WizardPanelTab[];
  @Input() currentStep: number;
  @Input() btnFinishText: string;
  @Output() stepChange: EventEmitter<number> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngAfterContentInit(): void {
    this.currentStep = this.currentStep || 0;
    this.setTabProgress();
  }

  setTabProgress() {
    this.wizardPanels.forEach((panel) => (panel.show = false));
    this.wizardPanels.find((panel, index) => index === this.currentStep).show =
      true;
    this.stepChange.emit(this.currentStep);
  }

  nextTab() {
    if (this.currentStep === this.wizardTabs.length - 1) return;

    this.currentStep += 1;
    this.setTabProgress();
  }

  previousTab() {
    if (this.currentStep === 0) return;

    this.currentStep -= 1;
    this.setTabProgress();
  }
}
