import { schema } from "normalizr";
import ReferrerSchema from "./referrer.schema";

export default class AutomationSchema extends schema.Entity {
  constructor() {
    const referrer = new ReferrerSchema();

    super(
      "automation",
      {
        referrers: [referrer],
      },
      {
        idAttribute: "_id",
      }
    );
  }
}
