import { createSelector } from "@ngrx/store";
import { EcommerceType } from "@sprintstudioco/ts-types";
import { Observable } from "rxjs";
import * as fromFeature from "../reducers";
import * as fromCommercialAssistant from "../reducers/commercial-assistant.reducer";
import { selectCompetitorsEntities } from "./competitors.selector";

export const selectCommercialAssistantEntitites = createSelector(
  fromFeature.getCommercialAssistantState,
  fromCommercialAssistant.getCommercialAssistantEntities
);

export const selectAllAutomation = createSelector(
  selectCommercialAssistantEntitites,
  selectCompetitorsEntities,
  (
    entities: fromCommercialAssistant.CommercialAssistantEntities,
    competitors: Record<string, EcommerceType>
  ) => {
    if (!entities.automation) return;

    if (!competitors.ecommerce) return;

    console.log(entities);

    // TODO Tipizzare Automations
    const automations: any = Object.values(entities.automation);
    return automations.map((automation) => ({
      ...automation,
      ecommerce: competitors?.ecommerce[automation.ecommerceId],
      referrers: automation.referrers.map(
        (referrer) => entities.referrer[referrer]
      ),
    }));
  }
);

/**
 * QUESTION
 * In questo punto sto ritornando l'automazione draft presente nello stato ma sto praticamente cambiando
 * il tipo ecommerce presente all'interno di essa. Inizialmente è una stringa, in quando è un ecommerceId, ma con il selector
 * dovrebbe essere popolato per evitare troppi giri nel componente. Una volta popolato diventerebbe
 * di tipo EcommerceType, per cui come gestire questa cosa dato che non è più di tipo AutomationType dopo che il componente richiama il selector?
 **/
export const selectDraftedAutomation = createSelector(
  fromFeature.getCommercialAssistantState,
  selectCompetitorsEntities,
  (
    state: fromCommercialAssistant.CommercialAssistantState,
    competitors: Record<string, EcommerceType>
  ) => {
    if (!competitors.ecommerce) return;

    return {
      ...state.draft,
      referrers: state.draft?.referrers[0]?.name
        ? state.draft.referrers
        : state.draft?.referrers?.map(
            (referrer) => state.entities.referrer[referrer]
          ),
      ecommerce: competitors?.ecommerce[state.draft?.ecommerceId],
    };
  }
);

export const selectAllReferentsIntoDraft = createSelector(
  fromFeature.getCommercialAssistantState,
  (state: fromCommercialAssistant.CommercialAssistantState) => {
    if (!state.draft) return;

    return state.draft?.referrers;
  }
);

export const selectEmailTemplateIntoDraft = createSelector(
  fromFeature.getCommercialAssistantState,
  (state: fromCommercialAssistant.CommercialAssistantState) => {
    if (!state.draft) return;

    return state.draft?.emailTemplate;
  }
);

export const selectIdsOfSelectedAutomations = createSelector(
  fromFeature.getCommercialAssistantState,
  (state: fromCommercialAssistant.CommercialAssistantState) =>
    state.selectedItems
);
