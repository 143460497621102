import { createAction, props } from "@ngrx/store";
import { EcommerceType, ProductType } from "@sprintstudioco/ts-types";
import { SimilarProduct } from "src/app/shared/models/products-matching";

// TODO Delete this action
export const loadSimilarProductsOld = createAction(
  "[ProductMatching Resolver] Load Similar Products",
  props<{ comparisonId: string; productId: string }>()
);

export const loadSimilarProducts = createAction(
  "[ProductMatching Component] Load Similar Products",
  props<{ productId: string; competitorId: string }>()
);

export const loadSimilarProductsSuccess = createAction(
  "[ProductMatching Effects] Load Similar Products Success",
  props<{
    productId?: string;
    competitorId?: string;
    similarProducts: SimilarProduct[];
  }>()
);

export const loadSimilarProductsError = createAction(
  "[ProductMatching Effects] Load Similar Products",
  props<{ productId?: string; competitorId?: string; error: any }>()
);

export const loadNextCompetitorToMatch = createAction(
  "[ProductsMatching Effects] Load Next Competitor To Match",
  props<{
    productId?: string;
    nextCompetitorToMatch?: EcommerceType;
  }>()
);

export const loadNextCompetitorToMatchSuccess = createAction(
  "[ProductsMatching Resolver] Load Next Competitor To Match Success",
  props<{ product: ProductType; products: any }>()
);

export const loadNextCompetitorToMatchError = createAction(
  "[ProductsMatching Resolver] Load Next Competitor To Match Error",
  props<{ error: any }>()
);

export const loadProductsForMatching = createAction(
  "[ProductsMatching Dashboard] Load Products for matching",
  props<{ ecommerceId?: string; productId: string }>()
);

export const loadProductsForMatchingSuccess = createAction(
  "[ProductsMatching Effect] Load Products for matching Success",
  props<{ entities: any }>()
);

export const loadProductsForMatchingError = createAction(
  "[ProductsMatching Effect] Load Products for Matching Error",
  props<{ error: any }>()
);

export const getProductToMatchForComparison = createAction(
  "[ProductsMatching Component] Ge Products To Match for Comparison",
  props<{ comparisonId: string }>()
);

export const matchProducts = createAction(
  "[ProductsMatching Dashboard] Match Products",
  props<{
    productId?: string;
    similarProductID?: string;
    isTheSame?: boolean;
  }>()
);

export const matchProductSuccess = createAction(
  "[ProductsMatching Effects] Match Products Success",
  props<{
    productId: string;
    similarProductID: string;
    isTheSame?: boolean;
  }>()
);

export const matchProductError = createAction(
  "[ProductsMatching Effects] Match Products Error",
  props<{ error: any }>()
);

export const notMatchProducts = createAction(
  "[ProductsMatching Dashboard] Not Match Products",
  props<{
    productId: string;
    similarProductID: string;
  }>()
);

export const notMatchProductsSuccess = createAction(
  "[ProductsMatching Effects] Not Match Products Success",
  props<{
    productId: string;
    similarProductID: string;
  }>()
);

export const notMatchProductsError = createAction(
  "[ProductsMatching Effects] Not Match Products Error",
  props<{
    productId?: string;
    similarProductID?: string;
    error: any;
  }>()
);
