import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProductType } from "@sprintstudioco/ts-types";

@Component({
  selector: "product-price-stats",
  template: `
    <div class="flex flex-col bg-white rounded shadow">
      <div class="px-4 py-4 flex items-center justify-between border-b ">
        <h2 class="text-xl text-gray-700 ">
          {{ product?.name }}
        </h2>
        <button
          *ngIf="isAnAdminUser"
          class="px-3 py-2 bg-gray-200 rounded text-sm text-gray-800 font-medium"
          (click)="onPricesAnomaliesClick.emit()"
        >
          Segnala anomalie sui prezzi
        </button>
      </div>
      <div *ngIf="highestPrice" class="w-full flex">
        <div class="px-12 py-4 flex flex-1 flex-col items-center  border-r">
          <label class="text-indigo-700 text-4xl font-medium"
            >{{ lowestPrice }} €</label
          >
          <span class="text-gray-500 text-sm uppercase">Minimo</span>
        </div>
        <div class="px-12 py-4 flex flex-1 flex-col items-center border-r">
          <label class="text-indigo-700 text-4xl font-medium"
            >{{ highestPrice }} €</label
          >
          <span class="text-gray-500 text-sm uppercase">Più Alto</span>
        </div>
        <div class="px-12 py-4 flex flex-1 flex-col items-center border-r">
          <label class="text-indigo-700 text-4xl font-medium">{{
            map ? map + " €" : "-"
          }}</label>
          <span class="text-gray-500 text-sm uppercase">MAP</span>
        </div>
        <div class="px-12 py-4 flex flex-1 flex-col items-center ">
          <label class="text-indigo-700 text-4xl font-medium">{{
            msrp ? msrp + " €" : "-"
          }}</label>
          <span class="text-gray-500 text-sm uppercase">MSRP</span>
        </div>
      </div>
    </div>
  `
})
export class ProductPriceStatsComponent {
  @Input() isAnAdminUser: boolean;
  @Input() product: ProductType;
  @Input() highestPrice: number;
  @Input() lowestPrice: number;
  @Input() recommendedPrice: number;
  @Input() map: number;
  @Input() msrp: number;
  @Output() onPricesAnomaliesClick: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
