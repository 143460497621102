import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AuthActions } from "src/app/store/actions";
import { ApplicationState } from "src/app/store/reducers";

@Injectable({
  providedIn: "root"
})
export default class UserResolver implements Resolve<void> {
  constructor(private store: Store<ApplicationState>) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    return this.store.dispatch(AuthActions.loadUserProfile());
  }
}
