import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompetitorDashboardComponent } from './containers/competitor-dashboard/competitor-dashboard.component';
import { CompetitorRoutingModule } from './competitor-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [CompetitorDashboardComponent],
  imports: [
    CommonModule,
    /* Custom module */
    SharedModule,
    CompetitorRoutingModule
  ],
  exports: [
    CompetitorDashboardComponent
  ]
})
export class CompetitorModule { }
