import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { AuthGuard } from "../core/guards/auth-guard";
import { AlertResolver } from "../core/resolvers/alert/alert-resolver";
import { MyProductsResolver } from "../core/resolvers/product/my-products.resolver";
import { MyProductManagerComponent } from "./containers/my-product-manager/my-product-manager.component";
import { MyProductsComponent } from "./containers/my-products/my-products.component";

const routes: Route[] = [
  {
    path: "my-products",
    component: MyProductsComponent,
    resolve: {
      products: MyProductsResolver,
    },
  },
  {
    path: "my-product-manager",
    children: [
      {
        path: "add",
        component: MyProductManagerComponent,
      },
      {
        path: "edit/:productId",
        component: MyProductManagerComponent,
        resolve: {
          alert: AlertResolver
        }
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyProductsRoutingModule {}
