import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { User } from "src/app/shared/models/user";
import { AuthActions } from "src/app/store/actions";
import { ApplicationState } from "src/app/store/reducers";
import { selectUser } from "src/app/store/selectors";

@Component({
  selector: "sidebar",
  template: `
    <div class="w-56 h-full flex flex-col bg-gray-800 ">
      <div class="w-full flex items-center justify-between h-12 p-4">
        <h4 class="font-bold text-sm text-gray-500">Pricyo</h4>
      </div>
      <ul class="w-full flex-1 mt-6 p-2 text-gray-100">
        <li class="w-full">
          <a
            class="w-full p-2 mb-2 inline-flex items-center font-medium text-sm
            rounded hover:bg-gray-400 hover:no-underline hover:text-gray-700"
            routerLink="/products/overview"
            routerLinkActive="active"
          >
            <svg
              class="w-4 h-4 mr-2 text-gray-500"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              ></path>
            </svg>
            Prodotti</a
          >
        </li>
        <li class="w-full">
          <a
            class="w-full p-2 mb-2 inline-flex items-center font-medium text-sm
            rounded hover:bg-gray-400 hover:no-underline hover:text-gray-700"
            routerLink="/products/distribution"
            routerLinkActive="active"
          >
            <svg
              class="w-4 h-4 mr-2 text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
              />
            </svg>
            Distribuzione</a
          >
        </li>
        <li class="w-full">
          <a
            class="w-full p-2 mb-2 inline-flex items-center font-medium text-sm rounded hover:bg-gray-400 hover:no-underline hover:text-gray-700"
            routerLink="/reports"
            routerLinkActive="active"
          >
            <svg
              class="w-4 h-4 mr-2 text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            Reports</a
          >
        </li>
        <li class="h-0 my-5 border-b border-gray-700"></li>
        <li class="w-full">
          <a
            class="w-full p-2 mb-2 inline-flex items-center font-medium text-sm rounded hover:bg-gray-400 hover:no-underline hover:text-gray-700"
            routerLink="/my-products"
            routerLinkActive="active"
          >
            <svg
              class="w-4 h-4 mr-2 text-gray-500"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
              ></path>
            </svg>
            I miei vini</a
          >
        </li>
        <li class="w-full">
          <a
            class="w-full p-2 mb-2 inline-flex items-center font-medium text-sm rounded hover:bg-gray-400 hover:no-underline hover:text-gray-700"
            routerLink="/competitor"
            routerLinkActive="active"
          >
            <svg
              class="w-4 h-4 mr-2 text-gray-500"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
              ></path>
              <path
                d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
              ></path>
            </svg>
            Portali tracciati</a
          >
        </li>
      </ul>
      <div class="flex-shrink-0 flex bg-gray-700 p-4">
        <a
          *ngIf="user$ | async"
          class="flex-shrink-0 w-full group block text-gray-400 text-center"
          (click)="logout()"
        >
          Logout
          <div class="flex items-center"></div>
        </a>
      </div>
    </div>
  `,
  styles: [],
})
export class SidebarComponent implements OnInit {
  private user$: Observable<User>;
  constructor(private store: Store<ApplicationState>) {}

  ngOnInit() {
    this.user$ = this.store.pipe(
      select(selectUser),
      filter((data) => !!data)
    );
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }
}
