import { createReducer, on, Action } from "@ngrx/store";
import { AuthActions } from "../actions/auth.action";
import { User } from "src/app/shared/models/user";
import { AuthStatus } from "src/app/shared/models/status";
import { state } from "@angular/animations";

// TODO Move into feature module
export interface AuthState {
  user: User;
  status?: string;
  error?: any;
}

export const initialState: AuthState = {
  user: null,
};

export const authTokenReducer = createReducer(
  initialState,
  on(AuthActions.loadAuthToken, (state) => ({
    ...state,
    status: "Loading Auth Token",
  })),
  on(AuthActions.loadAuthTokenSuccess, (state, { token }) => ({
    ...state,
    user: { token },
    status: "Loaded Auth Token",
  })),
  on(AuthActions.loadAuthTokenError, (state, { error }) => ({
    ...state,
    error,
    status: "Error Loading Auth Token",
  })),
  on(AuthActions.login, (state, { email, password }) => ({
    ...state,
    user: { email, password },
    status: AuthStatus.login,
  })),
  on(AuthActions.loginSuccess, ({ user, ...rest }, { token }) => ({
    ...rest,
    user: { token },
    status: AuthStatus.loginSuccess,
  })),
  on(AuthActions.loginError, (state, { error }) => ({
    ...state,
    status: "Login Error",
    error,
  })),
  on(AuthActions.loadUserProfile, (state) => ({
    ...state,
    status: AuthStatus.loadUserProfileRequested,
  })),
  on(AuthActions.loadUserProfileSuccess, (state, { user }) => ({
    ...state,
    status: AuthStatus.loadUserProfileSuccess,
    user: { ...state.user, ...user },
  })),
  on(AuthActions.loadUserProfileError, (state, { error }) => ({
    ...state,
    status: AuthStatus.loadUserProfileError,
    error,
  }))
);

export function reducer(state: AuthState, action: Action) {
  return authTokenReducer(state, action);
}

export const getUser = (authState: AuthState) => authState.user;
export const getAuthStateStatus = (authState: AuthState) => authState.status;
export const getAuthStateError = (authState: AuthState) => authState.error;
