import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { ProductsMatchingComponent } from "./containers/products-matching/products-matching.component";
import { ProductsMatchingResolver } from "../core/resolvers/products-marching/products-matching.resolver";

const routes: Route[] = [
  {
    path: "products-matching/:productId",
    component: ProductsMatchingComponent,
    resolve: {
      products: ProductsMatchingResolver
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsMatchingRoutingModule {}
