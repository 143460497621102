import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from "@angular/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { fromEvent } from "rxjs";

@Directive({
  selector: "[gtmPush]"
})
export class GTMPushDirective implements OnInit {
  @Input() gtmEventReport: string;
  @Input() gtmEventName: string;

  @HostListener("click") onMouseClick() {
    this.gtmService.pushTag({
      report: this.gtmEventReport,
      product: this.gtmEventName
    });
  }

  constructor(
    private elementRef: ElementRef,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {}
}
