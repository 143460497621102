import { createSelector } from "@ngrx/store";
import { EcommerceType } from "@sprintstudioco/ts-types";

import * as fromFeature from "../reducers/index";
import * as fromCompetitors from "../reducers/competitors.reducer";

export const selectCompetitorsEntities = createSelector(
  fromFeature.getCompetitorsState,
  fromCompetitors.getCompetitorsEntities
);

// TODO Ottimizzare il typing di entities
export const selectCompetitorsEntitiesValue = createSelector(
  selectCompetitorsEntities,
  (entities: { [id: string]: EcommerceType }) => {
    return entities.ecommerce ? Object.values(entities.ecommerce) : null;
  }
);

export const selectCompetitorsById = (ids: string[]) =>
  createSelector(
    selectCompetitorsEntities,
    (entities: { [id: string]: EcommerceType }) => ids.map((id) => entities[id])
  );

export const selectCurrentPage = createSelector(
  fromFeature.getCompetitorsState,
  (state: fromCompetitors.CompetitorsState) => state.page
);

export const selectSort = createSelector(
  fromFeature.getCompetitorsState,
  (state: fromCompetitors.CompetitorsState) => state.sort
);

export const selectSortOrder = createSelector(
  fromFeature.getCompetitorsState,
  (state: fromCompetitors.CompetitorsState) =>
    state.sort ? state.sort.order : null
);
