import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { ProductType } from "@sprintstudioco/ts-types";
import { Observable } from "rxjs";
import { delay, filter, map, switchMap } from "rxjs/operators";
import { ActionResult } from "src/app/shared/models/action-result";
import { Alert } from "src/app/shared/models/alert";
import { EcommerceProductsActions } from "src/app/store/actions";
import { ApplicationState } from "src/app/store/reducers";
import { EcommerceProductsSelectors } from "src/app/store/selectors";

@Component({
  selector: "my-product-manager",
  template: `
    <page-wrapper [title]="''">
      <div class="container mx-auto">
        <div
          class="mt-10 sm:mt-0"
          [ngClass]="{ 'opacity-50 pointer-events-none': product && !isEdit }"
        >
          <my-product-adder [product]="product" (save)="createProduct($event)">
            <alert
              *ngIf="actionProductAddEditResult"
              class="flex-1"
              [type]="
                actionProductAddEditResult.isSuccess ? 'success' : 'error'
              "
              [text]="actionProductAddEditResult.message"
            ></alert>
          </my-product-adder>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

        <div
          class="mt-10 sm:mt-0"
          [ngClass]="{ 'opacity-50 pointer-events-none': !product }"
        >
          <map-msrp-manager
            [MAPandMSRP]="alert"
            (onSubmit)="saveMAPandMSRP($event)"
          >
            <alert
              *ngIf="actionAlertResult"
              class="flex-1"
              [type]="actionAlertResult.isSuccess ? 'success' : 'error'"
              [text]="actionAlertResult.message"
            ></alert>
          </map-msrp-manager>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

        <div
          class="mt-10 sm:mt-0"
          [ngClass]="{ 'opacity-50 pointer-events-none': !alert }"
        >
          <my-products-notifications-manager
            [alert]="alert"
            (onSubmit)="updateNotificationsSettings($event)"
          ></my-products-notifications-manager>
        </div>
      </div>
    </page-wrapper>
  `,
  styles: []
})
export class MyProductManagerComponent implements OnInit {
  private product: ProductType;
  private alert: Alert;
  private isEdit: boolean;
  private actionProductAddEditResult: ActionResult;
  private actionAlertResult: ActionResult;

  constructor(
    private route: ActivatedRoute,
    private store: Store<ApplicationState>
  ) {}

  ngOnInit(): void {
    // this.store.pipe(select(EcommerceProductsSelectors.selectLatestCreatedProduct))

    this.store
      .pipe(
        select(EcommerceProductsSelectors.selectIfProductAddUpdateIsSuccess),
        delay(500)
      )
      .subscribe(result => {
        this.actionProductAddEditResult = result;
      });

    this.store
      .pipe(
        select(
          EcommerceProductsSelectors.selectIfUpdateAlertByProductIdIsSuccess
        ),
        delay(500)
      )
      .subscribe(result => {
        this.actionAlertResult = result;
      });

    this.store
      .pipe(
        select(EcommerceProductsSelectors.selectLatestCreatedProductIfSuccess)
      )
      .subscribe(product => {
        this.product = product;
      });

    this.route.paramMap
      .pipe(
        map(paramMap => paramMap.get("productId")),
        filter(productId => !!productId),
        switchMap(productId =>
          this.store.pipe(
            select(
              EcommerceProductsSelectors.selectedAlertAndProductById(productId)
            )
          )
        )
      )
      .subscribe(({ product, alert }) => {
        this.isEdit = true;
        this.product = product;
        this.alert = alert;
      });
  }

  createProduct(product: ProductType) {
    if (this.isEdit) {
      this.store.dispatch(
        EcommerceProductsActions.updateProduct({
          product: { ...this.product, ...product }
        })
      );
    } else {
      this.store.dispatch(EcommerceProductsActions.createProduct({ product }));
    }
  }

  saveMAPandMSRP(data: Alert) {
    if (this.isEdit) {
      this.store.dispatch(
        EcommerceProductsActions.updateAlertByProductId({
          productId: this.product["_id"],
          data
        })
      );
    } else {
      this.store.dispatch(
        EcommerceProductsActions.saveMAPandMSRP({
          productUrl: this.product.url,
          data
        })
      );
    }
  }

  updateNotificationsSettings(settings: Alert) {
    this.store.dispatch(
      EcommerceProductsActions.updateAlertByProductId({
        productId: this.product["_id"],
        data: settings
      })
    );
  }
}
