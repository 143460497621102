import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ProductType } from "@sprintstudioco/ts-types";
import { environment } from "src/environments/environment";

@Component({
  selector: "my-products-lister",
  template: `
    <div class="bg-white mr-10 rounded shadow overflow-y-auto">
      <table class="min-w-full table-auto">
        <thead
          class="w-full border-b"
          [class.bg-gray-800]="(selectedProducts | json) !== '{}'"
        >
          <tr>
            <th
              class="px-6 py-3 text-gray-500 text-xs text-left font-medium uppercase"
            >
              <input
                *ngIf="false"
                type="checkbox"
                (change)="checkAllProducts()"
              />
            </th>
            <th
              class="px-6 py-3 text-gray-500 text-xs text-left font-medium uppercase"
            >
              <span *ngIf="(selectedProducts | json) === '{}'">Nome</span>
              <div
                *ngIf="(selectedProducts | json) !== '{}'"
                class="flex flex-col md:flex-row space-y-1 md:space-x-1 md:space-y-0"
              >
                <button
                  class="px-2 border rounded bg-gray-200 text-gray-800 leading-5"
                  (click)="trackSelected()"
                >
                  Traccia
                </button>
                <button
                  class="px-2 border rounded bg-gray-200 text-gray-800 leading-5"
                  (click)="untrackSelected()"
                >
                  Non tracciare
                </button>
              </div>
            </th>
            <th
              class="px-6 py-3 text-gray-500 text-xs text-left font-medium uppercase"
            >
              Categoria
            </th>
            <th
              class="px-6 py-3 text-gray-500 text-xs text-left font-medium uppercase"
            >
              Prezzo
            </th>
            <th
              class="px-6 py-3 text-gray-500 text-xs text-left font-medium uppercase"
            >
              Prezzo scontato
            </th>
            <th
              class="px-6 py-3 text-gray-500 text-xs text-left font-medium uppercase"
            >
              Sconto
            </th>
            <th
              class="px-6 py-3 inline-flex items-center sticky top-0 text-gray-500 text-xs text-right font-medium uppercase space-x-1"
            >
              <span>Stato</span>
              <div *ngIf="false" class="relative">
                <a
                  class="block"
                  (click)="showStatusDropdown = !showStatusDropdown"
                >
                  <svg
                    class="w-4"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M19 9l-7 7-7-7"></path>
                  </svg>
                </a>
                <div
                  *ngIf="showStatusDropdown"
                  class="p-4 mt-1 w-40 absolute right-0 bg-white shadow-md rounded-md border space-y-2 capitalize text-sm font-normal"
                >
                  <div class="flex items-center space-x-1">
                    <input type="radio" name="status" value="all" />
                    <label for="all">All</label>
                  </div>
                  <div class="flex items-center space-x-1">
                    <input type="radio" name="status" value="tracked" />
                    <label for="tracked">Tracked</label>
                  </div>
                  <div class="flex items-center space-x-1">
                    <input type="radio" name="status" value="not-tracked" />
                    <label for="not-tracked">Untracked</label>
                  </div>
                </div>
              </div>
            </th>
            <th
              class="px-6 py-3 text-gray-500 text-xs text-left font-medium uppercase"
            >
              Azioni
            </th>
          </tr>
        </thead>
        <tbody class="w-full h-full overflow-y-auto">
          <tr class="w-full border-b" *ngFor="let product of products">
            <td class="px-6 py-3">
              <input
                *ngIf="isAnAdminUser"
                type="checkbox"
                class="form-checkbox"
                [checked]="selectedProducts[product._id]"
                (change)="checkProduct(product._id)"
              />
            </td>
            <td
              colspan="2"
              class="px-6 py-3 flex items-center text-sm text-gray-800 font-medium space-x-2"
            >
              <div class="w-10 flex justify-center">
                <img class="h-10" [src]="product.image" alt="" />
              </div>
              <div class="flex flex-col">
                <span>{{ product.name }}</span>
                <span class="text-gray-600 font-normal">{{
                  product.brand
                }}</span>
              </div>
            </td>
            <td class="px-6 py-3 text-sm text-gray-800 font-medium">
              {{ product.category.name }}
            </td>
            <td class="px-6 py-3 text-sm text-gray-800 font-medium">
              {{ product.regularPrice }}€
            </td>
            <td class="px-6 py-3 text-sm text-gray-800 font-medium">
              {{
                product.discountedPrice ? product.discountedPrice + "€" : "-"
              }}
            </td>
            <td class="px-6 py-3 text-sm text-gray-800 font-medium">
              {{ product.discount ? product.discount + "%" : "-" }}
            </td>
            <td class="px-6 py-3">
              <label
                *ngIf="product.is_tracked === false"
                class="w-48 px-2 rounded-full bg-red-100 text-red-800 text-center font-semibold text-xs leading-5"
                >Non tracciato</label
              >
              <label
                *ngIf="product.is_tracked === true"
                class="w-48 px-2 rounded-full bg-green-100 text-green-800 text-center font-semibold text-xs leading-5"
                >Tracciato</label
              >
            </td>
            <td class="px-6 py-3 text-sm text-gray-800 font-medium">
              <div
                class="flex flex-col md:flex-row items-center space-y-0 md:space-x-2 md:space-y-0"
              >
                <button
                  *ngIf="false"
                  class="px-2 py-1 rounded text-xs bg-gray-800 text-gray-200 font-medium shadow"
                  [routerLink]="['/products-matching', product._id]"
                >
                  Esegui matching
                </button>
                <button
                  class="px-2 py-1 rounded text-xs bg-gray-800 text-gray-200 font-medium shadow"
                  [routerLink]="['/my-product-manager/edit/', product._id]"
                >
                  Modifica
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr *ngIf="false" class="border-t">
            <th colspan="9" class="sticky bottom-0 bg-white">
              <div
                class="flex justify-between border-t text-xs text-gray-800 font-medium"
              >
                <div class="h-12 pl-6 flex justify-start items-center ">
                  {{ page }} di 5 pagine
                </div>
                <div class="h-12 pr-6 flex justify-end items-center">
                  <div class="flex">
                    <button
                      class="px-2 py-1 border border-r-0 rounded-tl rounded-bl"
                      (click)="prevPage()"
                    >
                      <svg
                        class=" w-3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M15 19l-7-7 7-7"></path>
                      </svg>
                    </button>
                    <button
                      class="px-2 border rounded-tr rounded-br"
                      (click)="nexPage()"
                    >
                      <svg
                        class="w-3"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M9 5l7 7-7 7"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  `,
  styles: []
})
export class MyProductsListerComponent {
  @Input() isAnAdminUser: boolean;
  @Input() products: ProductType[];
  @Input() selectedProducts: { [id: string]: ProductType };
  @Input() page: number;
  @Output() edit: EventEmitter<string> = new EventEmitter();
  @Output() untrack: EventEmitter<string | void> = new EventEmitter();
  @Output() track: EventEmitter<string | string[]> = new EventEmitter();
  @Output() select: EventEmitter<string> = new EventEmitter();
  @Output() checkAll: EventEmitter<void> = new EventEmitter();
  @Output() previousPage: EventEmitter<void> = new EventEmitter();
  @Output() nextPage: EventEmitter<void> = new EventEmitter();

  constructor() {}

  trackProduct(id: string) {
    this.track.emit(id);
  }

  trackSelected() {
    this.track.emit();
  }

  untrackProduct(id: string) {
    this.untrack.emit(id);
  }

  untrackSelected() {
    this.untrack.emit();
  }

  checkProduct(id: string) {
    this.select.emit(id);
  }

  checkAllProducts() {
    this.checkAll.emit();
  }

  prevPage() {
    this.previousPage.emit();
  }

  nexPage() {
    this.nextPage.emit();
  }
}
