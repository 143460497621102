import { createAction, props } from "@ngrx/store";

export const loadDistributionOverviewByChannels = createAction(
  "[DistributionOverview Resolver] load Distribution Overview By Channels Requested",
  props<{ ecommerceId: string }>()
);

export const loadDistributionOverviewByChannelsSuccess = createAction(
  "[Distribution Effect] load Distribution Overview By Channels Success",
  props<{ chartData: any }>()
);

export const loadDistributionOverviewByChannelsError = createAction(
  "[Distribution Effect] load Distribution Overview By Channels Error",
  props<{ error: any }>()
);

export const exportProductsDistribution = createAction(
  "[DistributionOverview Component] Export Products Distribution Requested",
  props<{ data: any }>()
);

export const exportProductsDistributionSuccess = createAction(
  "[Distribution Effect] Export Products Distribution Success"
);

export const exportProductsDistributionError = createAction(
  "[Distribution Effect] Export Products Distribution Error",
  props<{ error: any }>()
);
