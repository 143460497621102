import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AutomationType } from "@sprintstudioco/ts-types";

@Injectable({
  providedIn: "root",
})
export class CommercialAssistantService {
  constructor(private http: HttpClient) {}

  /**
   * Get all Automations from Backend
   */
  getAllAutomations() {
    return this.http.get<AutomationType[]>(
      `${environment.API.host}${environment.API.getAllAutomations}`
    );
  }

  createAutomation(data: AutomationType): Observable<AutomationType> {
    return this.http.post<AutomationType>(
      `${environment.API.host}${environment.API.createAutomation}`,
      data
    );
  }

  deleteAutomations(automationsIds: string[]): Observable<any> {
    return this.http.request<Observable<any>>(
      "DELETE",
      `${environment.API.host}${environment.API.deleteAutomations}`,
      {
        body: { ids: automationsIds },
      }
    );
  }

  editAutomation(automationId: string, data: Partial<AutomationType>) {
    return this.http.patch<Observable<Partial<AutomationType>>>(
      `${environment.API.host}${environment.API.editAutomation(automationId)}`,
      data
    );
  }

  activeAutomations(automationsIds: string[]) {
    return this.http.post<Observable<any>>(
      `${environment.API.host}${environment.API.activeAutomations}`,
      { ids: automationsIds }
    );
  }

  deactiveAutomations(automationsIds: string[]) {
    return this.http.post<Observable<any>>(
      `${environment.API.host}${environment.API.deactivateAutomations}`,
      { ids: automationsIds }
    );
  }
}
