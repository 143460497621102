import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { NgxChartsModule } from "@swimlane/ngx-charts";

import { SidebarComponent } from "./components/presentationals/sidebar/sidebar.component";
import { CardInfoComponent } from "./components/card-info/card-info.component";
import { ComponentHostDirective } from "./directives/component-host/component-host.directive";
import { CardImageComponent } from "./components/card-image/card-image.component";
import { AlertComponent } from "./components/alert/alert.component";
import { BasicTableComponent } from "./components/basic-table/basic-table.component";
import { RowTmpDirective } from "./directives/row-tmp/row-tmp.directive";
import { PageWrapperComponent } from "./components/page-wrapper/page-wrapper.component";
import { ChartComponent } from "./components/chart/chart.component";
import { NgVarDirective } from "./directives/ng-var/ng-var.directive";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { PieChartComponent } from "./components/pie-chart/pie-chart.component";
import { LineChartComponent } from "./components/line-chart/line-chart.component";
import { CardHeaderFooterComponent } from "./components/cards/card-header-footer/card-header-footer.component";
import { StackedVerticalBarChartComponent } from "./components/stacked-vertical-bar-chart/stacked-vertical-bar-chart.component";
import { TableChartComponent } from "./components/table-chart/table-chart.component";
import { VericalBarChartComponent } from "./components/verical-bar-chart/verical-bar-chart.component";
import { PrButtonToggleComponent } from "./components/ui/pr-button-toggle/pr-button-toggle.component";
import { PrButtonToggleGroupComponent } from "./components/ui/pr-button-toggle-group/pr-button-toggle-group.component";
import { PrSwitchComponent } from "./components/ui/pr-switch/pr-switch.component";
import { PrTabNavigationComponent } from "./components/ui/pr-tab-navigation/pr-tab-navigation.component";
import { PrTabPanelComponent } from "./components/ui/pr-tab-panel/pr-tab-panel.component";
import { PrTabGroupComponent } from "./components/ui/pr-tab-group/pr-tab-group.component";
import { NumberCardsChartComponent } from "./components/number-cards-chart/number-cards-chart.component";
import { LoaderPlaceholderComponent } from "./components/loader-placeholder/loader-placeholder.component";
import { GTMPushDirective } from "./directives/data-layer-push/gtml-push.directive";
import { ProductProfileComponent } from "./containers/product-profile/product-profile.component";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
import { StepPanelsDescriptionComponent } from "./components/step-panels-description/step-panels-description.component";
import { CheckboxesListDescriptionComponent } from "./components/checkboxes/checkboxes-list-description/checkboxes-list-description.component";
import { RadioListDescriptionComponent } from "./components/radio-groups/radio-list-description/radio-list-description.component";
import { WizardContainerComponent } from "./components/wizard/wizard-container/wizard-container.component";
import { WizardPanelComponent } from "./components/wizard/wizard-panel/wizard-panel.component";
import { PrInputGroupComponent } from "./components/ui/pr-input-group/pr-input-group.component";
import { BadgeComponent } from "./components/badge/badge/badge.component";

export const IMPORTS = [
  CommonModule,
  FormsModule,
  RouterModule,
  NgxChartsModule,
  NgxImageZoomModule,
];

export const PROVIDERS = [];

export const DECLARATIONS = [
  ComponentHostDirective,
  SidebarComponent,
  CardInfoComponent,
  CardImageComponent,
  AlertComponent,
  BasicTableComponent,
  RowTmpDirective,
  PageWrapperComponent,
  PageHeaderComponent,
  ChartComponent,
  NgVarDirective,
  DropdownComponent,
  PieChartComponent,
  LineChartComponent,
  CardHeaderFooterComponent,
  StackedVerticalBarChartComponent,
  TableChartComponent,
  VericalBarChartComponent,
  PrButtonToggleComponent,
  PrButtonToggleGroupComponent,
  PrSwitchComponent,
  PrTabNavigationComponent,
  PrTabPanelComponent,
  PrTabGroupComponent,
  NumberCardsChartComponent,
  LoaderPlaceholderComponent,
  GTMPushDirective,
  ProductProfileComponent,
  PageHeaderComponent,
  StepPanelsDescriptionComponent,
  CheckboxesListDescriptionComponent,
  RadioListDescriptionComponent,
  WizardContainerComponent,
  WizardPanelComponent,
  PrInputGroupComponent,
  BadgeComponent,
];

export const EXPORTS = [
  ComponentHostDirective,
  SidebarComponent,
  CardInfoComponent,
  CardImageComponent,
  AlertComponent,
  BasicTableComponent,
  RowTmpDirective,
  PageWrapperComponent,
  PageHeaderComponent,
  ChartComponent,
  NgVarDirective,
  DropdownComponent,
  PieChartComponent,
  LineChartComponent,
  CardHeaderFooterComponent,
  StackedVerticalBarChartComponent,
  TableChartComponent,
  VericalBarChartComponent,
  PrButtonToggleComponent,
  PrButtonToggleGroupComponent,
  PrSwitchComponent,
  PrTabNavigationComponent,
  PrTabPanelComponent,
  PrTabGroupComponent,
  NumberCardsChartComponent,
  LoaderPlaceholderComponent,
  GTMPushDirective,
  ProductProfileComponent,
  PageHeaderComponent,
  StepPanelsDescriptionComponent,
  CheckboxesListDescriptionComponent,
  RadioListDescriptionComponent,
  WizardContainerComponent,
  WizardPanelComponent,
  PrInputGroupComponent,
  BadgeComponent,
];
