import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import CheckboxRadioItem from "src/app/shared/models/checkbox-item";

@Component({
  selector: "radio-list-description",
  template: `
    <!-- This example requires Tailwind CSS v2.0+ -->
    <fieldset>
      <legend class="sr-only">Server size</legend>
      <div class="space-y-4">
        <!-- Active: "ring-1 ring-offset-2 ring-indigo-500" -->
        <label
          *ngFor="let item of items; let i = index"
          class="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
          [ngClass]="{
            'ring-1 ring-green-500': selected === i,
            'ring-0': selected !== i
          }"
          (click)="select(i)"
        >
          <input
            type="radio"
            name="server-size"
            value="Hobby"
            class="sr-only"
            aria-labelledby="server-size-0-label"
            aria-describedby="server-size-0-description-0 server-size-0-description-1"
          />
          <div class="flex items-center">
            <div class="text-sm">
              <p id="server-size-0-label" class="font-medium text-gray-700">
                {{ item.title }}
              </p>
              <div id="server-size-0-description-0" class="text-gray-500">
                <p class="sm:inline">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <div
            id="server-size-0-description-1"
            class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right"
          >
            <svg
              *ngIf="selected === i"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-green-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <!-- Checked: "border-indigo-500", Not Checked: "border-transparent" -->
          <div
            class="absolute -inset-px rounded-lg border-2 pointer-events-none"
            aria-hidden="true"
            [ngClass]="{
              'border-green-500': selected === i,
              'border-transparent': selected !== i
            }"
          ></div>
        </label>
      </div>
    </fieldset>
  `,
  styles: [],
})
export class RadioListDescriptionComponent {
  @Input() items: CheckboxRadioItem[];
  @Input() selected: number;
  @Output() selectItem: EventEmitter<number> = new EventEmitter();

  constructor() {}

  select(index: number) {
    this.selected = index;
    this.selectItem.emit(index);
  }
}
