import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { ProductType } from "@sprintstudioco/ts-types";
import * as moment from "moment";
import { Moment } from "moment";
import { filter } from "rxjs/operators";
import {
  BasicTableColumn,
  BasicTableOptions
} from "src/app/shared/models/basic-tabel";
import { Product } from "src/app/shared/models/product";
import {
  ProductComparatorActions,
  ProductsMatchingActions
} from "src/app/store/actions";
import { ApplicationState } from "src/app/store/reducers";
import { ProductsComparatorSelectors } from "src/app/store/selectors";

@Component({
  selector: "prices-anomalies",
  template: `
    <div class="w-full flex flex-col pt-4 space-y-3">
      <h4 class="text-indigo-900 font-semibold">
        Segnala delle anomalie riscontrate per: {{ selectedProduct.name }}
      </h4>
      <basic-table
        [options]="options"
        [columnLabels]="labels"
        [items]="products"
      >
        <ng-template row-tmp let-item="item">
          <tr class="w-full border-b">
            <td
              class="w-64 px-6 py-3 flex items-center text-sm text-gray-800 font-medium space-x-2 overflow-hidden"
            >
              <span [title]="item.name" class="">{{ item.name }}</span>
              <a
                class="text-gray-600 font-normal"
                href="{{ item.url }}"
                target="_blank"
              >
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="external-link w-3 h-3"
                >
                  <path
                    d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                  ></path>
                  <path
                    d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                  ></path></svg
              ></a>
            </td>
            <td class="w-40 px-6 py-3 text-sm font-medium hover:border">
              <span class="w-full flex items-center justify-center">
                <img class="h-16 self-center" [src]="item.image" />
              </span>
            </td>
            <td class="px-6 py-3 text-sm font-medium hover:border">
              <input
                #image
                name="image"
                class="px-3 py-1 border rounded text-gray-800"
                [value]="item.image"
                (change)="onChangeAnomalies(item, 'image', image.value)"
                [ngModel]="item.image"
              />
            </td>
            <td class="px-6 py-3 text-sm font-medium hover:border">
              <input
                #regularPrice
                name="regularPrice"
                class="px-3 py-1 border rounded text-gray-800"
                [value]="item.regularPrice"
                (change)="
                  onChangeAnomalies(item, 'regularPrice', regularPrice.value)
                "
                [ngModel]="item.regularPrice"
              />
            </td>
            <td class="px-6 py-3 text-sm font-medium">
              <input
                #discountedPrice
                name="discountedPrice"
                class="px-3 py-1 border rounded text-gray-800"
                [value]="item.discountedPrice || '-'"
                (change)="
                  onChangeAnomalies(
                    item,
                    'discountedPrice',
                    discountedPrice.value
                  )
                "
                [ngModel]="item.discountedPrice"
              />
            </td>
            <td class="px-6 py-3">
              <label
                class="px-2 inline-flex rounded-full bg-gray-100 text-gray-800 font-semibold text-xs leading-5"
                >{{ yesterday | date }}</label
              >
            </td>
            <td class="px-6 py-3">
              <button
                class="px-2 py-1 bg-red-600 text-xs text-white rounded font-medium shadow"
                (click)="wrongProductIntoComparison(item)"
              >
                Prodotto non corretto
              </button>
            </td>
          </tr>
        </ng-template>
      </basic-table>
    </div>
  `,
  styles: []
})
export class PricesAnomaliesComponent implements OnInit {
  private options: BasicTableOptions = {
    itemsIsSelectable: false,
    itemsHasActions: false,
    hasPagination: false
  };
  private labels: BasicTableColumn[];
  private yesterday: string;
  private comparisonSelected: any;
  private comparisonIdSelected: string;
  private selectedProduct: ProductType;

  @Input() products: any[];

  constructor(
    private route: ActivatedRoute,
    private store: Store<ApplicationState>
  ) {}

  ngOnInit(): void {
    this.yesterday = moment().toString();
    this.route.paramMap.subscribe(params => {
      this.comparisonIdSelected = params.get("comparisonId");
    });

    this.labels = [
      {
        id: "name",
        name: "Nome Prodotto"
      },
      {
        id: "image",
        name: "Immagine"
      },
      {
        id: "image",
        name: "Url Immagine"
      },
      {
        id: "regularPrice",
        name: "Prezzo regolare"
      },
      {
        id: "discountedPrice",
        name: "Prezzo scontato"
      },
      {
        id: "scraped",
        name: "Analizzato"
      },
      {
        id: "actions",
        name: ""
      }
    ];

    this.store
      .pipe(
        select(
          ProductsComparatorSelectors.selectProductComparisonById(
            this.comparisonIdSelected
          )
        ),
        filter(data => !!data)
      )
      .subscribe(comparison => {
        this.selectedProduct = comparison.product;
        this.products = comparison.competitors.map(
          competitor => competitor.product
        );
      });
  }

  onChangeAnomalies(item: ProductType, property: string, newValue: string) {
    this.store.dispatch(
      ProductComparatorActions.updateCompetitorProduct({
        productId: item["_id"],
        data: {
          [property]: property === "image" ? newValue : parseFloat(newValue)
        }
      })
    );
  }

  wrongProductIntoComparison(item: ProductType) {
    if (
      !confirm(
        "Sei sicuro di eliminare questo prodotto da questa comparazione?\n\nNon sarà più presente"
      )
    )
      return;

    this.store.dispatch(
      ProductComparatorActions.wrongProductIntoComparison({
        comparisonId: this.comparisonIdSelected,
        productId: item["_id"]
      })
    );
  }
}
